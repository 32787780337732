import React, { useEffect, useState } from "react"
import _ from "lodash"
import axios from "axios"
import { CMS_URL, token } from ".."
import { gql, useMutation, useQuery } from "@apollo/client"
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Switch,
  Table,
  Tooltip,
  Upload,
} from "antd"
import { useContext } from "react"
import { useParams, Redirect } from "react-router"
import { ProjectContext } from "../App"
import { GET_PROJECT_QUERY } from "./CreateProject"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "./types/GetProjectQuery"
import { Actions, ListActions, ListHeader, Page } from "../util/page"
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  DiffOutlined,
  CheckOutlined,
  CopyOutlined,
  FileOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { useForm } from "antd/lib/form/Form"
import {
  ListTouchless,
  ListTouchlessVariables,
  ListTouchless_touchlessList,
} from "./types/ListTouchless"
import styled from "styled-components"
import dayjs from "dayjs"
import {
  CreateTouchless,
  CreateTouchlessVariables,
} from "./types/CreateTouchless"
import {
  UpdateTouchless as UpdateTouchlessMutation,
  UpdateTouchlessVariables,
} from "./types/UpdateTouchless"
import {
  DeleteTouchless,
  DeleteTouchlessVariables,
} from "./types/DeleteTouchless"
import UpdateTouchlessPages from "./UpdateTouchlessPages"
import TouchlessContentItem from "../components/TouchlessContentItem"
import copy from "copy-to-clipboard"
import { KIOSK_URL } from ".."
import TouchlessCustomValues from "../components/TouchlessCustomValues"

interface Params {
  id?: string
}

type ContentInput = {
  contentType: "video" | "image"
  video?: string
  image?: string
}

export const LIST_TOUCHLESS_QUERY = gql`
  query ListTouchless($projectID: ID!) {
    touchlessList(projectId: $projectID) {
      id
      name
      slug

      qrPage {
        contentType
        image
        video
      }

      start {
        contentType
        image
        video
      }

      pages {
        name
        content {
          contentType
          image
          video
        }
      }

      bundle
      showBundleButton

      timeout

      closeButtonText
      swipeHintText
      emailInfoText
      emailPlaceholderText
      emailHintText
      emailSendButtonText
      connectionClosedText
      emailSentText

      backgroundColor
      headingColor
      textColor
      hintColor
      closeButtonColor
      closeButtonTextColor
      menuButtonColor
      menuButtonTextColor
      bundleButtonColor
      bundleButtonTextColor
      backButtonColor
      swipeAreaColor
      swipeIconColor
      emailSendButtonColor
      emailSendButtonTextColor
      emailSentTextColor

      headingFontFamily
      headingFontSize
      textFontFamily
      textFontSize

      created
      updated
    }
  }
`

const DELETE_TOUCHLESS_MUTATION = gql`
  mutation DeleteTouchless($id: ID!) {
    deleteTouchless(id: $id) {
      id
    }
  }
`

const CREATE_TOUCHLESS_MUTATION = gql`
  mutation CreateTouchless($input: CreateTouchlessInput!) {
    createTouchless(input: $input) {
      id
    }
  }
`

const UPDATE_TOUCHLESS_MUTATION = gql`
  mutation UpdateTouchless($input: UpdateTouchlessInput!) {
    updateTouchless(input: $input) {
      id
    }
  }
`

const UpdateTouchless = () => {
  const params = useParams<Params>()
  const { activeProject, setActiveProject } = useContext(ProjectContext)

  const [editModalShown, setEditModalShown] = useState(false)
  const [
    editingTouchless,
    setEditingTouchless,
  ] = useState<ListTouchless_touchlessList | null>(null)

  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const [
    deletingTouchless,
    setDeletingTouchless,
  ] = useState<ListTouchless_touchlessList | null>(null)

  const [
    selectedTouchless,
    setSelectedTouchless,
  ] = useState<ListTouchless_touchlessList | null>(null)

  const touchlessQuery = useQuery<ListTouchless, ListTouchlessVariables>(
    LIST_TOUCHLESS_QUERY,
    {
      skip: !activeProject,
      variables: { projectID: activeProject?.id ?? "" },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (!data) return

        // DEBUG
        if (!selectedTouchless) {
          setSelectedTouchless(data.touchlessList[0] ?? null)
        }

        if (selectedTouchless != null) {
          const updatedTouchless = data.touchlessList.find(
            it => it.id === selectedTouchless.id
          )
          setSelectedTouchless(updatedTouchless ?? null)
        }
      },
    }
  )

  const [createTouchless, createTouchlessMutation] = useMutation<
    CreateTouchless,
    CreateTouchlessVariables
  >(CREATE_TOUCHLESS_MUTATION, {
    refetchQueries: [
      {
        query: LIST_TOUCHLESS_QUERY,
        variables: { projectID: activeProject?.id || "" },
      },
    ],
    onCompleted: () => {
      message.success("Touchless Kiosk erfolgreich gespeichert")
      setEditModalShown(false)
      setEditingTouchless(null)
    },
  })

  const [updateTouchless, updateTouchlessMutation] = useMutation<
    UpdateTouchlessMutation,
    UpdateTouchlessVariables
  >(UPDATE_TOUCHLESS_MUTATION, {
    refetchQueries: [
      {
        query: LIST_TOUCHLESS_QUERY,
        variables: { projectID: activeProject?.id || "" },
      },
    ],
    onCompleted: () => {
      message.success("Touchless Kiosk erfolgreich gespeichert")
      setEditModalShown(false)
      setEditingTouchless(null)
    },
  })

  const [deleteTouchless, deleteTouchlessMutation] = useMutation<
    DeleteTouchless,
    DeleteTouchlessVariables
  >(DELETE_TOUCHLESS_MUTATION, {
    refetchQueries: [
      {
        query: LIST_TOUCHLESS_QUERY,
        variables: { projectID: activeProject?.id || "" },
      },
    ],
    onCompleted: () => {
      message.success("Touchless Kiosk erfolgreich gelöscht")
      setDeleteModalShown(false)
      setDeletingTouchless(null)
    },
  })

  const onSave = (data: any) => {
    if (!!editingTouchless) {
      updateTouchless({
        variables: {
          input: {
            id: editingTouchless.id,
            name: data.name,
            slug: data.slug,
            bundle: editingTouchless.bundle,
            showBundleButton: editingTouchless.showBundleButton,
            qrPage: editingTouchless.qrPage,
            start: editingTouchless.start,
            timeout: editingTouchless.timeout,
          },
        },
      })
    } else {
      createTouchless({
        variables: {
          input: {
            projectID: activeProject?.id ?? ``,
            name: data.name,
            slug: data.slug,
            bundle: "",
            qrPage: {
              contentType: "image",
            },
            start: {
              contentType: "image",
            },
          },
        },
      })
    }
  }

  const [qrPage, setQrPage] = useState<ContentInput | undefined>()
  const [start, setStart] = useState<ContentInput | undefined>()

  const [bundle, setBundle] = useState<string | undefined>()
  const [uploadingBundle, setUploadingBundle] = useState(false)
  const [showBundle, setShowBundle] = useState(false)
  const [timeoutVal, setTimeoutVal] = useState(0)

  const onUploadBundle = async (file: Blob) => {
    setUploadingBundle(true)
    const formData = new FormData()
    formData.append("file", file)

    try {
      const res = await axios.post(
        `${CMS_URL}/files/contentbundles`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      setBundle(res.data["fileId"])
      setUploadingBundle(false)
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      message.error(
        "Der Upload ist fehlgeschlagen. Bitte probieren Sie es erneut."
      )
      setUploadingBundle(false)
    }
  }

  const [custom, setCustom] = useState<any>({})

  useEffect(() => {
    if (!selectedTouchless) {
      setStart(undefined)
      setQrPage(undefined)
      return
    }

    setStart({
      contentType:
        (selectedTouchless.start.contentType as "video" | "image") ?? "image",
      image: selectedTouchless.start.image ?? undefined,
      video: selectedTouchless.start.video ?? undefined,
    })

    setQrPage({
      contentType:
        (selectedTouchless.qrPage.contentType as "video" | "image") ?? "image",
      image: selectedTouchless.qrPage.image ?? undefined,
      video: selectedTouchless.qrPage.video ?? undefined,
    })

    setBundle(selectedTouchless.bundle || undefined)
    setShowBundle(selectedTouchless.showBundleButton)
    setTimeoutVal(selectedTouchless.timeout)
    setCustom(
      _.pick(
        selectedTouchless,
        "closeButtonText",
        "swipeHintText",
        "emailInfoText",
        "emailPlaceholderText",
        "emailHintText",
        "emailSendButtonText",
        "connectionClosedText",
        "backgroundColor",
        "headingColor",
        "textColor",
        "hintColor",
        "closeButtonColor",
        "closeButtonTextColor",
        "menuButtonColor",
        "menuButtonTextColor",
        "bundleButtonColor",
        "bundleButtonTextColor",
        "backButtonColor",
        "swipeAreaColor",
        "swipeIconColor",
        "emailSendButtonColor",
        "emailSendButtonTextColor",
        "headingFontFamily",
        "headingFontSize",
        "textFontFamily",
        "textFontSize"
      )
    )
  }, [selectedTouchless])

  const onSaveMenu = () => {
    if (!selectedTouchless || !start || !qrPage) return

    updateTouchless({
      variables: {
        input: {
          ..._.omit(selectedTouchless, "pages", "created", "updated"),
          bundle: bundle || "",
          showBundleButton: showBundle,
          timeout: timeoutVal,
          start,
          qrPage,
        },
      },
    })
  }

  const onSaveCustom = (data: any) => {
    if (!selectedTouchless) return

    updateTouchless({
      variables: {
        input: {
          ..._.omit(selectedTouchless, "pages", "created", "updated"),
          ...data,
        },
      },
    })
  }

  useQuery<GetProjectQuery, GetProjectQueryVariables>(GET_PROJECT_QUERY, {
    variables: { id: params.id!! },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      message.error("Das Projekt konnte nicht gefunden werden.")
    },
    onCompleted: data => {
      if (!data) return
      setActiveProject(data.project.id)
    },
  })

  const [form] = useForm()

  if (activeProject && activeProject.id !== params.id)
    return <Redirect to={`/projects/${activeProject.id}/touchless/edit`} />

  return (
    <Page>
      <Modal
        title={`Touchless Kiosk ${deletingTouchless?.name ?? ""} löschen`}
        visible={deleteModalShown}
        okText="Löschen"
        onCancel={() => {
          setDeleteModalShown(false)
          setDeletingTouchless(null)
        }}
        okButtonProps={{
          danger: true,
          loading: deleteTouchlessMutation.loading,
        }}
        cancelButtonProps={{
          disabled: deleteTouchlessMutation.loading,
        }}
        onOk={() => {
          if (!deletingTouchless?.id) return
          deleteTouchless({ variables: { id: deletingTouchless.id } })
        }}
      >
        <p>
          Sind Sie sicher, dass Sie den Touchless Kiosk{" "}
          {deletingTouchless?.name ?? ""} löschen möchten?
        </p>
      </Modal>

      <Modal
        title={
          !!editingTouchless
            ? `Touchless Kiosk ${editingTouchless.name} bearbeiten`
            : `Touchless Kiosk erstellen`
        }
        visible={editModalShown}
        onOk={() => form.submit()}
        okText={
          !!editingTouchless
            ? `Touchless Kiosk ${editingTouchless.name} speichern`
            : `Touchless Kiosk erstellen`
        }
        okButtonProps={{
          loading:
            createTouchlessMutation.loading || updateTouchlessMutation.loading,
        }}
        cancelButtonProps={{
          disabled:
            createTouchlessMutation.loading || updateTouchlessMutation.loading,
        }}
        onCancel={() => setEditModalShown(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={data => onSave(data)}
          initialValues={{
            project_name: "",
            has_embedded_basket: true,
            has_embedded_browser: false,
            embedded_browser_url: "",
            basket_always_visible: false,
            screen_timeout: 30,
          }}
        >
          <Form.Item
            name="name"
            label="Kiosk Name"
            rules={[{ required: true }]}
          >
            <Input
              disabled={
                createTouchlessMutation.loading ||
                updateTouchlessMutation.loading
              }
            />
          </Form.Item>

          <Form.Item
            name="slug"
            label="Kiosk Slug"
            rules={[{ required: true }]}
          >
            <Input
              disabled={
                createTouchlessMutation.loading ||
                updateTouchlessMutation.loading
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      <ListHeader>
        <PageHeader
          title={`Touchless Kiosks für Projekt ${
            activeProject?.name ?? "..."
          } bearbeiten`}
        />

        <Actions>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingTouchless(null)
              form.resetFields()
              setEditModalShown(true)
            }}
          >
            Neuer Touchless Kiosk
          </Button>
        </Actions>
      </ListHeader>

      <TableContainer>
        <Table
          rowKey="id"
          loading={touchlessQuery.loading}
          dataSource={touchlessQuery.data?.touchlessList || []}
          pagination={false}
          bordered
          rowClassName={(touchless: ListTouchless_touchlessList) =>
            touchless.id === selectedTouchless?.id ? "selected-row" : ""
          }
        >
          <Table.Column title="Name" key={0} dataIndex="name" />

          <Table.Column title="Slug" key={1} dataIndex="slug" />

          <Table.Column
            key={3}
            title="Erstellt"
            dataIndex="created"
            render={(created: string) => dayjs(created).fromNow()}
          />

          <Table.Column
            key={4}
            title="Bearbeitet"
            dataIndex="updated"
            render={(updated: string) => dayjs(updated).fromNow()}
          />

          <Table.Column
            key={5}
            title="Aktionen"
            width={105}
            fixed="right"
            render={(touchless: ListTouchless_touchlessList) => (
              <ListActions>
                <Tooltip title="Kiosk URL kopieren">
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() => {
                      if (
                        copy(`${KIOSK_URL}/${touchless.slug}`, {
                          message: "#{key} drücken zum kopieren",
                        })
                      ) {
                        message.success("URL in die Zwischenablage kopiert")
                      }
                    }}
                  />
                </Tooltip>

                <Tooltip title="Touchless Einstellungen bearbeiten">
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditingTouchless(touchless)
                      form.setFieldsValue({ ...touchless })
                      setEditModalShown(true)
                    }}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    selectedTouchless?.id === touchless.id
                      ? `Ausgewählt`
                      : "Touchless Content bearbeiten"
                  }
                >
                  {selectedTouchless?.id === touchless.id ? (
                    <Button icon={<CheckOutlined />} type="primary" disabled />
                  ) : (
                    <Button
                      icon={<DiffOutlined />}
                      type="primary"
                      onClick={() => {
                        setSelectedTouchless(touchless)
                      }}
                    />
                  )}
                </Tooltip>

                <Tooltip title="Löschen">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setDeletingTouchless(touchless)
                      setDeleteModalShown(true)
                    }}
                  />
                </Tooltip>
              </ListActions>
            )}
          />
        </Table>
      </TableContainer>

      {selectedTouchless && activeProject && (
        <>
          <BaseSettingsContainer>
            <h2>Grundeinstellungen</h2>

            <div>
              <Switch
                checked={showBundle}
                onChange={setShowBundle}
                style={{ marginRight: "0.5rem" }}
              />{" "}
              Basket-Button anzeigen
            </div>

            <div className="settings-items">
              {showBundle && (
                <Card className="content-bundle-upload">
                  <p style={{ marginBottom: "0.75rem", fontSize: "1.05rem" }}>
                    Content Bundle
                  </p>

                  <div className="content-bundle-dragger-container">
                    <Upload.Dragger
                      showUploadList={false}
                      multiple={false}
                      beforeUpload={value => {
                        onUploadBundle(value)
                        return false
                      }}
                      disabled={uploadingBundle}
                      className="content-bundle-dragger"
                    >
                      <>
                        {uploadingBundle && (
                          <LoadingOutlined style={{ fontSize: "2rem" }} spin />
                        )}

                        {!uploadingBundle && (
                          <>
                            <FileOutlined
                              style={{ fontSize: "2rem", marginBottom: "1rem" }}
                            />

                            {bundle && <p>{bundle.split("::")[1]}</p>}
                            {!bundle && <p>Content Bundle hochladen</p>}
                          </>
                        )}
                      </>
                    </Upload.Dragger>
                  </div>
                </Card>
              )}

              <TouchlessContentItem
                title="QR Code Page"
                value={qrPage}
                onChange={v => setQrPage(v as any)}
              />

              <TouchlessContentItem
                title="Start Page"
                value={start}
                onChange={v => setStart(v as any)}
              />
            </div>

            <div>
              <div>Timeout in Sekunden</div>
              <Input
                type="number"
                value={timeoutVal || undefined}
                onChange={ev => setTimeoutVal(ev.target.valueAsNumber)}
                placeholder="30"
              />
            </div>

            <Button
              type="primary"
              htmlType="submit"
              style={{ marginBottom: "1rem" }}
              loading={updateTouchlessMutation.loading}
              onClick={() => onSaveMenu()}
            >
              Grundeinstellungen speichern
            </Button>

            <TouchlessCustomValues
              initialValue={custom}
              onSave={data => onSaveCustom(data)}
            />
          </BaseSettingsContainer>

          <UpdateTouchlessPages
            touchless={selectedTouchless}
            projectID={activeProject.id}
          />
        </>
      )}

      {!selectedTouchless && (
        <NothingSelected>
          Klicken Sie rechts in der Tabelle auf den Content-Button (
          <DiffOutlined />
          ), um den i-Point-Content zu bearbeiten.
        </NothingSelected>
      )}
    </Page>
  )
}

const BaseSettingsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  > .settings-items {
    display: flex;
    gap: 1rem;

    > .content-bundle-upload {
      > .ant-card-body {
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .content-bundle-dragger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .content-bundle-dragger-container {
    flex: 1;

    > span {
      height: 100%;
    }
  }
`

const TableContainer = styled.div`
  .selected-row {
    background: #f0f0f0;
    border: 1px solid #333;
  }
`

const NothingSelected = styled.div`
  width: 100%;
  text-align: center;
  margin: 4rem 0;
  font-size: 1.15rem;
`

export default UpdateTouchless

import { Button, Card, Input, Radio } from "antd"
import React from "react"
import styled from "styled-components"
import ImageUpload from "./ImageUpload"
import { DragHandle } from "./DownloadableItem"
import { DeleteOutlined } from "@ant-design/icons"

interface Value {
  contentType: string
  image?: string
  video?: string
}

interface Props {
  title?: string
  value?: Value
  onChange?: (v: Value) => void
  dragHandle?: any
  onDelete?: () => void
}

const TouchlessContentItem: React.FC<Props> = ({
  title,
  value = {},
  onChange = () => {},
  dragHandle,
  onDelete,
}) => {
  return (
    <Container>
      <Card>
        {title && <div className="title">{title}</div>}

        <div className="content-header">
          {dragHandle && (
            <div {...dragHandle}>
              <DragHandle
                style={{
                  borderRight: "none",
                  width: "auto",
                  minHeight: 0,
                  marginRight: "1rem",
                }}
              />
            </div>
          )}

          <Radio.Group
            onChange={({ target: { value: contentType } }) => {
              onChange({ ...value, contentType })
            }}
            value={value?.contentType ?? "image"}
            optionType="button"
            buttonStyle="solid"
            className="content-type-select"
          >
            <Radio.Button value="image">Bild</Radio.Button>
            <Radio.Button value="video">Video</Radio.Button>
          </Radio.Group>
        </div>

        {(value?.contentType ?? "image") === "image" && (
          <ImageUpload
            value={value?.image}
            onChange={image =>
              onChange({
                image,
                video: value?.image,
                contentType: value?.contentType ?? "image",
              })
            }
            style={{ width: 200, height: 113 }}
          />
        )}

        {value?.contentType === "video" && (
          <>
            <Input
              placeholder="Vimeo-ID"
              value={value?.video}
              onChange={ev =>
                onChange({
                  video: ev.target.value,
                  image: value?.image,
                  contentType: value?.contentType ?? "video",
                })
              }
            />

            {/\d+/.test(value?.video ?? ``) && (
              <img
                alt="Vimeo Vorschau"
                src={`https://vumbnail.com/${value?.video}_medium.jpg`}
                style={{
                  width: 200,
                  height: 150,
                  objectFit: "contain",
                  marginTop: "0.5rem",
                }}
              />
            )}
          </>
        )}

        {onDelete && (
          <Button
            style={{ marginTop: "1rem", width: "100%" }}
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDelete()}
          >
            Löschen
          </Button>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div`
  width: 250px;

  .title {
    margin-bottom: 0.75rem;
    font-size: 1.05rem;
  }

  .content-header {
    display: flex;
  }

  .content-type-select {
    margin-bottom: 1rem;
  }

  .image-container {
    width: 200px;
    height: 113px;
    min-height: 113px;

    > span {
      height: 113px;
      min-height: unset;
    }

    span.ant-upload {
      padding: 0;
    }

    .uploaded {
      width: 198px;
      height: 111px;
      min-height: unset;
      overflow: hidden;
    }
  }
`

export default TouchlessContentItem

import React, { useContext } from "react"
import { Switch, useRouteMatch, Route, Redirect, Link } from "react-router-dom"
import { ProjectContext, UserContext } from "../App"
import styled from "styled-components"
import { Empty, Button } from "antd"
import { FolderAddOutlined } from "@ant-design/icons"
import { Role } from "../graphql-types-global"
import ListBaskets from "./ListBaskets"

const Baskets = () => {
  const match = useRouteMatch()
  const { activeProject } = useContext(ProjectContext)
  const user = useContext(UserContext)

  return (
    <Switch>
      <Route path={`${match.path}/:projectId`}>
        <ListBaskets />
      </Route>

      <Route path={match.path} exact>
        {activeProject && <Redirect to={`${match.path}/${activeProject.id}`} />}

        {!activeProject && (
          <EmptyContainer>
            <Empty description="Bitte wählen Sie ein Projekt aus">
              {user?.role === Role.ADMIN && (
                <Link to="/projects/create">
                  <Button type="primary" icon={<FolderAddOutlined />}>
                    Neues Projekt
                  </Button>
                </Link>
              )}
            </Empty>
          </EmptyContainer>
        )}
      </Route>

      <Route path={`${match.path}/*`}>
        <Redirect to="/baskets" />
      </Route>
    </Switch>
  )
}

const EmptyContainer = styled.div`
  margin-top: 4rem;
`

export default Baskets

import React, { useContext, useState } from "react"
import { Page, CardSection, ListHeader } from "../util/page"
import { PageHeader, Form, Input, Select, message, Button } from "antd"
import { useHistory, useParams, Redirect } from "react-router"
import { gql, useMutation, ApolloError, useQuery } from "@apollo/client"
import { LIST_USERS_QUERY } from "./ListUsers"
import { useForm } from "antd/lib/form/Form"
import { Role } from "../graphql-types-global"
import { UserFragments } from "./ListUsers"
import {
  CreateUserMutation,
  CreateUserMutationVariables,
} from "./types/CreateUserMutation"
import {
  UpdateUserQuery,
  UpdateUserQueryVariables,
} from "./types/UpdateUserQuery"
import {
  ListUsersQuery,
  ListUsersQuery_users_users,
} from "./types/ListUsersQuery"
import { GetUserQuery, GetUserQueryVariables } from "./types/GetUserQuery"
import { UpdateUserMutationVariables } from "./types/UpdateUserMutation"
import { UserContext } from "../App"
import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from "./types/UpdatePasswordMutation"
import { isDuplicateRecordError } from "../util/errors"
import { ME_QUERY, LIST_PROJECTS_QUERY } from "../util/queries"
import { ListProjectsQuery } from "../types/ListProjectsQuery"
import { renderRole } from "../util/formats"
import { useLocation } from "react-use"

const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation($user: CreateUser!) {
    createUser(user: $user) {
      ...UserFields
    }
  }
  ${UserFragments.UserFields}
`

const GET_USER_QUERY = gql`
  query GetUserQuery($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${UserFragments.UserFields}
`

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation($id: ID!, $version: Int!, $changes: UpdateUser!) {
    updateUser(id: $id, version: $version, changes: $changes) {
      ...UserFields
    }
  }
  ${UserFragments.UserFields}
`

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordMutation(
    $version: Int!
    $oldPw: String!
    $newPw: String!
  ) {
    updatePassword(version: $version, oldPw: $oldPw, newPw: $newPw)
  }
`

interface UserForm {
  email: string
  name: string
  role: Role
  projects: string[]
}

const initialUserFormValues: UserForm = {
  email: "",
  name: "",
  role: Role.USER,
  projects: [],
}

interface UpdatePasswordForm {
  oldPw: string
  newPw: string
}

const initialUpdatePasswordFormValues: UpdatePasswordForm = {
  oldPw: "",
  newPw: "",
}

interface Params {
  id?: string
}

const CreateUser = () => {
  const history = useHistory()
  const params = useParams<Params>()
  const location = useLocation()

  const [form] = useForm()
  const isEditing = !!params.id || location.pathname === "/profile"

  const user = useContext(UserContext)
  const isEditingSelf = location.pathname === "/profile"

  const { data, loading: loadingUser } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_QUERY, {
    variables: { id: isEditingSelf ? user?.id ?? "" : params.id ?? "" },
    skip: !isEditing,
    onCompleted: (data) => {
      if (!isEditing) return
      form.setFieldsValue({ ...data.user })
      setShowProjectSelect(data.user.role !== Role.ADMIN)
    },
  })

  const [createUser, { loading: creatingUser }] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      { query: LIST_USERS_QUERY, variables: { page: 0, pageSize: 10 } },
    ],
  })

  const [updateUser, { loading: updatingUser }] = useMutation<
    UpdateUserQuery,
    UpdateUserQueryVariables
  >(UPDATE_USER_MUTATION, {
    notifyOnNetworkStatusChange: true,
    update: (store, { data }) => {
      let cached: ListUsersQuery | null = null
      try {
        cached = store.readQuery({ query: LIST_USERS_QUERY })
      } catch (err) {}

      const users = cached?.users?.users ?? []
      const userIndex = users.findIndex(
        (user) => user.id === data?.updateUser?.id
      )

      if (userIndex !== -1) {
        const updatedUser: ListUsersQuery_users_users = {
          ...data!!.updateUser,
        }

        const updatedUsers = users.map((user, index) =>
          index === userIndex ? updatedUser : user
        )

        store.writeQuery<ListUsersQuery>({
          query: LIST_USERS_QUERY,
          data: {
            ...cached,
            users: {
              ...cached!!.users,
              users: updatedUsers,
            },
          },
        })
      }
    },
  })

  const [updatePassword, { loading: updatingPassword }] = useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UPDATE_PASSWORD_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      { query: ME_QUERY },
      {
        query: GET_USER_QUERY,
        variables: { id: isEditingSelf ? user?.id ?? "" : params.id ?? "" },
      },
    ],
  })

  const onSaveError = (err: ApolloError) => {
    if (err.networkError) {
      message.error("Beim Speichern ist ein Fehler aufgetreten.")
      return
    }

    if (isDuplicateRecordError(err)) {
      message.error("Ein Benutzer mit dieser E-Mail-Adresse existiert bereits.")
      return
    }

    message.error("Ein unbekannter Fehler ist aufgetreten.")
  }

  const onPasswordSaveError = (err: ApolloError) => {
    if (err.networkError) {
      message.error("Beim Speichern ist ein Fehler aufgetreten.")
      return
    }

    if (err.graphQLErrors?.[0]?.message === "resolver-pw-mismatch") {
      message.error("Das alte Passwort war inkorrekt.")
      return
    }

    message.error("Ein unbekannter Fehler ist aufgetreten.")
  }

  const onFinish = async (values: UserForm) => {
    const projects = values.role === Role.USER ? values.projects ?? [] : []

    if (isEditing) {
      const variables: UpdateUserMutationVariables = {
        id: isEditingSelf ? user?.id ?? "" : params.id!!,
        version: data?.user?.version ?? 0,
        changes: { ...values, projects },
      }

      try {
        await updateUser({ variables })

        if (isEditingSelf) message.success("Profil erfolgreich gespeichert.")
        else {
          message.success("Benutzer erfolgreich gespeichert.")
          history.push("/users")
        }
      } catch (err) {
        onSaveError(err as ApolloError)
      }
    } else {
      const variables: CreateUserMutationVariables = {
        user: { ...values, projects },
      }

      try {
        await createUser({ variables })
        message.success("Benutzer erfolgreich erstellt.")
        history.push("/users")
      } catch (err) {
        onSaveError(err as ApolloError)
      }
    }
  }

  const onFinishFailed = () => {
    message.error("Bitte füllen Sie alle benötigten Felder aus.")
  }

  const disableFormFields =
    creatingUser || updatingUser || loadingUser || updatingPassword

  const onFinishUpdatePassword = async (values: UpdatePasswordForm) => {
    const variables: UpdatePasswordMutationVariables = {
      version: data?.user?.version ?? 0,
      newPw: values.newPw,
      oldPw: values.oldPw,
    }

    try {
      await updatePassword({ variables })
      message.success("Passwort erfolgreich geändert.")
    } catch (err) {
      onPasswordSaveError(err as ApolloError)
    }
  }

  const { data: projectsData, loading: loadingProjects } = useQuery<
    ListProjectsQuery
  >(LIST_PROJECTS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const [showProjectSelect, setShowProjectSelect] = useState<boolean>(true)

  if (params.id && user?.id === params.id && location.pathname !== "/profile")
    return <Redirect to="/profile" />

  return (
    <Page>
      {isEditingSelf ? (
        <ListHeader>
          <PageHeader title="Profil bearbeiten" />
        </ListHeader>
      ) : (
        <PageHeader
          title={
            isEditing
              ? `Benutzer ${data?.user?.email ?? "..."} bearbeiten`
              : "Benutzer erstellen"
          }
          onBack={() => history.push("/users")}
        />
      )}

      <CardSection>
        <Form
          name="user"
          form={form}
          initialValues={initialUserFormValues}
          layout="vertical"
          onFinish={(values) => onFinish(values as UserForm)}
          onFinishFailed={() => onFinishFailed()}
        >
          <Form.Item
            label="E-Mail"
            name="email"
            rules={[
              { required: true, message: "Bitte E-Mail eingeben" },
              { type: "email", message: "Keine gültige E-Mail Addresse" },
            ]}
          >
            <Input placeholder="E-Mail" disabled={disableFormFields} />
          </Form.Item>

          <Form.Item
            name="name"
            rules={[{ required: true, message: "Bitte Namen eingeben" }]}
            label="Name"
          >
            <Input placeholder="Name" disabled={disableFormFields} />
          </Form.Item>

          {!isEditingSelf && (
            <Form.Item name="role" label="Rolle">
              <Select
                disabled={disableFormFields}
                loading={loadingProjects}
                onChange={(value) => {
                  setShowProjectSelect(value !== Role.ADMIN)
                }}
              >
                {Object.values(Role).map((role) => (
                  <Select.Option value={role} key={role}>
                    {renderRole(role)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {showProjectSelect && !isEditingSelf && (
            <Form.Item name="projects" label="Projektberechtigungen">
              <Select mode="multiple">
                {projectsData?.projects?.projects?.map((project) => (
                  <Select.Option value={project.id} key={project.id}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "1rem" }}
            disabled={loadingUser || updatingPassword}
            loading={creatingUser || updatingUser}
          >
            {isEditingSelf
              ? "Profil speichern"
              : isEditing
              ? "Benutzer speichern"
              : "Benutzer erstellen"}
          </Button>
        </Form>
      </CardSection>

      {isEditingSelf && (
        <CardSection title="Passwort ändern">
          <Form
            name="updatePassword"
            initialValues={initialUpdatePasswordFormValues}
            layout="vertical"
            onFinish={(values) =>
              onFinishUpdatePassword(values as UpdatePasswordForm)
            }
            onFinishFailed={() => onFinishFailed()}
          >
            <Form.Item
              label="Altes Passwort"
              name="oldPw"
              rules={[
                { required: true, message: "Bitte altes Passwort eingeben" },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 1rem)",
                marginRight: "1rem",
              }}
            >
              <Input.Password
                placeholder="Passwort"
                disabled={disableFormFields}
              />
            </Form.Item>

            <Form.Item
              label="Neues Passwort"
              name="newPw"
              rules={[
                { required: true, message: "Bitte neues Passwort eingeben" },
              ]}
              style={{ display: "inline-block", width: "50%" }}
            >
              <Input.Password
                placeholder="Passwort"
                disabled={disableFormFields}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "1rem" }}
              disabled={loadingUser || creatingUser || updatingUser}
              loading={updatingPassword}
            >
              Passwort ändern
            </Button>
          </Form>
        </CardSection>
      )}
    </Page>
  )
}

export default CreateUser

import React, { useState, useContext, useEffect } from "react"
import _ from "lodash"
import { Page, ListHeader, Actions } from "../util/page"
import { Card, Upload, Button, PageHeader, message, Empty, Modal } from "antd"
import {
  CloudUploadOutlined,
  PlusOutlined,
  PictureOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import styled, { css } from "styled-components"
import { useQuery, useApolloClient } from "@apollo/client"
import { useParams, Redirect } from "react-router"
import { ProjectContext } from "../App"
import EditableSpan from "../components/EditableSpan"
import axios, { AxiosError } from "axios"
import { CMS_URL, token } from ".."
import { v4 as uuid } from "uuid"
import { timeout } from "../util/util"
import { sanitizeFilename } from "../util/sanitize"
import { GET_PROJECT_QUERY } from "../projects/CreateProject"
import Axios from "axios"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "../projects/types/GetProjectQuery"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DraggableLocation,
} from "react-beautiful-dnd"
import {
  useCreateCategoryMutation,
  useCreateDownloadableMutation,
  useDeleteCategoryMutation,
  useDeleteDownloadableMutation,
  useListDownloadablesQuery,
  useUpdateCategoryMutation,
  useUpdateDownloadableMutation,
  useUpdateProjectDefaultIconMutation,
  LIST_DOWNLOADABLES_QUERY,
  useReorderDownloadablesMutation,
  useDeleteAllDownloadablesMutation,
} from "./queries"
import { CreateCategoryMutationVariables } from "./types/CreateCategoryMutation"
import { UpdateCategoryMutationVariables } from "./types/UpdateCategoryMutation"
import { DeleteCategoryMutationVariables } from "./types/DeleteCategoryMutation"
import { DeleteDownloadableMutationVariables } from "./types/DeleteDownloadableMutation"
import { CreateDownloadableMutationVariables } from "./types/CreateDownloadableMutation"
import { UpdateDownloadableMutationVariables } from "./types/UpdateDownloadableMutation"
import DownloadableItem, { DragHandle } from "../components/DownloadableItem"
import {
  ListDownloadablesQuery,
  ListDownloadablesQueryVariables,
} from "./types/ListDownloadablesQuery"

const fileExtensionRegex = /(?:\.([^.]+))?$/
const fileNameRegex = /\.[^/.]+$/

interface Params {
  projectId: string
}

const FileThumb = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`

const renderFileIcon = (extension: string, previewIcon?: string) => {
  if (previewIcon)
    return <FileThumb src={`${CMS_URL}/images/${previewIcon}`} alt="Vorschau" />

  switch (extension) {
    case ".jpg":
    case ".jpeg":
      return <FileImageOutlined />
    case ".pdf":
      return <FilePdfOutlined />
  }

  return <FileOutlined />
}

const ListDownloadables = () => {
  const params = useParams<Params>()
  const { activeProject, setActiveProject } = useContext(ProjectContext)

  const [showNewCategory, setShowNewCategory] = useState(false)
  const [newCategoryName, setNewCategoryName] = useState("")

  // query hooks
  const { dlbls, loadingDlbls } = useListDownloadablesQuery(activeProject)
  const { reorder: reorderDlbls, reordering } = useReorderDownloadablesMutation(
    activeProject
  )

  const { updateCategory } = useUpdateCategoryMutation(activeProject)
  const { createCategory, creatingCategory } = useCreateCategoryMutation(
    activeProject
  )
  const { deleteCategory } = useDeleteCategoryMutation(activeProject)

  const { createDownloadable } = useCreateDownloadableMutation(activeProject)
  const { updateDownloadable } = useUpdateDownloadableMutation(activeProject)
  const { deleteDownloadable } = useDeleteDownloadableMutation(activeProject)

  const { updateProjectDefaultIcon } = useUpdateProjectDefaultIconMutation()

  const onCreateCategory = async (name: string) => {
    if (!name) return

    const variables: CreateCategoryMutationVariables = {
      category: {
        name,
        projectId: activeProject?.id ?? "",
      },
    }

    try {
      await createCategory({ variables })
      message.success("Kategorie erfolgreich erstellt")
      setShowNewCategory(false)
      setNewCategoryName("")
    } catch (err) {
      message.error("Ein Fehler ist aufgetreten.")
    }
  }

  const [editingCategoryNames, setEditingCategoryNames] = useState<{
    [id: string]: string
  }>({})

  const [updatingCategoryIds, setUpdatingCategoryIds] = useState<{
    [id: string]: boolean
  }>({})

  const onUpdateCategory = async (
    id: string,
    version: number,
    name?: string
  ) => {
    if (!name) return

    const variables: UpdateCategoryMutationVariables = {
      id,
      version,
      changes: { name },
    }

    try {
      setUpdatingCategoryIds({ [id]: true, ...updatingCategoryIds })
      await updateCategory({ variables })
      message.success("Kategorie erfolgreich gespeichert")

      setEditingCategoryNames(_.omit(editingCategoryNames, id))
      setUpdatingCategoryIds(_.omit(updatingCategoryIds, id))
    } catch (err) {
      message.error("Ein Fehler ist aufgetreten.")
      setUpdatingCategoryIds(_.omit(updatingCategoryIds, id))
    }
  }

  const onDeleteCategory = (id: string, version: number, name: string) => {
    Modal.warning({
      title: `Kategorie ${name} löschen`,
      content: (
        <p>
          Sind Sie sicher? Diese Aktion wird{" "}
          <b>alle Downloadables in dieser Kategorie löschen.</b>
        </p>
      ),
      okCancel: true,
      maskClosable: true,
      okText: "Kategorie löschen",
      okType: "danger",
      cancelText: "Abbrechen",
      onOk: () => onConfirmDeleteCategory(id, version),
      onCancel: () => {},
    })
  }

  const [deletingCategoryIds, setDeletingCategoryIds] = useState<{
    [id: string]: boolean
  }>({})

  const onConfirmDeleteCategory = async (id: string, version: number) => {
    const variables: DeleteCategoryMutationVariables = { id, version }

    try {
      setDeletingCategoryIds({ [id]: true, ...deletingCategoryIds })
      await deleteCategory({ variables })
      message.success("Kategorie erfolgreich gelöscht")

      setDeletingCategoryIds(_.omit(deletingCategoryIds, id))
    } catch (err) {
      message.error("Ein Fehler ist aufgetreten.")
      setDeletingCategoryIds(_.omit(deletingCategoryIds, id))
    }
  }

  const [creatingDownloadables, setCreatingDownloadables] = useState<{
    [catId: string]: {
      [tempId: string]: {
        tempId: string
        progress: number
        filename: string
        extension: string
        hasError: boolean
      }
    }
  }>({})

  const { data } = useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GET_PROJECT_QUERY,
    {
      variables: { id: params.projectId!! },
      notifyOnNetworkStatusChange: true,
      onError: () => {
        message.error("Das Projekt konnte nicht gefunden werden.")
      },
      onCompleted: data => {
        if (!data) return
        setActiveProject(data.project.id)
      },
    }
  )

  const onUploadDownloadable = async (file: File, categoryId: string) => {
    const formData = new FormData()
    formData.append("file", file)

    const extension = file.name.match(fileExtensionRegex)?.[0]
    if (!extension) {
      message.error(`Die Datei "${file.name}" hat ein ungültiges Format.`)
      return
    }

    const filename = file.name.replace(fileNameRegex, "")
    const tempId = uuid()

    setCreatingDownloadables({
      ...creatingDownloadables,
      [categoryId]: {
        ...(creatingDownloadables[categoryId] ?? {}),
        [tempId]: {
          tempId,
          progress: 0,
          filename,
          extension,
          hasError: false,
        },
      },
    })

    try {
      const res = await axios.post(`${CMS_URL}/files/downloadable`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: ({ loaded, total }) => {
          setCreatingDownloadables(creatingDownloadables => ({
            ...creatingDownloadables,
            [categoryId]: {
              ...creatingDownloadables[categoryId],
              [tempId]: {
                ...creatingDownloadables[categoryId][tempId],
                progress: (loaded / total) * 100,
              },
            },
          }))
        },
      })

      setCreatingDownloadables(creatingDownloadables => ({
        ...creatingDownloadables,
        [categoryId]: {
          ...creatingDownloadables[categoryId],
          [tempId]: {
            ...creatingDownloadables[categoryId][tempId],
            progress: 100,
          },
        },
      }))

      const variables: CreateDownloadableMutationVariables = {
        downloadable: {
          projectId: activeProject?.id ?? "",
          categoryId,
          filename: file.name,
          file: res.data.fileId,
          name: filename,
          description: "",
        },
      }

      await timeout(200)
      await createDownloadable({ variables })
      message.success(`Downloadable "${file.name}" erfolgreich erstellt`)
      setCreatingDownloadables(creatingDownloadables => ({
        ...creatingDownloadables,
        [categoryId]: _.omit(creatingDownloadables[categoryId], tempId),
      }))
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      setCreatingDownloadables(creatingDownloadables => ({
        ...creatingDownloadables,
        [categoryId]: {
          ...creatingDownloadables[categoryId],
          [tempId]: {
            ...creatingDownloadables[categoryId][tempId],
            hasError: true,
          },
        },
      }))

      if (
        (err as AxiosError).response?.data?.details?.[0] === "invalid file type"
      )
        message.error(`Das Dateiformat "${extension}" ist nicht erlaubt.`)
      else
        message.error(
          `Beim Upload der Datei "${file.name}" ist ein Fehler aufgetreten.`
        )

      await timeout(1000)
      setCreatingDownloadables(creatingDownloadables => ({
        ...creatingDownloadables,
        [categoryId]: _.omit(creatingDownloadables[categoryId], tempId),
      }))
    }
  }

  const [deletingDownloadableIds, setDeletingDownloadableIds] = useState<{
    [id: string]: boolean
  }>({})

  const onDeleteDownloadable = async (id: string, version: number) => {
    const variables: DeleteDownloadableMutationVariables = { id, version }

    try {
      setDeletingDownloadableIds({ [id]: true, ...deletingDownloadableIds })
      await deleteDownloadable({ variables })
      message.success("Downloadable erfolgreich gelöscht")

      setDeletingDownloadableIds(_.omit(deletingDownloadableIds, id))
    } catch (err) {
      message.error("Ein Fehler ist aufgetreten.")
      setDeletingDownloadableIds(_.omit(deletingDownloadableIds, id))
    }
  }

  const [editingDownloadableNames, setEditingDownloadableNames] = useState<{
    [id: string]: string
  }>({})

  const [editingDownloadableDescs, setEditingDownloadableDescs] = useState<{
    [id: string]: string
  }>({})

  const [updatingDownloadableIds, setUpdatingDownloadableIds] = useState<{
    [id: string]: boolean
  }>({})

  const onUpdateDownloadable = async (
    id: string,
    version: number,
    name: string,
    previewImage: string,
    description: string
  ) => {
    const variables: UpdateDownloadableMutationVariables = {
      id,
      version,
      changes: { name, previewImage, description },
    }

    try {
      setUpdatingDownloadableIds({ [id]: true, ...updatingCategoryIds })
      await updateDownloadable({ variables })
      message.success("Downloadable erfolgreich gespeichert")

      setEditingDownloadableNames(_.omit(editingCategoryNames, id))
      setEditingDownloadableDescs(_.omit(editingCategoryNames, id))
      setUpdatingDownloadableIds(_.omit(updatingCategoryIds, id))
    } catch (err) {
      message.error("Ein Fehler ist aufgetreten.")
      setUpdatingDownloadableIds(_.omit(updatingCategoryIds, id))
    }
  }

  const [showIconModal, setShowIconModal] = useState(false)
  const [uploadingPreview, setUploadingPreview] = useState(false)

  const onUploadPreviewImage = async (file: File) => {
    const formData = new FormData()
    formData.append("file", file)

    const extension = file.name.match(fileExtensionRegex)?.[0]
    if (!extension) {
      message.error(`Die Datei "${file.name}" hat ein ungültiges Format.`)
      return
    }

    if (![".jpeg", ".jpg", ".png"].includes(extension)) {
      message.error(`Die Datei "${file.name}" hat ein ungültiges Format.`)
      return
    }

    setUploadingPreview(true)
    try {
      const formData = new FormData()
      formData.append("file", file!!)

      const res = await Axios.post(
        `${CMS_URL}/files/image?thumb=true`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      await updateProjectDefaultIcon({
        variables: {
          id: data!!.project.id,
          version: data!!.project.version,
          changes: { defaultDownloadableIcon: res.data.fileId },
        },
      })

      setUploadingPreview(false)
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      setUploadingPreview(false)

      if ((err as AxiosError).response?.data === "invalid file type\n")
        message.error(`Das Dateiformat "${extension}" ist nicht erlaubt.`)
      else if (
        (err as AxiosError).response?.data === "max width or height exceeded\n"
      )
        message.error(`Das Bild darf nicht größer als 400x400 sein.`)
      else
        message.error(
          `Beim Upload der Datei "${file.name}" ist ein Fehler aufgetreten.`
        )
    }
  }

  const reorder: <T>(
    list: Array<T>,
    startIndex: number,
    endIndex: number
  ) => Array<T> = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const move: <T>(
    source: Array<T>,
    dest: Array<T>,
    dropSource: DraggableLocation,
    dropDest: DraggableLocation
  ) => { [id: string]: Array<T> } = (source, dest, dropSource, dropDest) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(dest)
    const [removed] = sourceClone.splice(dropSource.index, 1)

    destClone.splice(dropDest.index, 0, removed)
    const result: any = {}
    result[dropSource.droppableId] = sourceClone
    result[dropDest.droppableId] = destClone

    return result
  }

  const moveCat = (startIndex: number, endIndex: number) => {
    if (!dlbls) return

    const items = reorder(dlbls.downloadables.categories, startIndex, endIndex)

    const reordered = _.cloneDeep(dlbls.downloadables)
    reordered.categories = items
    store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>({
      query: LIST_DOWNLOADABLES_QUERY,
      data: { downloadables: { ...reordered } },
      variables: { projectId: activeProject?.id ?? "" },
    })

    const newOrder = reordered.categories.map(cat => ({
      categoryId: cat.category.id,
      downloadables: cat.downloadables.map(dl => dl.id),
    }))

    reorderDlbls({
      variables: {
        projectId: data!!.project.id,
        version: data!!.project.version,
        order: newOrder,
      },
    })
  }

  const store = useApolloClient()
  const getCatIndex = (id: string) => {
    return (
      dlbls?.downloadables.categories.findIndex(it => it.category.id === id) ??
      0
    )
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!dlbls || !destination) return

    let newOrder = []
    if (source.droppableId === destination.droppableId) {
      const catIndex = getCatIndex(source.droppableId)
      const items = reorder(
        dlbls!!.downloadables.categories[catIndex].downloadables,
        source.index,
        destination.index
      )

      const reordered = _.cloneDeep(dlbls.downloadables)
      reordered.categories[catIndex].downloadables = items
      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          data: { downloadables: { ...reordered } },
          variables: { projectId: activeProject?.id ?? "" },
        }
      )

      newOrder = reordered.categories.map(cat => ({
        categoryId: cat.category.id,
        downloadables: cat.downloadables.map(dl => dl.id),
      }))
    } else {
      const catIndexSource = getCatIndex(source.droppableId)
      const catIndexDest = getCatIndex(destination.droppableId)

      const result = move(
        dlbls!!.downloadables.categories[catIndexSource].downloadables,
        dlbls!!.downloadables.categories[catIndexDest].downloadables,
        source,
        destination
      )

      const reordered = _.cloneDeep(dlbls.downloadables)
      reordered.categories[catIndexSource].downloadables =
        result[source.droppableId]
      reordered.categories[catIndexDest].downloadables =
        result[destination.droppableId]
      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          data: { downloadables: { ...reordered } },
          variables: { projectId: activeProject?.id ?? "" },
        }
      )

      newOrder = reordered.categories.map(cat => ({
        categoryId: cat.category.id,
        downloadables: cat.downloadables.map(dl => dl.id),
      }))
    }

    reorderDlbls({
      variables: {
        projectId: data!!.project.id,
        version: data!!.project.version,
        order: newOrder,
      },
    })
  }

  const { deleteCategories } = useDeleteAllDownloadablesMutation(
    activeProject?.id ?? "",
    activeProject
  )

  useEffect(() => {
    if (!activeProject) setActiveProject(params.projectId)
  }, [activeProject, params])

  if (activeProject && activeProject.id !== params.projectId)
    return <Redirect to={`/downloadables/${activeProject.id}`} />

  return (
    <>
      <Modal
        title="Downloadable-Icon"
        visible={showIconModal}
        okButtonProps={{ hidden: true }}
        cancelText="Schließen"
        onCancel={() => setShowIconModal(false)}
      >
        <div style={{ display: "flex" }}>
          <Upload
            showUploadList={false}
            disabled={uploadingPreview}
            beforeUpload={v => {
              onUploadPreviewImage(v)
              return false
            }}
            style={{ position: "relative" }}
          >
            <DownloadableIcon style={{ paddingTop: "0.25rem" }}>
              {renderFileIcon(
                "",
                data?.project?.defaultDownloadableIcon ?? undefined
              )}
            </DownloadableIcon>
          </Upload>

          <p style={{ marginLeft: "2rem" }}>
            Klicken Sie auf das Bild oder ziehen Sie eine Datei (JPG oder PNG,
            maximal 400x400, bevorzugt quadratisch) hinein, um ein Fallback-Icon
            zu setzen. Dieses Icon wird angezeigt, falls für eine Downloadable
            kein eigenes Icon hochgeladen wurde.
          </p>
        </div>
      </Modal>

      <Page>
        <ListHeader>
          <PageHeader title="Downloadables" />

          <Actions>
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Alle Downloadables löschen",
                  content:
                    "Sind Sie sicher, dass Sie alle Downloadables für dieses Projekt löschen wollen? Hierbei werden auch alle Baskets gelöscht.",
                  okText: "Ja, alles Löschen",
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => deleteCategories(),
                  cancelText: "Abbrechen",
                })
              }}
            >
              Alle Löschen
            </Button>

            <Button
              icon={<PictureOutlined />}
              onClick={() => setShowIconModal(true)}
            >
              Standard Downloadable-Icon setzen
            </Button>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={showNewCategory}
              onClick={() => setShowNewCategory(true)}
            >
              Neue Kategorie
            </Button>
          </Actions>
        </ListHeader>

        {showNewCategory && (
          <CategoryCard
            title={
              <EditableSpan
                value={newCategoryName}
                onChange={v => setNewCategoryName(v ?? "")}
                canSave={newCategoryName?.length > 0}
                autofocus
                placeholder="Neue Kategorie"
                style={{ marginRight: "2rem" }}
                saveText="Erstellen"
                loading={creatingCategory}
                onSave={v => onCreateCategory(v ?? "")}
              />
            }
            extra={
              <Actions>
                <Button
                  onClick={() => {
                    setShowNewCategory(false)
                    setNewCategoryName("")
                  }}
                >
                  Abbrechen
                </Button>
              </Actions>
            }
          >
            <Upload.Dragger disabled>
              <UploadIconContainer>
                <CloudUploadOutlined />
              </UploadIconContainer>
            </Upload.Dragger>
          </CategoryCard>
        )}

        {!showNewCategory &&
          !loadingDlbls &&
          dlbls?.downloadables?.categories?.length === 0 && (
            <EmptyContainer>
              <Empty description="Keine Downloadables gefunden" />
            </EmptyContainer>
          )}

        <ListContainer disabled={reordering}>
          <DragDropContext onDragEnd={res => onDragEnd(res)}>
            {dlbls?.downloadables?.categories?.map((c, index) => (
              <CategoryCard
                key={c.category.id}
                title={
                  <CategoryHeader>
                    <div className="reorder-buttons">
                      {index > 0 && (
                        <CaretUpOutlined
                          onClick={() => moveCat(index, index - 1)}
                        />
                      )}

                      {index < dlbls?.downloadables?.categories?.length - 1 && (
                        <CaretDownOutlined
                          onClick={() => moveCat(index, index + 1)}
                        />
                      )}
                    </div>

                    <EditableSpan
                      disabled={deletingCategoryIds[c.category.id]}
                      onSave={v =>
                        onUpdateCategory(c.category.id, c.category.version, v)
                      }
                      value={
                        editingCategoryNames[c.category.id] ?? c.category.name
                      }
                      onChange={v => {
                        if (v !== c.category.name)
                          setEditingCategoryNames({
                            ...editingCategoryNames,
                            [c.category.id]: v ?? "",
                          })
                        else
                          setEditingCategoryNames(
                            _.omit(editingCategoryNames, c.category.id)
                          )
                      }}
                      style={{ marginRight: "2rem" }}
                      saveText="Speichern"
                      saveDisabled={
                        !Object.keys(editingCategoryNames).includes(
                          c.category.id
                        )
                      }
                      canSave={
                        editingCategoryNames[c.category.id]?.length > 0 &&
                        editingCategoryNames[c.category.id] !== c.category.name
                      }
                      loading={!!updatingCategoryIds[c.category.id]}
                    />
                  </CategoryHeader>
                }
                extra={
                  <Actions>
                    <Button
                      danger
                      disabled={!!updatingCategoryIds[c.category.id]}
                      loading={deletingCategoryIds[c.category.id]}
                      onClick={() =>
                        onDeleteCategory(
                          c.category.id,
                          c.category.version,
                          c.category.name
                        )
                      }
                    >
                      Löschen
                    </Button>
                  </Actions>
                }
              >
                <Droppable droppableId={c.category.id} type="dl-droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {c.downloadables.map((d, index) => (
                        <Draggable key={d.id} draggableId={d.id} index={index}>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                marginBottom: "1rem",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <DownloadableItem
                                dragHandle={
                                  <div {...provided.dragHandleProps}>
                                    <DragHandle />
                                  </div>
                                }
                                key={d.id}
                                extension={
                                  d.filename.match(fileExtensionRegex)?.[0] ??
                                  ""
                                }
                                originalFilename={d.filename}
                                state="saved"
                                deleting={deletingDownloadableIds[d.id]}
                                updating={updatingDownloadableIds[d.id]}
                                onDelete={() =>
                                  onDeleteDownloadable(d.id, d.version)
                                }
                                onSave={(name, desc) =>
                                  onUpdateDownloadable(
                                    d.id,
                                    d.version,
                                    name,
                                    d.previewImage,
                                    desc
                                  )
                                }
                                filenameValue={
                                  editingDownloadableNames[d.id] ?? d.name
                                }
                                downloadableId={d.id}
                                descriptionValue={
                                  editingDownloadableDescs[d.id] ??
                                  d.description
                                }
                                onDescriptionChange={v => {
                                  setEditingDownloadableDescs({
                                    ...editingDownloadableDescs,
                                    [d.id]: v ?? "",
                                  })
                                }}
                                fallbackImage={
                                  data?.project.defaultDownloadableIcon ??
                                  undefined
                                }
                                previewImage={d.previewImage}
                                onPreviewImageChange={pId => {
                                  onUpdateDownloadable(
                                    d.id,
                                    d.version,
                                    d.name,
                                    pId,
                                    d.description
                                  )
                                }}
                                onFilenameChange={v => {
                                  if (v !== d.name)
                                    setEditingDownloadableNames({
                                      ...editingDownloadableNames,
                                      [d.id]: sanitizeFilename(v) ?? "",
                                    })
                                  else
                                    setEditingDownloadableNames(
                                      _.omit(editingDownloadableNames, d.id)
                                    )
                                }}
                                saveDisabled={
                                  (!Object.keys(
                                    editingDownloadableNames
                                  ).includes(d.id) &&
                                    !Object.keys(
                                      editingDownloadableDescs
                                    ).includes(d.id)) ||
                                  editingDownloadableNames[d.id]?.length === 0
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <div>
                  {Object.values(
                    creatingDownloadables[c.category.id] ?? {}
                  ).map((d, index) => (
                    <div
                      key={d.tempId || index}
                      style={{ marginBottom: "1rem" }}
                    >
                      <DownloadableItem
                        filenameValue={d.filename}
                        extension={d.extension}
                        fallbackImage={
                          data?.project.defaultDownloadableIcon ?? undefined
                        }
                        originalFilename={`${d.filename}.${d.extension}`}
                        state={d.hasError ? "failed" : "uploading"}
                        uploadingPercent={d.progress}
                        disabled
                      />
                    </div>
                  ))}

                  <Upload.Dragger
                    disabled={deletingCategoryIds[c.category.id]}
                    showUploadList={false}
                    multiple
                    beforeUpload={value => {
                      onUploadDownloadable(value, c.category.id)
                      return false
                    }}
                  >
                    <UploadIconContainer>
                      <CloudUploadOutlined />
                    </UploadIconContainer>
                  </Upload.Dragger>
                </div>
              </CategoryCard>
            ))}
          </DragDropContext>
        </ListContainer>
      </Page>
    </>
  )
}

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;

  .reorder-buttons {
    font-size: 22px;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;

    > * {
      transition: opacity 0.1s linear;
      cursor: pointer;
    }

    > *:hover {
      opacity: 0.5;
    }
  }
`

const UploadIconContainer = styled.p`
  color: #40a9ff;
  font-size: 48px;
`

const ListContainer = styled.div<{ disabled: boolean }>`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`

const CategoryCard = styled(Card)`
  margin-bottom: 2rem;

  .ant-card-head-title {
    overflow: visible;
  }
`

const EmptyContainer = styled.div`
  margin-top: 4rem;
`

const DownloadableIcon = styled.div`
  color: #40a9ff;
  font-size: 48px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`

export default ListDownloadables

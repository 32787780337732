import { ApolloError } from "@apollo/client"

export const isDuplicateRecordError = (err: ApolloError) => {
  return err.graphQLErrors?.[0]?.extensions?.code === "dbal-duplicate-record"
}

export const isNoUserError = (err: ApolloError) => {
  if (!err) return false
  if (!!err.networkError) return true
  if (err.graphQLErrors[0]?.extensions?.code === "no-user") return true

  return false
}

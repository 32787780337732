import { message, Upload } from "antd"
import Axios, { AxiosError } from "axios"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { CMS_URL, token } from ".."
import { LoadingOutlined, FontSizeOutlined } from "@ant-design/icons"
import { Helmet } from "react-helmet"

interface FontUploadProps {
  onChange?: (d: SimpleFont | null) => void
  value?: SimpleFont | null
}

interface SimpleFont {
  cssUrl: string
  fontFamily: string
}

const FontUpload: React.FC<FontUploadProps> = ({
  onChange = () => {},
  value = null,
}) => {
  const [loading, setLoading] = useState(false)

  const onFontUpload = async (file: File) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("file", file)

    try {
      const res = await Axios.post(`${CMS_URL}/fonts`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      onChange({
        cssUrl: res.data.css,
        fontFamily: res.data.fontFamily,
      })

      setLoading(false)
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      setLoading(false)
      const res = (err as AxiosError).response

      if (res?.status === 413) {
        message.error("Die Schriftdatei ist zu groß. (Maximal 32MB)")
        return
      }

      if (res?.status === 400 && res?.data.cause === "invalid file type") {
        message.error(
          "Die Schriftdatei hatte ein ungültiges Format. Die folgenden Formate sind erlaubt: .ttf, .woff, .woff2, .svg, .otf",
          10
        )
        return
      }

      message.error("Ein unbekannter Fehler ist aufgetreten.")
    }
  }

  return (
    <Container family={value?.fontFamily}>
      <Helmet>{value && <link rel="stylesheet" href={value.cssUrl} />}</Helmet>

      <Upload.Dragger
        style={{ width: "100%" }}
        showUploadList={false}
        disabled={loading}
        beforeUpload={(v) => {
          onFontUpload(v)
          return false
        }}
      >
        {!loading ? (
          <>
            <p className="ant-upload-drag-icon">
              <FontSizeOutlined />
            </p>
            <p className="ant-upload-text">{value?.fontFamily ?? `Standard`}</p>
          </>
        ) : (
          <>
            <p className="ant-upload-drag-icon">
              <LoadingOutlined spin />
            </p>
            <p className="ant-upload-text"></p>
          </>
        )}
      </Upload.Dragger>
    </Container>
  )
}

const Container = styled.div<{ family?: string }>`
  ${(props) =>
    props.family &&
    css`
      .ant-upload-text {
        font-family: ${props.family}, sans-serif;
      }
    `}
`

export default FontUpload

import styled from "styled-components"
import { Card } from "antd"

// TODO: Remove the ugly ant tabs hack
export const Page = styled.main`
  padding: 25px 50px;

  .ant-tabs {
    overflow: visible !important;
  }

  .ant-form-item-has-error .ant-upload.ant-upload-drag {
    border-color: rgb(255, 77, 79);
  }
`

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div.ant-page-header {
    padding-left: 0;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const ListActions = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const CardSection = styled(Card)`
  margin-bottom: 2rem;
`

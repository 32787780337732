import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { Card, Progress, Form, Input, Button } from "antd"
import { useParams, useHistory } from "react-router"
import { timeout } from "../util/util"
import { useEffectOnce } from "react-use"
import { LockOutlined } from "@ant-design/icons"
import { CMS_URL } from ".."

interface Params {
  code: string
}

interface ActivateProps {
  setToken: (token: string | null) => void
}

const Activate: React.FC<ActivateProps> = ({ setToken }) => {
  const { code } = useParams<Params>()
  const history = useHistory()

  const [choosingPassword, setChoosingPassword] = useState(false)
  const [validating, setValidating] = useState(true)
  const [activating, setActivating] = useState(false)
  const [activatingStatus, setActivatingStatus] = useState<
    "active" | "exception" | "success"
  >("active")

  useEffectOnce(() => {
    validate()
  })

  const validate = async () => {
    await timeout(500)
    try {
      await axios.post(`${CMS_URL}/auth/activation/validate`, { code })

      setChoosingPassword(true)
      setValidating(false)
    } catch {
      setActivatingStatus("exception")
      setValidating(false)
    }
  }

  const onActivate = async (password: string) => {
    setActivating(true)
    await timeout(500)
    setChoosingPassword(false)

    try {
      const res = await axios.post(`${CMS_URL}/auth/activation/activate`, {
        code,
        newPassword: password,
      })

      setActivatingStatus("success")
      await timeout(2000)

      setToken(res.data.token)
      history.push("/")
    } catch {
      setActivatingStatus("exception")
    }
  }

  return (
    <Container>
      <ContentCard>
        {validating && (
          <StatusText>
            <b>Link wird überprüft...</b>
          </StatusText>
        )}

        {!validating && (
          <>
            {choosingPassword && (
              <>
                <ChoosePasswordText>
                  Bitte wählen Sie ein Passwort, um Ihren Account zu aktivieren.
                </ChoosePasswordText>

                <Form
                  name="password"
                  onFinish={(values) => onActivate(values.password as string)}
                >
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Bitte Passwort wählen" },
                      {
                        min: 6,
                        message: "Passwort muss mindestens 6 Zeichen lang sein",
                      },
                    ]}
                  >
                    <Input.Password
                      disabled={activating}
                      placeholder="Passwort"
                      prefix={<PasswordIcon />}
                    />
                  </Form.Item>

                  <SubmitButton
                    type="primary"
                    htmlType="submit"
                    loading={activating}
                  >
                    Account aktivieren
                  </SubmitButton>
                </Form>
              </>
            )}

            {!choosingPassword && (
              <>
                {activatingStatus === "active" && (
                  <StatusText>Account wird aktiviert...</StatusText>
                )}
                {activatingStatus === "success" && (
                  <StatusText>Account erfolgreich aktiviert!</StatusText>
                )}
                {activatingStatus === "exception" && (
                  <StatusText>Ein Fehler ist aufgetreten.</StatusText>
                )}
              </>
            )}
          </>
        )}

        {!choosingPassword && (
          <Progress percent={100} status={activatingStatus} showInfo={false} />
        )}
      </ContentCard>
    </Container>
  )
}

const ChoosePasswordText = styled.p`
  width: 100%;
  margin-bottom: 1.5rem;
`

const StatusText = styled.p`
  width: 100%;
  text-align: center;
  font-weight: bolder;
`

const ContentCard = styled(Card)`
  width: 24rem;
  margin: 2rem;
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PasswordIcon = styled(LockOutlined)`
  color: rgba(0, 0, 0, 0.25);
`

const SubmitButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
`

export default Activate

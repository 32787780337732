import React, { useMemo } from "react"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { v4 as uuid } from "uuid"
import ReactTooltip from "react-tooltip"

export function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const reorder: <T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
) => Array<T> = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const ExplainIcon: React.FC<{
  text: string
  place?: "top" | "right" | "bottom" | "left"
}> = ({ text, place = "top" }) => {
  const id = useMemo(() => uuid(), [])

  return (
    <>
      <div
        data-tip
        data-for={id}
        style={{ display: "inline", margin: "0 0.2em" }}
      >
        <QuestionCircleOutlined style={{ opacity: 0.6 }} />
      </div>

      <ReactTooltip id={id} effect="solid" place={place}>
        <div style={{ maxWidth: 400 }}>{text}</div>
      </ReactTooltip>
    </>
  )
}

export const Tooltip: React.FC<{
  text: string
  place?: "top" | "right" | "bottom" | "left"
}> = ({ text, place, children }) => {
  const id = useMemo(() => uuid(), [])

  return (
    <>
      <div data-tip data-for={id}>
        {children}
      </div>

      <ReactTooltip id={id} effect="solid" place={place}>
        <div style={{ maxWidth: 400 }}>{text}</div>
      </ReactTooltip>
    </>
  )
}

import React, { useContext, useRef, useState } from "react"
import { Page, ListHeader, Actions } from "../util/page"
import { PageHeader, message, Tabs, Button, Divider } from "antd"
import { useQuery, gql, useMutation } from "@apollo/client"
import { useParams, Redirect } from "react-router"
import { ProjectContext } from "../App"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "./types/GetProjectQuery"
import { GET_PROJECT_QUERY } from "./CreateProject"
import styled, { css } from "styled-components"

import { ProjectFragments } from "./CreateProject"
import { SaveOutlined } from "@ant-design/icons"
import CodeEntryTemplate, {
  CodeState,
  Element as CodeEntryElement,
} from "@g51/hubi-components/templates/web/CodeEntry"
import ViewBasketTemplate, {
  Element as ViewBasketElement,
} from "@g51/hubi-components/templates/web/ViewBasket"
import { LIST_PROJECTS_QUERY } from "../util/queries"
import { animateScroll } from "react-scroll"
import {
  CustomElements,
  Property,
  Category,
  useEditable,
  BaseElement,
  DefaultBaseConfig,
  serializeCustomValues,
  deserializeCustomValues,
  getThemeProps,
} from "@g51/hubi-components"
import { useImageUpload } from "../components/ImageUpload"
import {
  UpdateProjectCustomValuesMutationBasket,
  UpdateProjectCustomValuesMutationBasketVariables,
} from "./types/UpdateProjectCustomValuesMutationBasket"
import { BackgroundEditor, Logos } from "./UpdateBasket"
import { showLinkModal } from "../util/forms"
import { Helmet } from "react-helmet"
import { CMS_URL } from ".."

interface Params {
  id?: string
}

const UPDATE_PROJECT_CUSTOM_VALUES_MUTATION = gql`
  mutation UpdateProjectCustomValuesMutationWebsite(
    $id: ID!
    $version: Int!
    $changes: UpdateProject!
  ) {
    updateProject(id: $id, version: $version, changes: $changes) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

const UpdateWebsite = () => {
  const params = useParams<Params>()
  const { activeProject, setActiveProject } = useContext(ProjectContext)
  const [values, setValues] = useState<CustomElements>({})

  const { data, loading: loadingProject } = useQuery<
    GetProjectQuery,
    GetProjectQueryVariables
  >(GET_PROJECT_QUERY, {
    variables: { id: params.id!! },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      message.error("Das Projekt konnte nicht gefunden werden.")
    },
    onCompleted: data => {
      if (!data) return

      setActiveProject(data.project.id)
      setValues(deserializeCustomValues(data.project.customValues))
    },
  })

  const [updateProject, { loading: updatingProject }] = useMutation<
    UpdateProjectCustomValuesMutationBasket,
    UpdateProjectCustomValuesMutationBasketVariables
  >(UPDATE_PROJECT_CUSTOM_VALUES_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    update: (store, { data }) => {
      store.writeQuery<GetProjectQuery, GetProjectQueryVariables>({
        query: GET_PROJECT_QUERY,
        data: { project: { ...data!!.updateProject } },
      })
    },
    onCompleted: () => {
      message.success("Projekt erfolgreich gespeichert.")
      animateScroll.scrollToTop({ duration: 300 })
    },
    onError: err => {
      if (err.networkError) {
        message.error("Beim Speichern ist ein Fehler aufgetreten.")
        return
      }

      message.error("Ein unbekannter Fehler ist aufgetreten.")
    },
  })

  const categories = (data?.project?.downloadables.categories ?? []).map<
    Category
  >(it => ({
    id: it.category.id,
    name: it.category.name,
    downloadables: it.downloadables.map(
      ({ id, name, description, previewImage }) => ({
        id,
        name,
        selected: false,
        description,
        previewImage,
      })
    ),
  }))

  const onSave = () => {
    if (!data?.project) return

    const toSave = serializeCustomValues(values)
    updateProject({
      variables: {
        id: data.project.id,
        version: data.project.version,
        changes: { customValues: toSave },
      },
    })
  }

  const themeEditable = useEditable({
    baseConfig: DefaultBaseConfig,
    editable: true,
    outer: values,
    setOuter: v => setValues(v),
    elements: {
      [CodeEntryElement.Container]: BaseElement.Container,
      [CodeEntryElement.Background]: BaseElement.Background,
      [ViewBasketElement.Container]: BaseElement.Container,
      [ViewBasketElement.Background]: BaseElement.Background,

      // We pull this out so we can use it to clear the image
      [CodeEntryElement.IntroImage]: BaseElement.IntroImage,
    },
  })

  const setIntroImage = (url: string | null) =>
    themeEditable.setElementProperty(
      CodeEntryElement.IntroImage,
      Property.Image,
      url
    )
  const introImageCallback = useRef<(url: string) => void>()
  const introImageUploadProps = useImageUpload(
    (url?: string) => url && introImageCallback.current?.(url)
  )

  if (activeProject && activeProject.id !== params.id)
    return <Redirect to={`/projects/${activeProject.id}/website/edit`} />

  return (
    <Page>
      <Helmet>
        {!!values["font-heading"] && (
          <link rel="stylesheet" href={values["font-heading"]?.["css-url"]} />
        )}

        {!!values["font-body"] && (
          <link rel="stylesheet" href={values["font-body"]?.["css-url"]} />
        )}
      </Helmet>

      <ListHeader>
        <PageHeader
          title={`Website für Projekt ${
            data?.project?.name ?? "..."
          } bearbeiten`}
        />

        <Actions>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={loadingProject}
            loading={updatingProject}
            onClick={() => onSave()}
          >
            Änderungen speichern
          </Button>
        </Actions>
      </ListHeader>

      <Tabs
        type="card"
        size="large"
        destroyInactiveTabPane
        style={{ marginBottom: "2rem" }}
      >
        <Tabs.TabPane tab="Code-Eingabe" key="1">
          <WebsiteContainer
            {...getThemeProps(themeEditable, "web", "code-entry")}
            isLoading={loadingProject || updatingProject}
            headingFontFamily={`${values["font-heading"]?.family ?? ""}`}
            bodyFontFamily={`${values["font-body"]?.family ?? ""}`}
            sizeBodyNormal={values["font-body"]?.["size-normal"]}
            sizeBodyBig={values["font-body"]?.["size-big"]}
            sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
            sizeHeadingBig={values["font-heading"]?.["size-big"]}
          >
            <Logos projectData={data?.project} />

            <div className="container">
              <CodeEntryTemplate
                onGetLinkValue={cb => showLinkModal(cb)}
                onCodeChanged={() => {}}
                codeState={CodeState.Idle}
                value={values}
                onChange={v => setValues(v)}
                onIntroImageChanged={(done, file) => {
                  if (!file) return
                  introImageCallback.current = done
                  introImageUploadProps.setFile(file)
                }}
                onIntroImageCleared={() => setIntroImage(null)}
                makeImageURL={s => `${CMS_URL}/images/${s}`}
                editable
              />
            </div>
          </WebsiteContainer>

          <Divider />

          <BackgroundEditor
            app="web"
            themeEditable={themeEditable}
            page="code-entry"
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Basket ansehen" key="2">
          <WebsiteContainer
            {...getThemeProps(themeEditable, "web", "view-basket")}
            isLoading={loadingProject || updatingProject}
            headingFontFamily={`${values["font-heading"]?.family ?? ""}`}
            bodyFontFamily={`${values["font-body"]?.family ?? ""}`}
            sizeBodyNormal={values["font-body"]?.["size-normal"]}
            sizeBodyBig={values["font-body"]?.["size-big"]}
            sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
            sizeHeadingBig={values["font-heading"]?.["size-big"]}
          >
            <Logos projectData={data?.project} />

            <div className="container">
              <ViewBasketTemplate
                onGetLinkValue={cb => showLinkModal(cb)}
                value={values}
                onChange={v => setValues(v)}
                editable
                categories={categories}
                makeImageURL={s => `${CMS_URL}/images/${s}`}
                fallbackIcon={data?.project.defaultDownloadableIcon ?? ""}
              />
            </div>
          </WebsiteContainer>

          <Divider />

          <BackgroundEditor
            app="web"
            themeEditable={themeEditable}
            page="view-basket"
          />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}

const WebsiteContainer = styled.div<{
  isLoading: boolean
  backgroundColor: string
  backgroundImage: string | null
  hasContainer: boolean
  hasContainerShadow: boolean
  containerBorderRadius: number
  containerBackgroundColor: string
  containerBackgroundImage: string | null
  headingFontFamily: string | null
  bodyFontFamily: string | null
  pageAlign: string | null
  sizeBodyNormal?: number
  sizeBodyBig?: number
  sizeHeadingNormal?: number
  sizeHeadingBig?: number
}>`
  * {
    font-family: ${props => props.bodyFontFamily || "sans-serif"};
  }

  h1,
  h2 {
    * {
      font-family: ${props => props.headingFontFamily || "sans-serif"};
    }
  }

  .DraftEditor-root span {
    font-size: ${props => props.sizeBodyNormal || 16}px;
  }

  .DraftEditor-root h3 span {
    font-size: ${props => props.sizeBodyBig || 22}px;
  }

  .DraftEditor-root h2 span {
    font-size: ${props => props.sizeHeadingNormal || 26}px;
  }

  .DraftEditor-root h1 span {
    font-size: ${props => props.sizeHeadingBig || 32}px;
  }

  .category {
    font-size: ${props => props.sizeBodyBig || 22}px;
  }

  .container .downloadables {
    .info {
      padding-right: 16px;
    }

    .item .meta {
      font-size: ${props => props.sizeBodyNormal || 16}px;

      .desc {
        font-size: 0.95em;
      }
    }

    a.download-link {
      flex-shrink: 0;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
  padding: 2rem;
  border: 1px solid #f0f0f0;
  border-top: none;
  transition: opacity 150ms linear;
  background-color: ${props => props.backgroundColor};

  ${props =>
    props.pageAlign === "left" &&
    css`
      align-items: flex-start;
    `}

  ${props =>
    props.pageAlign === "right" &&
    css`
      align-items: flex-end;
    `}

  ${props =>
    props.backgroundImage &&
    css`
      background-position: center center;
      background-size: cover;
      background-image: url(${CMS_URL}/images/${props.backgroundImage});
    `}

  .container {
    border: 1px solid #666;
    width: 1024px;
    height: 768px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 3rem;
    margin-top: 2rem;
    background-color: ${props => props.containerBackgroundColor};
    border-radius: ${props => props.containerBorderRadius || "0"}px;

    ${props =>
      props.hasContainerShadow &&
      css`
        box-shadow: 0px 0px 30px 16px rgba(0, 0, 0, 0.1);
      `}

    ${props =>
      props.containerBackgroundImage &&
      css`
        background-position: center center;
        background-size: cover;
        background-image: url(${CMS_URL}/images/${props.containerBackgroundImage});
      `}

    ${props =>
      !props.hasContainer &&
      css`
        border-color: transparent !important;
        background: transparent !important;
        box-shadow: none !important;
        padding: 1.5rem 0;
        margin-top: 0 !important;
      `}
  }

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`

export default UpdateWebsite

import React, { useState } from "react"
import axios, { AxiosError } from "axios"
import styled from "styled-components"
import { Form, Card, Input, Button, Checkbox, message } from "antd"
import { useHistory } from "react-router"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { APP_NAME, CMS_URL } from ".."

interface LoginForm {
  user: string
  password: string
  rememberMe: boolean
}

const initialLoginFormValues: LoginForm = {
  user: "",
  password: "",
  rememberMe: true,
}

interface LoginProps {
  setToken: (token: string | null) => void
}

const Login: React.FC<LoginProps> = ({ setToken }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: LoginForm) => {
    setLoading(true)

    const req = {
      username: values.user,
      password: values.password,
      remember: values.rememberMe,
    }

    try {
      const res = await axios.post(`${CMS_URL}/auth/token`, req)
      setToken(res.data.token)
      history.push("/")
    } catch (err) {
      const axiosErr = err as AxiosError
      setLoading(false)

      if (`${axiosErr.response?.status}`.startsWith("4")) {
        if (axiosErr.response?.data?.code === "api-account-disabled") {
          message.error(
            "Ihr Account ist deaktiviert. Bitte wenden Sie sich an einen Administrator."
          )
          return
        }

        message.error("E-Mail oder Passwort war falsch.")
        return
      }

      message.error(
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
      )
    }
  }

  return (
    <Container>
      <LoginCard title={`${APP_NAME} HUB-i CMS Login`}>
        <Form
          name="login"
          initialValues={initialLoginFormValues}
          onFinish={(values) => onFinish(values as LoginForm)}
        >
          <Form.Item
            name="user"
            rules={[
              { required: true, message: "Bitte Email eingeben" },
              { type: "email", message: "Keine gültige E-Mail Addresse" },
            ]}
          >
            <Input
              placeholder="E-Mail"
              disabled={loading}
              prefix={<UserIcon />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Bitte Passwort eingeben" }]}
          >
            <Input.Password
              disabled={loading}
              placeholder="Passwort"
              prefix={<PasswordIcon />}
            />
          </Form.Item>

          <Form.Item>
            <Form.Item name="rememberMe" valuePropName="checked" noStyle>
              <Checkbox disabled={loading}>Eingeloggt bleiben</Checkbox>
            </Form.Item>

            <ForgotLink href="/request-password-reset">
              Passwort vergessen?
            </ForgotLink>
          </Form.Item>

          <SubmitButton type="primary" htmlType="submit" loading={loading}>
            Log in
          </SubmitButton>
        </Form>
      </LoginCard>
    </Container>
  )
}

const SubmitButton = styled(Button)`
  width: 100%;
`

const ForgotLink = styled.a`
  float: right;
`

const PasswordIcon = styled(LockOutlined)`
  color: rgba(0, 0, 0, 0.25);
`

const UserIcon = styled(UserOutlined)`
  color: rgba(0, 0, 0, 0.25);
`

const LoginCard = styled(Card)`
  width: 24rem;
  margin: 2rem;
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Login

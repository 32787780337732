import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { ListContentPagesQuery_ipointMainpages_items } from "../projects/types/ListContentPagesQuery"
import {
  FilePdfOutlined,
  LoadingOutlined,
  VideoCameraAddOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import { Button, Input, message, Select, Upload } from "antd"
import { ListDownloadablesQuery_downloadables_categories } from "../downloadables/types/ListDownloadablesQuery"
import { RcFile } from "antd/lib/upload"
import Axios from "axios"
import { CMS_URL, token } from ".."
import {
  formatPDFData,
  parsePDFData,
} from "../projects/UpdateIPointContentpage"
import { DragHandle } from "./DownloadableItem"

const ContentPageItem: React.FC<{
  dragHandle?: any
  item: Omit<ListContentPagesQuery_ipointMainpages_items, "__typename">
  downloadables: ListDownloadablesQuery_downloadables_categories[]
  onDelete: () => void
  onChange: (
    p: Omit<ListContentPagesQuery_ipointMainpages_items, "__typename">
  ) => void
}> = ({ dragHandle, item, downloadables, onChange, onDelete }) => {
  const [uploadingVideo, setUploadingVideo] = useState(false)
  const onUploadVideo = async (f: RcFile) => {
    setUploadingVideo(true)

    const formData = new FormData()
    formData.append("file", f)

    try {
      const res = await Axios.post(`${CMS_URL}/files/downloadable`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setUploadingVideo(false)
      onChange({
        ...item,
        videos: [
          {
            file: formatPDFData(f.name, res.data.fileId),
            page: 0,
          } as any,
          ...item.videos,
        ],
      })
    } catch {
      setUploadingVideo(false)
      message.error(
        "Beim Upload ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
      )
    }
  }

  const videoValues = useMemo(
    () =>
      item.videos.map(vid => {
        const { name, fileId } = parsePDFData(vid.file)
        return {
          name,
          fileId,
          page: vid.page,
        }
      }),
    [item.videos]
  )

  const urlValues = useMemo(
    () => item.urls.map(({ url, page }) => ({ url, page })),
    [item.urls]
  )

  const { name } = useMemo(() => parsePDFData(item.document), [item.document])

  const [uploading, setUploading] = useState(false)
  const onReupload = async (f: RcFile) => {
    setUploading(true)

    const formData = new FormData()
    formData.append("file", f)

    try {
      const res = await Axios.post(`${CMS_URL}/files/downloadable`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      onChange({
        ...item,
        document: formatPDFData(f.name, res.data.fileId),
      })

      setUploading(false)
    } catch {
      setUploading(false)
      message.error(
        "Beim Upload ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
      )
    }
  }

  return (
    <Container>
      <div {...dragHandle}>
        <DragHandle
          style={{
            borderRight: "none",
            position: "absolute",
            top: 6,
            left: 6,
            width: "auto",
            minHeight: 0,
          }}
        />
      </div>

      <p className="icon">
        <Upload.Dragger
          showUploadList={false}
          multiple
          className="reupload"
          beforeUpload={f => {
            onReupload(f)
            return false
          }}
        >
          {uploading && (
            <p className="ant-upload-drag-icon">
              <LoadingOutlined spin />
            </p>
          )}

          {!uploading && (
            <>
              <p className="ant-upload-drag-icon">
                <FilePdfOutlined />
              </p>
            </>
          )}
        </Upload.Dragger>
      </p>

      <span className="label">Titel</span>
      <Input
        type="text"
        value={item.title}
        onChange={e => {
          onChange({
            ...item,
            title: e.target.value,
          })
        }}
      />

      <span className="label">Dokument</span>
      <Input type="text" value={name || ""} disabled />

      <span className="label">Downloadables</span>
      <Select
        style={{ width: "100%" }}
        mode="multiple"
        value={item.downloadables}
        onChange={e => {
          onChange({
            ...item,
            downloadables: e as string[],
          })
        }}
      >
        {downloadables.map((cat, idx) => (
          <Select.OptGroup label={cat.category.name} key={idx}>
            {cat.downloadables.map(dl => (
              <Select.Option key={dl.id} value={dl.id}>
                {dl.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>

      <span className="label">Browser</span>
      <Button
        className="delete-button"
        icon={<PlusOutlined />}
        onClick={() => {
          onChange({
            ...item,
            urls: [...item.urls, { url: "", page: null } as any],
          })
        }}
      >
        Hinzufügen
      </Button>
      <div className="uploaded-videos">
        {urlValues.map((brow, idx) => (
          <div key={idx}>
            <span>URL: </span>
            <Input
              type="text"
              placeholder="URL"
              value={brow.url}
              onChange={e =>
                onChange({
                  ...item,
                  urls: item.urls.map((it, toChange) =>
                    idx === toChange
                      ? ({
                          url: e.target.value,
                          page: it.page,
                        } as any)
                      : it
                  ),
                })
              }
            />

            <div className="uploaded-inputs">
              <span>Page: </span>
              <Input
                type="number"
                placeholder="Seite"
                value={brow.page}
                onChange={e =>
                  onChange({
                    ...item,
                    urls: item.urls.map((it, toChange) =>
                      idx === toChange
                        ? ({
                            url: it.url,
                            page: e.target.valueAsNumber,
                          } as any)
                        : it
                    ),
                  })
                }
              />
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => {
                  onChange({
                    ...item,
                    urls: item.urls.filter((_, toDelete) => idx !== toDelete),
                  })
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <span className="label">Videos</span>
      <Upload.Dragger
        style={{ width: "100%" }}
        showUploadList={false}
        multiple
        beforeUpload={f => {
          onUploadVideo(f)
          return false
        }}
      >
        {uploadingVideo && <LoadingOutlined spin />}

        {!uploadingVideo && (
          <p className="video-upload">
            <VideoCameraAddOutlined /> <span>Video</span>
          </p>
        )}
      </Upload.Dragger>

      <div className="uploaded-videos">
        {videoValues.map((vid, idx) => (
          <div key={idx}>
            <span>{vid.name}</span>

            <div className="uploaded-inputs">
              <span>Page: </span>
              <Input
                type="number"
                placeholder="Seite"
                value={vid.page}
                onChange={e =>
                  onChange({
                    ...item,
                    videos: item.videos.map((it, toChange) =>
                      idx === toChange
                        ? ({
                            file: it.file,
                            page: e.target.valueAsNumber,
                          } as any)
                        : it
                    ),
                  })
                }
              />
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => {
                  onChange({
                    ...item,
                    videos: item.videos.filter(
                      (_, toDelete) => idx !== toDelete
                    ),
                  })
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <Button
        className="delete-button"
        icon={<DeleteOutlined />}
        danger
        onClick={() => onDelete()}
      >
        Löschen
      </Button>
    </Container>
  )
}

const Container = styled.div`
  width: 240px;
  height: auto;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 2px;
  padding: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .delete-button {
    margin-top: 10px;
  }

  > * {
    width: 100%;
  }

  .label {
    width: 100%;
    padding-left: 4px;
    margin-bottom: 2px;
    margin-top: 16px;
  }

  .icon {
    color: #40a9ff;
    font-size: 48px;
    line-height: 1;
    margin-bottom: 0;
    width: unset;

    .reupload {
      width: 120px;
      height: 70px;
    }
  }

  .ant-upload.ant-upload-btn {
    padding: 8px 0;
  }

  .video-upload {
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      color: #40a9ff;
      font-size: 20px;
      margin-right: 6px;
    }
  }

  .uploaded-videos > div {
    margin-top: 8px;
    width: 100%;
    border: 1px solid #f5f5f5;
    border-radius: 2px;
    background: #f5f5f5;
    padding: 4px 8px;

    .uploaded-inputs {
      display: flex;
      align-items: center;

      > span {
        margin-right: 8px;
      }

      > button {
        margin-left: 8px;
      }
    }

    > span {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      width: 100%;
    }

    .ant-input {
      flex: 20px;
    }
  }
`

export default ContentPageItem

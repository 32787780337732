import React, { useState } from "react"
import { FolderAddOutlined } from "@ant-design/icons"
import { Page, ListHeader, Actions, CardSection } from "../util/page"
import {
  PageHeader,
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Select,
  Divider,
  Radio,
  Switch,
} from "antd"
import { Link } from "react-router-dom"
import { showLinkModal } from "../util/forms"
import { gql, useQuery, useMutation, ApolloError } from "@apollo/client"
import { GetInstanceQuery } from "./types/GetInstanceQuery"
import {
  UpdateInstanceMutation,
  UpdateInstanceMutationVariables,
} from "./types/UpdateInstanceMutation"
import RichTextEditor, { EditorValue } from "@happens-lol/react-rte"
import { ListMailOptionsQuery } from "./types/ListMailOptionsQuery"
import { animateScroll } from "react-scroll"
import { useForm } from "antd/lib/form/Form"
import Markdown from "@g51/hubi-components/components/Markdown"
import styled from "styled-components"
import ImageUpload from "../components/ImageUpload"
import { ExplainIcon, Tooltip } from "../util/util"
import { BackgroundColorElement } from "../projects/UpdateBasket"
import { CustomColorSelect } from "@g51/hubi-components/components/EditableBar"
import { FontData, FontUploader } from "../projects/CreateProject"
import { Helmet } from "react-helmet"

const InstanceFragments = {
  InstanceFields: gql`
    fragment InstanceFields on Instance {
      clientName
      logo
      customValues
      mailSenderEmail
      mailSenderName
      mailTemplates {
        activationTemplateId
        activationSubject

        activationSuccessTemplateId
        activationSuccessSubject

        passwordResetTemplateId
        passwordResetSubject

        passwordResetSuccessTemplateId
        passwordResetSuccessSubject

        basketCheckoutTemplateId
        basketCheckoutSubject
      }
      strings {
        imprint
        dataPrivacyNotice
        dark
      }
      updated
      version
    }
  `,
}

const GET_INSTANCE_QUERY = gql`
  query GetInstanceQuery {
    instance {
      ...InstanceFields
    }
  }
  ${InstanceFragments.InstanceFields}
`

const UPDATE_INSTANCE_MUTATION = gql`
  mutation UpdateInstanceMutation($version: Int!, $changes: UpdateInstance!) {
    updateInstance(version: $version, changes: $changes) {
      ...InstanceFields
    }
  }
  ${InstanceFragments.InstanceFields}
`

export const LIST_MAIL_OPTIONS_QUERY = gql`
  query ListMailOptionsQuery {
    mailSenders
    mailTemplates {
      name
      key
    }
  }
`

interface InstanceForm {
  clientName: string
  strings: {
    imprint: EditorValue
    dataPrivacyNotice: EditorValue
    dark: boolean
  }
  mailSenderEmail: string
  mailSenderName: string
  mailTemplates: {
    activationTemplateId: string
    activationSubject: string

    activationSuccessTemplateId: string
    activationSuccessSubject: string

    passwordResetTemplateId: string
    passwordResetSubject: string

    passwordResetSuccessTemplateId: string
    passwordResetSuccessSubject: string

    basketCheckoutTemplateId: string
    basketCheckoutSubject: string
  }
  customValues: {
    landingPage: {
      text: EditorValue
      backgroundImage: string
      logo: string
    }
    imprintLink: string
    privacyLink: string
  }
}

const UpdateInstance = () => {
  const [form] = useForm()

  const [updateInstance, { loading: updatingInstance }] = useMutation<
    UpdateInstanceMutation,
    UpdateInstanceMutationVariables
  >(UPDATE_INSTANCE_MUTATION, {
    notifyOnNetworkStatusChange: true,
    update: (store, { data }) => {
      store.writeQuery<GetInstanceQuery>({
        query: GET_INSTANCE_QUERY,
        data: { instance: { ...data!!.updateInstance } },
      })
    },
  })

  const { data: instanceData, loading: loadingInstance } = useQuery<
    GetInstanceQuery
  >(GET_INSTANCE_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const strings = {
        imprint: RichTextEditor.createValueFromString(
          data.instance.strings.imprint,
          "markdown"
        ),
        dataPrivacyNotice: RichTextEditor.createValueFromString(
          data.instance.strings.dataPrivacyNotice,
          "markdown"
        ),
        dark: data.instance.strings.dark,
      }

      const introText = RichTextEditor.createValueFromString(
        data.instance.customValues.landingPage?.text ?? "",
        "markdown"
      )

      const { customValues: cv } = data.instance

      const colors = {
        backgroundColor: cv.landingPage?.backgroundColor ?? "#FFF",
        textColor: cv.landingPage?.textColor ?? "#000",
        linkBoxColor: cv.landingPage?.linkBoxColor ?? "#DDD",
        linkTitleColor: cv.landingPage?.linkTitleColor ?? "#000",
        linkDateColor: cv.landingPage?.linkDateColor ?? "#555",
        buttonBackgroundColor: cv.landingPage?.buttonBackgroundColor ?? "#555",
        buttonTextColor: cv.landingPage?.buttonTextColor ?? "#FFF",
      }

      const measurements = {
        cardCornerRadius: cv.landingPage?.cardCornerRadius ?? 0,
        cardWidth: cv.landingPage?.cardWidth ?? 250,
        cardHeight: cv.landingPage?.cardHeight ?? 120,
        cardPadding: cv.landingPage?.cardPadding ?? 16,
      }

      if (data.instance.customValues.landingPage?.bodyFontData)
        setBodyFontData(data.instance.customValues.landingPage.bodyFontData)

      if (data.instance.customValues.landingPage?.headingFontData)
        setHeadingFontData(
          data.instance.customValues.landingPage.headingFontData
        )

      setLandingPageColors(colors)
      setLandingPageValues(measurements)

      form.setFieldsValue({
        ...data.instance,
        strings,
        customValues: {
          ...data.instance.customValues,
          landingPage: {
            ...(data.instance.customValues.landingPage ?? {}),
            text: introText,
          },
        },
      })
    },
  })

  const { data: mailOptionsData, loading: loadingMailOptions } = useQuery<
    ListMailOptionsQuery
  >(LIST_MAIL_OPTIONS_QUERY)

  const disableFormFields = loadingInstance || updatingInstance

  const onSaveError = (err: ApolloError) => {
    if (err.networkError) {
      message.error("Beim Speichern ist ein Fehler aufgetreten.")
      return
    }

    message.error("Ein unbekannter Fehler ist aufgetreten.")
  }

  const onFinish = async (values: InstanceForm) => {
    const strings = {
      imprint: values.strings.imprint.toString("markdown"),
      dataPrivacyNotice: values.strings.dataPrivacyNotice.toString("markdown"),
      dark: values.strings.dark,
    }

    const variables: UpdateInstanceMutationVariables = {
      version: instanceData?.instance?.version ?? 0,
      changes: {
        ...values,
        strings,
        customValues: {
          ...(values.customValues ?? {}),
          landingPage: {
            ...values.customValues.landingPage,
            text: values.customValues.landingPage?.text.toString("markdown"),
            backgroundColor: landingPageColors.backgroundColor,
            textColor: landingPageColors.textColor,
            linkBoxColor: landingPageColors.linkBoxColor,
            linkTitleColor: landingPageColors.linkTitleColor,
            linkDateColor: landingPageColors.linkDateColor,
            buttonBackgroundColor: landingPageColors.buttonBackgroundColor,
            buttonTextColor: landingPageColors.buttonTextColor,

            cardCornerRadius: landingPageValues.cardCornerRadius,
            cardWidth: landingPageValues.cardWidth,
            cardHeight: landingPageValues.cardHeight,
            cardPadding: landingPageValues.cardPadding,

            bodyFontData,
            headingFontData,
          },
        },
      },
    }

    try {
      await updateInstance({ variables })
      message.success("Instanz erfolgreich gespeichert.")
      animateScroll.scrollToTop({ duration: 300 })
    } catch (err) {
      onSaveError(err as ApolloError)
    }
  }

  const [headingFontData, setHeadingFontData] = useState<FontData | null>(null)
  const [bodyFontData, setBodyFontData] = useState<FontData | null>(null)
  const [landingPageColors, setLandingPageColors] = useState({
    backgroundColor: "#FFF",
    textColor: "#000",
    linkBoxColor: "#DDD",
    linkTitleColor: "#000",
    linkDateColor: "#555",
    buttonBackgroundColor: "#555",
    buttonTextColor: "#FFF",
  })

  const [landingPageValues, setLandingPageValues] = useState({
    cardCornerRadius: 0,
    cardWidth: 250,
    cardHeight: 120,
    cardPadding: 16,
  })

  const onFinishFailed = () => {
    message.error("Bitte füllen Sie alle benötigten Felder aus.")
  }

  return (
    <Page>
      <ListHeader>
        <PageHeader title="Instanz bearbeiten" />

        <Actions>
          <Link to="/projects/create">
            <Button type="primary" icon={<FolderAddOutlined />}>
              Neues Projekt
            </Button>
          </Link>
        </Actions>
      </ListHeader>

      <Form
        name="general"
        layout="vertical"
        form={form}
        onFinish={values => onFinish(values as InstanceForm)}
        onFinishFailed={() => onFinishFailed()}
      >
        <CardSection>
          <Form.Item
            label="Kunde"
            name="clientName"
            rules={[{ required: true, message: "Bitte Kunde eingeben." }]}
          >
            <Input placeholder="Kunde" disabled={disableFormFields} />
          </Form.Item>
        </CardSection>

        <MarkdownSection title="Landing Page">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Landing Page Text"
                name={["customValues", "landingPage", "text"]}
              >
                <Markdown
                  className="markdown"
                  isEditing
                  editable
                  hideAlignOption
                  hideEditableBar
                  onGetLinkValue={cb => showLinkModal(cb)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <div style={{ marginBottom: "1rem" }}>
                Logo{" "}
                <ExplainIcon text="Das hier hochgeladene Logo wird für die Landing Page verwendet. Die optimale Auflösung ist ca. 350x100, Abweichungen sind aber möglich. Erlaubte Formate sind PNG und JPG, maximale Größe ist 600x600." />
              </div>

              <Form.Item
                className="image-form-item"
                name={["customValues", "landingPage", "logo"]}
                label="Logo"
                noStyle
              >
                <ImageUpload
                  text="Logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Helmet>
            {!!headingFontData && (
              <link rel="stylesheet" href={headingFontData?.cssUrl} />
            )}

            {!!bodyFontData && (
              <link rel="stylesheet" href={bodyFontData?.cssUrl} />
            )}
          </Helmet>

          <Row gutter={16}>
            <Col span={12}>
              <FontUploader
                title="Überschriften"
                defaultBig={32}
                defaultNormal={26}
                fontData={headingFontData}
                onChange={d => setHeadingFontData(d)}
                explainText="Diese Schriftart wird für alle Überschriften der Landing Page verwendet (Überschrift in Text, Kongressnamen). Wird keine Schriftart angegeben, wird die Standard-Schriftart des Browsers verwendet."
              />

              <FontUploader
                title="Texte"
                defaultBig={32}
                defaultNormal={26}
                fontData={bodyFontData}
                onChange={d => setBodyFontData(d)}
                explainText="Diese Schriftart wird für alle Texte der Landing Page verwendet (Normale Schrift in Text, Kongresszeitraum). Wird keine Schriftart angegeben, wird die Standard-Schriftart des Browsers verwendet."
              />
            </Col>

            <Col span={12}>
              <div>
                Hintergrundfarbe{" "}
                <ExplainIcon text="Diese Farbe wird als Hintergrundfarbe für die Landing Page genutzt. Wird ein Hintergrundbild ausgewählt, liegt es über dieser Farbe." />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.backgroundColor}
                style={{ height: 260 }}
              >
                <CustomColorSelect
                  defaultColor="#FFF"
                  color={landingPageColors.backgroundColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      backgroundColor: c || "#FFF",
                    }))
                  }
                />
              </BackgroundColorElement>

              <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Tooltip text="Dieses Bild wird als Hintergrund verwendet, und so skaliert dass es bei jeder Auflösung den kompletten Hintergrund bedeckt. Die optimale Auflösung ist hier 1920x1080. JPG und PNG ist erlaubt.">
                  <Form.Item
                    className="image-form-item"
                    name={["customValues", "landingPage", "backgroundImage"]}
                    label="Logo"
                    noStyle
                  >
                    <ImageUpload text="Hintergrund" style={{ width: "100%" }} />
                  </Form.Item>
                </Tooltip>
              </div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "6rem" }}>
            <Col span={4}>
              <div>
                Farbe Text{" "}
                <ExplainIcon text="Textfarbe für Begrüßungstext auf Auswahlseite" />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.textColor}
                style={{ height: 220 }}
              >
                <CustomColorSelect
                  defaultColor="#000"
                  color={landingPageColors.textColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      textColor: c || "#000",
                    }))
                  }
                />
              </BackgroundColorElement>
            </Col>

            <Col span={4}>
              <div>
                Farbe Auswahl-Karte{" "}
                <ExplainIcon text="Hintergrundfarbe für Karten, die zur Wahl eines Kongresses benutzt werden können" />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.linkBoxColor}
                style={{ height: 220 }}
              >
                <CustomColorSelect
                  defaultColor="#DDD"
                  color={landingPageColors.linkBoxColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      linkBoxColor: c || "#DDD",
                    }))
                  }
                />
              </BackgroundColorElement>
            </Col>

            <Col span={4}>
              <div>
                Farbe Auswahl-Titel{" "}
                <ExplainIcon text="Textfarbe für Titel des Kongresses in der Auswahl-Karte" />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.linkTitleColor}
                style={{ height: 220 }}
              >
                <CustomColorSelect
                  defaultColor="#000"
                  color={landingPageColors.linkTitleColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      linkTitleColor: c || "#000",
                    }))
                  }
                />
              </BackgroundColorElement>
            </Col>

            <Col span={4}>
              <div>
                Farbe Kongress-Zeitspanne{" "}
                <ExplainIcon text="Textfarbe für die Zeitspanne in der Auswahl-Karte" />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.linkDateColor}
                style={{ height: 220 }}
              >
                <CustomColorSelect
                  defaultColor="#555"
                  color={landingPageColors.linkDateColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      linkDateColor: c || "#555",
                    }))
                  }
                />
              </BackgroundColorElement>
            </Col>

            <Col span={4}>
              <div>
                Farbe Auswahl-Button Hintergrund{" "}
                <ExplainIcon text="Hintergrundfarbe für den Button zur Auswahl des Kongresses" />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.buttonBackgroundColor}
                style={{ height: 220 }}
              >
                <CustomColorSelect
                  defaultColor="#555"
                  color={landingPageColors.buttonBackgroundColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      buttonBackgroundColor: c || "#555",
                    }))
                  }
                />
              </BackgroundColorElement>
            </Col>

            <Col span={4}>
              <div>
                Farbe Auswahl-Button Pfeil{" "}
                <ExplainIcon text="Farbe für den Pfeil im Button zur Auswahl des Kongresses" />
              </div>
              <BackgroundColorElement
                bgColor={landingPageColors.buttonTextColor}
                style={{ height: 220 }}
              >
                <CustomColorSelect
                  defaultColor="#FFF"
                  color={landingPageColors.buttonTextColor}
                  onChange={c =>
                    setLandingPageColors(colors => ({
                      ...colors,
                      buttonTextColor: c || "#FFF",
                    }))
                  }
                />
              </BackgroundColorElement>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "4rem" }}>
            <Col span={6}>
              Gerundete Ecken für Auswahl-Karten{" "}
              <ExplainIcon text="Die Ecken für jeden Auswahl-Link lassen sich abrunden, indem hier in Pixeln der Radius eingegeben wird. Bei 0 sind die Ecken rechtwinklig." />
              <Input
                style={{ width: "100%" }}
                type="number"
                placeholder="Rundung in Pixel"
                value={landingPageValues.cardCornerRadius}
                onChange={e => {
                  const v = e.target.valueAsNumber
                  setLandingPageValues(vs => ({
                    ...vs,
                    cardCornerRadius: v || 0,
                  }))
                }}
              />
            </Col>

            <Col span={6}>
              Breite Auswahl-Karten{" "}
              <ExplainIcon text="Breite in Pixel für jede Auswahl-Karte" />
              <Input
                style={{ width: "100%" }}
                type="number"
                placeholder="Breite in Pixel"
                value={landingPageValues.cardWidth}
                onChange={e => {
                  const v = e.target.valueAsNumber
                  setLandingPageValues(vs => ({
                    ...vs,
                    cardWidth: v || 250,
                  }))
                }}
              />
            </Col>

            <Col span={6}>
              Höhe Auswahl-Karten{" "}
              <ExplainIcon text="Höhe in Pixel für jede Auswahl-Karte" />
              <Input
                style={{ width: "100%" }}
                type="number"
                placeholder="Höhe in Pixel"
                value={landingPageValues.cardHeight}
                onChange={e => {
                  const v = e.target.valueAsNumber
                  setLandingPageValues(vs => ({
                    ...vs,
                    cardHeight: v || 120,
                  }))
                }}
              />
            </Col>

            <Col span={6}>
              Innenabstand Auswahl-Karten{" "}
              <ExplainIcon text="Abstand des Texts zu Innenkante jeder Auswahl-Karte" />
              <Input
                style={{ width: "100%" }}
                type="number"
                placeholder="Abstand in Pixel"
                value={landingPageValues.cardPadding}
                onChange={e => {
                  const v = e.target.valueAsNumber
                  setLandingPageValues(vs => ({
                    ...vs,
                    cardPadding: v || 16,
                  }))
                }}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name={["customValues", "landingPage", "verticalAlign"]}
                label={
                  <div>
                    Vertikale Ausrichtung{" "}
                    <ExplainIcon text="Die gesamte Seite kann entweder am oberen Seitenrand oder zentral ausgerichtet werden. In beiden Fällen erscheint eine Scroll-Leiste, wenn zu viel Inhalt für die Seitenhöhe vorhanden ist." />
                  </div>
                }
                style={{ marginTop: "1rem", width: "100%" }}
              >
                <Radio.Group disabled={disableFormFields}>
                  <Radio.Button value="top">Oben</Radio.Button>
                  <Radio.Button value="center">Zentriert</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={["customValues", "landingPage", "alignLogo"]}
                label={
                  <div>
                    Horizontale Ausrichtung Logo{" "}
                    <ExplainIcon text="Diese Option bestimmt die horizontale Ausrichtung des hochgeladenen Logos." />
                  </div>
                }
                style={{ marginTop: "1rem", width: "100%" }}
              >
                <Radio.Group disabled={disableFormFields}>
                  <Radio.Button value="left">Links</Radio.Button>
                  <Radio.Button value="center">Mitte</Radio.Button>
                  <Radio.Button value="right">Rechts</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={["customValues", "landingPage", "alignText"]}
                label={
                  <div>
                    Horizontale Ausrichtung Text{" "}
                    <ExplainIcon text="Diese Option bestimmt die horizontale Ausrichtung des Texts zwischen Logo und Auswahl-Karten." />
                  </div>
                }
                style={{ marginTop: "1rem", width: "100%" }}
              >
                <Radio.Group disabled={disableFormFields}>
                  <Radio.Button value="left">Links</Radio.Button>
                  <Radio.Button value="center">Mitte</Radio.Button>
                  <Radio.Button value="right">Rechts</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name={["customValues", "landingPage", "alignCards"]}
                label={
                  <div>
                    Horizontale Ausrichtung Auswahl-Karten{" "}
                    <ExplainIcon text="Diese Option bestimmt die horizontale Ausrichtung der Auswahl-Karten. Diese Ausrichtung wird auch fortgesetzt falls die Karten auf mehrere Zeilen verteilt sind." />
                  </div>
                }
                style={{ marginTop: "1rem", width: "100%" }}
              >
                <Radio.Group disabled={disableFormFields}>
                  <Radio.Button value="left">Links</Radio.Button>
                  <Radio.Button value="center">Mitte</Radio.Button>
                  <Radio.Button value="right">Rechts</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </MarkdownSection>

        <CardSection title="E-Mails">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="mailSenderEmail"
                label="Absender für E-Mails (Adresse)"
                rules={[
                  { type: "email", message: "Keine gültige E-Mail Addresse" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Absender"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailSenders?.map(s => (
                    <Select.Option value={s} key={s}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="mailSenderName"
                label="Absender für E-Mails (Name)"
                rules={[
                  {
                    min: 3,
                    message: "Name muss mindestens 3 Zeichen lang sein",
                  },
                  {
                    required: true,
                    message: "Dieser Wert wird benötigt.",
                  },
                ]}
              >
                <Input placeholder="Absender" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Templates und Betreffe</Divider>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "activationTemplateId"]}
                label="Account-Aktivierung E-Mail Template"
              >
                <Select
                  showSearch
                  placeholder="Account-Aktivierung E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "activationSubject"]}
                label="Account-Aktivierung E-Mail Betreff"
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "activationSuccessTemplateId"]}
                label="Account-Aktivierung Erfolg E-Mail Template"
              >
                <Select
                  showSearch
                  placeholder="Account-Aktivierung Erfolg E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "activationSuccessSubject"]}
                label="Account-Aktivierung Erfolg E-Mail Betreff"
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "passwordResetTemplateId"]}
                label="Password zurücksetzen E-Mail Template"
              >
                <Select
                  showSearch
                  placeholder="Password zurücksetzen E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "passwordResetSubject"]}
                label="Passwort zurücksetzen E-Mail Betreff"
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "passwordResetSuccessTemplateId"]}
                label="Passwort zurückgesetzt E-Mail Template"
              >
                <Select
                  showSearch
                  placeholder="Passwort zurückgesetzt E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "passwordResetSuccessSubject"]}
                label="Passwort zurückgesetzt E-Mail Betreff"
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "basketCheckoutTemplateId"]}
                label="Basket Checkout E-Mail Template (Fallback)"
              >
                <Select
                  showSearch
                  placeholder="Basket Checkout E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "basketCheckoutSubject"]}
                label="Basket Checkout E-Mail Betreff"
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>
        </CardSection>

        <MarkdownSection title="Texte">
          <Form.Item
            label={
              <div>
                Dunkler Hintergrund{" "}
                <ExplainIcon text="Durch Aktivieren dieser Option wird die Textfarbe der Impressum- und Datenschutzlinks auf weiß gestellt." />
              </div>
            }
            name={["strings", "dark"]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Text Impressum-Link"
            name={["customValues", "imprintLink"]}
          >
            <Input
              style={{ width: "100%" }}
              type="text"
              placeholder="Impressum"
            />
          </Form.Item>

          <Form.Item label="Impressum" name={["strings", "imprint"]}>
            <Markdown
              className="markdown"
              isEditing
              editable
              hideAlignOption
              hideEditableBar
              onGetLinkValue={cb => showLinkModal(cb)}
            />
          </Form.Item>

          <Form.Item
            label="Text Datenschutz-Link"
            name={["customValues", "privacyLink"]}
          >
            <Input
              style={{ width: "100%" }}
              type="text"
              placeholder="Datenschutz"
            />
          </Form.Item>

          <Form.Item
            label="Datenschutz"
            name={["strings", "dataPrivacyNotice"]}
          >
            <Markdown
              className="markdown"
              isEditing
              editable
              hideAlignOption
              hideEditableBar
              onGetLinkValue={cb => showLinkModal(cb)}
            />
          </Form.Item>
        </MarkdownSection>

        <Button
          type="primary"
          htmlType="submit"
          disabled={false}
          loading={false}
        >
          Speichern
        </Button>
      </Form>
    </Page>
  )
}

const MarkdownSection = styled(CardSection)`
  .markdown {
    margin-top: 50px;

    .public-DraftEditor-content {
      cursor: text;
      border: 1px solid #ccc;
      max-height: 400px;
      overflow: auto;

      &:hover {
        border: 1px solid #40a9ff;
      }

      &:active {
        border: 1px solid #40a9ff;
      }
    }
  }
`

export default UpdateInstance

import React, { useState } from "react"
import styled from "styled-components"
import { Input, Modal } from "antd"
import { LinkOutlined } from "@ant-design/icons"

export const showLinkModal = (cb: (s: string) => void) => {
  let linkValue: string

  Modal.confirm({
    icon: <LinkOutlined />,
    title: "Link erstellen",
    content: (
      <Input
        style={{ width: "100%", marginTop: "2rem" }}
        onChange={(e) => {
          linkValue = e.target.value
        }}
        placeholder="URL eingeben..."
      />
    ),
    okText: "URL setzen",
    cancelText: "Abbrechen",
    onOk: () => cb(linkValue),
  })
}

export const BorderRadiusInput: React.FC<{
  value?: number
  onChange?: (v: number) => void
  placeholder?: string
  disabled?: boolean
}> = ({ value = "", onChange, placeholder, disabled = false }) => {
  const [innerValue, setInnerValue] = useState(0)
  const _onChange = (v: number) => {
    setInnerValue(v)
    onChange && onChange(v)
  }

  return (
    <BorderRadiusInputContainer>
      <Input
        type="number"
        disabled={disabled}
        value={value || innerValue}
        onChange={(ev) => _onChange(ev.target.valueAsNumber)}
        placeholder={placeholder}
      />

      <BorderRadiusBox radius={`${value || innerValue}px`} />
    </BorderRadiusInputContainer>
  )
}

const BorderRadiusInputContainer = styled.div`
  display: flex;
  align-items: center;
`

const BorderRadiusBox = styled.div<{ radius: string }>`
  overflow: hidden;
  border-radius: ${(props) => props.radius};
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  width: 32px;
  height: 32px;
  margin-left: 0.5rem;
  flex-shrink: 0;
`

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  from,
} from "@apollo/client"
import "dayjs/locale/de"

import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { getMainDefinition } from "@apollo/client/utilities"
import omitDeep from "omit-deep-lodash"

dayjs.locale("de")
dayjs.extend(relativeTime)

export let CMS_URL = "https://cms.hubi.staging.g51.dev"
export let APP_NAME = "Staging"
export let KIOSK_URL = "https://kiosk.hubi.staging.g51.dev"

const INSTANCE_CMS_CONFIG = {
  staging: {
    hostnames: ["localhost", "admin.hubi.staging.g51.dev"],
    cmsUrl: process.env.REACT_APP_CMS_URL as string,
    kioskUrl: process.env.REACT_APP_KIOSK_URL as string,
    name: "Staging",
  },
  expopartner: {
    hostnames: ["admin.expopartner.g51.dev"],
    cmsUrl: "https://cms.expopartner.g51.dev",
    kioskUrl: "https://kiosk.expopartner.g51.dev",
    name: "Expopartner",
  },
  expopartner2: {
    hostnames: ["admin.expopartner2.g51.dev"],
    cmsUrl: "https://cms.expopartner2.g51.dev",
    kioskUrl: "https://kiosk.expopartner2.g51.dev",
    name: "Expopartner 2",
  },
  pfizer: {
    hostnames: ["admin.pfizer.g51.dev"],
    cmsUrl: "https://cms.pfizer.g51.dev",
    kioskUrl: "https://kiosk.pfizer.g51.dev",
    name: "Pfizer",
  },
  merck: {
    hostnames: ["admin.merck.g51.dev"],
    cmsUrl: "https://cms.merck.g51.dev",
    kioskUrl: "https://kiosk.merck.g51.dev",
    name: "Merck",
  },
  sanofi: {
    hostnames: ["admin.sanofi.g51.dev"],
    cmsUrl: "https://cms.sanofi.g51.dev",
    kioskUrl: "https://kiosk.sanofi.g51.dev",
    name: "Sanofi",
  },
  alexion: {
    hostnames: ["admin.alexion.g51.dev"],
    cmsUrl: "https://cms.alexion.g51.dev",
    kioskUrl: "https://kiosk.alexion.g51.dev",
    name: "Alexion",
  },
  gilead: {
    hostnames: ["admin.gilead.g51.dev"],
    cmsUrl: "https://cms.gilead.g51.dev",
    kioskUrl: "https://kiosk.gilead.g51.dev",
    name: "Gilead",
  },
  incyte: {
    hostnames: ["admin.incyte.g51.dev"],
    cmsUrl: "https://cms.incyte.g51.dev",
    kioskUrl: "https://kiosk.incyte.g51.dev",
    name: "Incyte",
  },
  takeda: {
    hostnames: ["admin.takeda.g51.dev"],
    cmsUrl: "https://cms.takeda.g51.dev",
    kioskUrl: "https://kiosk.takeda.g51.dev",
    name: "Takeda",
  },
  janssen: {
    hostnames: ["admin.janssen.g51.dev"],
    cmsUrl: "https://cms.janssen.g51.dev",
    kioskUrl: "https://kiosk.janssen.g51.dev",
    name: "Janssen",
  },
}

for (let it of Object.values(INSTANCE_CMS_CONFIG)) {
  const matches = it.hostnames.filter(host =>
    window.location.hostname.startsWith(host)
  )

  if (matches.length > 0) {
    console.log(
      `selected ${it.name} (${it.cmsUrl}) for hostname ${window.location.hostname}`
    )
    CMS_URL = it.cmsUrl
    APP_NAME = it.name
    KIOSK_URL = it.kioskUrl
    break
  }
}

const httpLink = new HttpLink({
  uri: `${CMS_URL}/query`,
})

export let token: string | null = null
try {
  const maybeToken = localStorage.getItem("token")
  const parsed = JSON.parse(maybeToken ?? "")
  if (!!parsed) token = parsed
} catch (err) {}

export const setToken = (set: string | null) => {
  token = set
}

export const hasToken = () => !!token

const authLink = new ApolloLink((op, forward) => {
  op.setContext(() => ({
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  }))

  return forward(op)
})

const typenameMiddleware = new ApolloLink((op, forward) => {
  const def = getMainDefinition(op.query)
  if (def && (def as any).operation === "mutation")
    op.variables = omitDeep(op.variables, "__typename")

  return forward ? forward(op) : null
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Touchless: {
        fields: {
          pages: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          users: {
            keyArgs: [],
            merge(existing = {}, incoming) {
              return {
                ...existing,
                total: incoming?.total ?? 0,
                users: [...(existing?.users ?? []), ...(incoming?.users ?? [])],
              }
            },
          },
        },
      },
    },
  }),
  link: from([authLink, typenameMiddleware, httpLink]),
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
)

import styled from "styled-components"

const IPointCard = styled.div`
  border: 2px solid #00000015;
  border-radius: 10px;
  height: calc(100% - 24px);
  padding: 1rem;
  margin-bottom: 24px;
  overflow: hidden;
  background-color: #0000000a;

  .sketch-picker {
    width: calc(100% - 20px) !important;
  }

  .color-control .ant-form-item-control-input-content > div {
    position: unset;
    width: auto;
  }

  .image-container {
    min-height: unset;
    height: unset;

    > span {
      min-height: unset;

      .uploaded {
        min-height: unset;
      }

      img {
        width: 100%;
      }
    }
  }
`

export default IPointCard

import React, { useContext, useState } from "react"
import { Page, ListHeader, Actions } from "../util/page"
import {
  PageHeader,
  message,
  Tabs,
  Button,
  Row,
  Col,
  Switch,
  Divider,
  Input,
  Radio,
} from "antd"
import { useQuery, gql, useMutation } from "@apollo/client"
import { useParams, Redirect } from "react-router"
import { ProjectContext } from "../App"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
  GetProjectQuery_project,
} from "./types/GetProjectQuery"
import { GET_PROJECT_QUERY } from "./CreateProject"
import styled, { css } from "styled-components"

import { ProjectFragments } from "./CreateProject"
import WelcomeTemplate, {
  Element as WelcomeElement,
} from "@g51/hubi-components/templates/basket/Welcome"
import ViewBasketTemplate, {
  Element as ViewBasketElement,
} from "@g51/hubi-components/templates/basket/ViewBasket"
import CheckoutTemplate, {
  Element as CheckoutElement,
} from "@g51/hubi-components/templates/basket/Checkout"
import CheckoutSuccessTemplate, {
  Element as CheckoutSuccessElement,
} from "@g51/hubi-components/templates/basket/CheckoutSuccess"
import { SaveOutlined } from "@ant-design/icons"
import { LIST_PROJECTS_QUERY } from "../util/queries"
import { animateScroll } from "react-scroll"
import {
  CustomElements,
  Property,
  Category,
  useEditable,
  DefaultBaseConfig,
  BaseElement,
  deserializeCustomValues,
  serializeCustomValues,
  UseEditable,
  getThemeProps,
  mobile,
} from "@g51/hubi-components"
import ImageUpload from "../components/ImageUpload"
import { CustomColorSelect } from "@g51/hubi-components/components/EditableBar"
import {
  UpdateProjectCustomValuesMutationWebsite,
  UpdateProjectCustomValuesMutationWebsiteVariables,
} from "./types/UpdateProjectCustomValuesMutationWebsite"
import { showLinkModal } from "../util/forms"
import { Helmet } from "react-helmet"
import { ExplainIcon, Tooltip } from "../util/util"
import { CMS_URL } from ".."

interface Params {
  id?: string
}

const clampBorderRadius = (v: string) => {
  try {
    const num = parseInt(v)
    return Number.isNaN(num) ? "" : Math.max(Math.min(num, 100), 0)
  } catch {
    return ""
  }
}

const UPDATE_PROJECT_CUSTOM_VALUES_MUTATION = gql`
  mutation UpdateProjectCustomValuesMutationBasket(
    $id: ID!
    $version: Int!
    $changes: UpdateProject!
  ) {
    updateProject(id: $id, version: $version, changes: $changes) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

const UpdateBasket = () => {
  const params = useParams<Params>()
  const { activeProject, setActiveProject } = useContext(ProjectContext)

  const { data, loading: loadingProject } = useQuery<
    GetProjectQuery,
    GetProjectQueryVariables
  >(GET_PROJECT_QUERY, {
    variables: { id: params.id!! },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      message.error("Das Projekt konnte nicht gefunden werden.")
    },
    onCompleted: data => {
      if (!data) return

      setActiveProject(data.project.id)
      setValues(deserializeCustomValues(data.project.customValues))
    },
  })

  const [updateProject, { loading: updatingProject }] = useMutation<
    UpdateProjectCustomValuesMutationWebsite,
    UpdateProjectCustomValuesMutationWebsiteVariables
  >(UPDATE_PROJECT_CUSTOM_VALUES_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    update: (store, { data }) => {
      store.writeQuery<GetProjectQuery, GetProjectQueryVariables>({
        query: GET_PROJECT_QUERY,
        data: { project: { ...data!!.updateProject } },
      })
    },
    onCompleted: () => {
      message.success("Projekt erfolgreich gespeichert.")
      animateScroll.scrollToTop({ duration: 300 })
    },
    onError: err => {
      if (err.networkError) {
        message.error("Beim Speichern ist ein Fehler aufgetreten.")
        return
      }

      message.error("Ein unbekannter Fehler ist aufgetreten.")
    },
  })

  const categories = (data?.project?.downloadables.categories ?? []).map<
    Category
  >(it => ({
    id: it.category.id,
    name: it.category.name,
    downloadables: it.downloadables.map(
      ({ id, name, description, previewImage }) => ({
        id,
        name,
        selected: false,
        description,
        previewImage,
      })
    ),
  }))

  const [values, setValues] = useState<CustomElements>({})
  const onSave = () => {
    if (!data?.project) return

    const toSave = serializeCustomValues(values)
    updateProject({
      variables: {
        id: data.project.id,
        version: data.project.version,
        changes: { customValues: toSave },
      },
    })
  }

  const themeEditable = useEditable({
    baseConfig: DefaultBaseConfig,
    editable: true,
    outer: values,
    setOuter: v => setValues(v),
    elements: {
      [WelcomeElement.Container]: BaseElement.Container,
      [WelcomeElement.Background]: BaseElement.Background,
      [ViewBasketElement.Container]: BaseElement.Container,
      [ViewBasketElement.Background]: BaseElement.Background,
      [CheckoutElement.Container]: BaseElement.Container,
      [CheckoutElement.Background]: BaseElement.Background,
      [CheckoutSuccessElement.Container]: BaseElement.Container,
      [CheckoutSuccessElement.Background]: BaseElement.Background,
    },
  })

  console.dir(values)

  if (activeProject && activeProject.id !== params.id)
    return <Redirect to={`/projects/${activeProject.id}/basket/edit`} />

  return (
    <Page>
      <Helmet>
        {!!values["font-heading"] && (
          <link rel="stylesheet" href={values["font-heading"]?.["css-url"]} />
        )}

        {!!values["font-body"] && (
          <link rel="stylesheet" href={values["font-body"]?.["css-url"]} />
        )}
      </Helmet>

      <ListHeader>
        <PageHeader
          title={`Basket für Projekt ${
            data?.project?.name ?? "..."
          } bearbeiten`}
        />

        <Actions>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={loadingProject}
            loading={updatingProject}
            onClick={() => onSave()}
          >
            Änderungen speichern
          </Button>
        </Actions>
      </ListHeader>

      <Tabs
        type="card"
        size="large"
        destroyInactiveTabPane
        style={{ marginBottom: "1rem", paddingBottom: "1rem" }}
      >
        <Tabs.TabPane tab="Willkommen" key="1">
          <BasketContainer
            {...getThemeProps(themeEditable, "basket", "welcome")}
            isLoading={loadingProject || updatingProject}
            headingFontFamily={`${values["font-heading"]?.family ?? ""}`}
            bodyFontFamily={`${values["font-body"]?.family ?? ""}`}
            sizeBodyNormal={values["font-body"]?.["size-normal"]}
            sizeBodyBig={values["font-body"]?.["size-big"]}
            sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
            sizeHeadingBig={values["font-heading"]?.["size-big"]}
          >
            <Logos projectData={data?.project} />

            <div className="container">
              <WelcomeTemplate
                value={values}
                onChange={v => setValues(v)}
                editable
                onGetLinkValue={cb => showLinkModal(cb)}
              />
            </div>
          </BasketContainer>

          <Divider />

          <BackgroundEditor
            app="basket"
            themeEditable={themeEditable}
            page="welcome"
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Basket bearbeiten" key="2">
          <BasketContainer
            {...getThemeProps(themeEditable, "basket", "view-basket")}
            isLoading={loadingProject || updatingProject}
            headingFontFamily={`${values["font-heading"]?.family ?? ""}`}
            bodyFontFamily={`${values["font-body"]?.family ?? ""}`}
            sizeBodyNormal={values["font-body"]?.["size-normal"]}
            sizeBodyBig={values["font-body"]?.["size-big"]}
            sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
            sizeHeadingBig={values["font-heading"]?.["size-big"]}
          >
            <Logos projectData={data?.project} />

            <div className="container">
              <ViewBasketTemplate
                value={values}
                onChange={v => setValues(v)}
                editable
                categories={categories}
                makeImageURL={s => `${CMS_URL}/images/${s}`}
                fallbackIcon={data?.project.defaultDownloadableIcon ?? ""}
                onGetLinkValue={cb => showLinkModal(cb)}
              />
            </div>
          </BasketContainer>

          <Divider />

          <BackgroundEditor
            app="basket"
            themeEditable={themeEditable}
            page="view-basket"
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Checkout" key="3">
          <BasketContainer
            {...getThemeProps(themeEditable, "basket", "checkout")}
            isLoading={loadingProject || updatingProject}
            headingFontFamily={`${values["font-heading"]?.family ?? ""}`}
            bodyFontFamily={`${values["font-body"]?.family ?? ""}`}
            sizeBodyNormal={values["font-body"]?.["size-normal"]}
            sizeBodyBig={values["font-body"]?.["size-big"]}
            sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
            sizeHeadingBig={values["font-heading"]?.["size-big"]}
          >
            <Logos projectData={data?.project} />

            <div className="container">
              <CheckoutTemplate
                emailPlaceholder={`${values["emailTexts"]?.["emailPlaceholder"]}`}
                emailInvalid={`${values["emailTexts"]?.["emailInvalid"]}`}
                value={values}
                onChange={v => setValues(v)}
                editable
                onGetLinkValue={cb => showLinkModal(cb)}
              />
            </div>
          </BasketContainer>

          <Divider />

          <BackgroundEditor
            app="basket"
            themeEditable={themeEditable}
            page="checkout"
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Checkout Erfolg" key="4">
          <BasketContainer
            {...getThemeProps(themeEditable, "basket", "checkout-success")}
            isLoading={loadingProject || updatingProject}
            headingFontFamily={`${values["font-heading"]?.family ?? ""}`}
            bodyFontFamily={`${values["font-body"]?.family ?? ""}`}
            sizeBodyNormal={values["font-body"]?.["size-normal"]}
            sizeBodyBig={values["font-body"]?.["size-big"]}
            sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
            sizeHeadingBig={values["font-heading"]?.["size-big"]}
          >
            <Logos projectData={data?.project} />

            <div className="container">
              <CheckoutSuccessTemplate
                value={values}
                onChange={v => setValues(v)}
                editable
                onGetLinkValue={cb => showLinkModal(cb)}
              />
            </div>
          </BasketContainer>

          <Divider />

          <BackgroundEditor
            app="basket"
            themeEditable={themeEditable}
            page="checkout-success"
          />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}

export const BackgroundEditor: React.FC<{
  app: "basket" | "web"
  themeEditable: UseEditable
  page: string
}> = ({ themeEditable, page, app }) => {
  return (
    <div style={{ padding: 1, marginBottom: 200 }}>
      <Row gutter={16} style={{ minHeight: "220px", marginBottom: "1rem" }}>
        <Col span={12}>
          <div style={{ marginBottom: "2rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              Seiten-Ausrichtung{" "}
              <ExplainIcon text="Diese Option bestimmt die Ausrichtung des Seiteninhalts." />
            </div>

            <Radio.Group
              value={
                themeEditable.getElementProperty(
                  `${app}.${page}/align`,
                  Property.TextAlign
                ) || "center"
              }
              onChange={s =>
                themeEditable.setElementProperty(
                  `${app}.${page}/align`,
                  Property.TextAlign,
                  s.target.value || "center"
                )
              }
            >
              <Radio.Button value="left">Links</Radio.Button>
              <Radio.Button value="center">Mitte</Radio.Button>
              <Radio.Button value="right">Rechts</Radio.Button>
            </Radio.Group>
          </div>

          <Tooltip text="Dieses Bild wird als Hintergrund verwendet, und so skaliert dass es bei jeder Auflösung den kompletten Hintergrund bedeckt. Die optimale Auflösung ist hier 1920x1080 bzw. die Auflösung des für den Basket verwendeten iPads. JPG und PNG ist erlaubt.">
            <ImageUpload
              text="Hintergrund"
              style={{ width: "100%" }}
              value={themeEditable.getElementProperty(
                `${app}.${page}/background`,
                Property.Image
              )}
              onChange={s =>
                themeEditable.setElementProperty(
                  `${app}.${page}/background`,
                  Property.Image,
                  s || null
                )
              }
            />
          </Tooltip>
        </Col>

        <Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            Hintergrundfarbe{" "}
            <ExplainIcon text="Diese Farbe wird als Hintergrundfarbe für den gesamten Basket/Website genutzt. Wird ein Hintergrundbild ausgewählt, liegt es über dieser Farbe." />
          </div>
          <BackgroundColorElement
            bgColor={
              themeEditable.getElementProperty(
                `${app}.${page}/background`,
                Property.BackgroundColor
              ) as string
            }
          >
            <CustomColorSelect
              defaultColor="#fff"
              color={themeEditable.getElementProperty(
                `${app}.${page}/background`,
                Property.BackgroundColor
              )}
              onChange={c =>
                themeEditable.setElementProperty(
                  `${app}.${page}/background`,
                  Property.BackgroundColor,
                  c
                )
              }
            />
          </BackgroundColorElement>
        </Col>
      </Row>

      <Divider />

      <Row gutter={16}>
        <Col span={24}>
          <Switch
            style={{ marginRight: "1rem" }}
            checked={themeEditable.getElementProperty(
              `${app}.${page}/container`,
              Property.HasContainer
            )}
            onChange={v =>
              themeEditable.setElementProperty(
                `${app}.${page}/container`,
                Property.HasContainer,
                v
              )
            }
          />
          Container anzeigen{" "}
          <ExplainIcon text="Diese Option aktiviert eine zusätzliche Hintergrundebene, der optional ein Schatten sowie ein eigenes Hintergrundbild gegeben werden kann." />
        </Col>
      </Row>

      {themeEditable.getElementProperty(
        `${app}.${page}/container`,
        Property.HasContainer
      ) && (
        <Row gutter={16} style={{ marginTop: "2rem", minHeight: 280 }}>
          <Col span={12}>
            <Tooltip text="Dieses Bild wird als Hintergrund für den Container genommen, und an den Rändern so abgeschnitten dass es immer den gesamten Container bedeckt.">
              <ImageUpload
                text="Container-Hintergrund"
                style={{ width: "100%" }}
                value={themeEditable.getElementProperty(
                  `${app}.${page}/container`,
                  Property.Image
                )}
                onChange={s =>
                  themeEditable.setElementProperty(
                    `${app}.${page}/container`,
                    Property.Image,
                    s || null
                  )
                }
              />
            </Tooltip>
          </Col>

          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row gutter={16} style={{ marginBottom: "1rem" }}>
              <Col span={12}>
                Gerundete Ecken{" "}
                <ExplainIcon text="Die Ecken des Containers lassen sich stufenweise abrunden, indem hier in Pixeln ein Radius eingegeben wird. Bei 0 sind die Ecken rechtwinklig." />
                <Input
                  style={{ width: "100%" }}
                  type="number"
                  placeholder="Rundung in Pixel"
                  value={themeEditable.getElementProperty(
                    `${app}.${page}/container`,
                    Property.BorderRadius
                  )}
                  onChange={v =>
                    themeEditable.setElementProperty(
                      `${app}.${page}/container`,
                      Property.BorderRadius,
                      clampBorderRadius(v.target.value)
                    )
                  }
                />
              </Col>

              <Col span={12}>
                Schatten anzeigen{" "}
                <ExplainIcon text="Diese Option fügt den Container einen Schatten hinzu, der ihn räumlich vom Hintergrund abhebt." />{" "}
                <br />
                <Switch
                  style={{ marginTop: "4px" }}
                  checked={themeEditable.getElementProperty(
                    `${app}.${page}/container`,
                    Property.HasShadow
                  )}
                  onChange={c =>
                    themeEditable.setElementProperty(
                      `${app}.${page}/container`,
                      Property.HasShadow,
                      c
                    )
                  }
                />
              </Col>
            </Row>
            <div>
              Containerfarbe{" "}
              <ExplainIcon text="Analog zur Hintergrundfarbe des Baskets/Website bestimmt diese Option die Hintergrundfarbe des Containers." />
            </div>
            <BackgroundColorElement
              bgColor={
                themeEditable.getElementProperty(
                  `${app}.${page}/container`,
                  Property.BackgroundColor
                ) as string
              }
            >
              <CustomColorSelect
                defaultColor="#fff"
                color={themeEditable.getElementProperty(
                  `${app}.${page}/container`,
                  Property.BackgroundColor
                )}
                onChange={c =>
                  themeEditable.setElementProperty(
                    `${app}.${page}/container`,
                    Property.BackgroundColor,
                    c
                  )
                }
              />
            </BackgroundColorElement>
          </Col>
        </Row>
      )}
    </div>
  )
}

export const BackgroundColorElement = styled.div<{ bgColor: string }>`
  background: ${props => props.bgColor};
  margin-top: 0.5rem;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #333;
  position: relative;

  > * {
    position: absolute;
    top: -1px;
    right: -1px;
    left: auto;
  }
`

const BasketContainer = styled.div<{
  isLoading: boolean
  backgroundColor: string
  backgroundImage: string | null
  hasContainer: boolean
  hasContainerShadow: boolean
  containerBorderRadius: number
  containerBackgroundColor: string
  containerBackgroundImage: string | null
  headingFontFamily: string | null
  bodyFontFamily: string | null
  pageAlign: string | null
  sizeBodyNormal?: number
  sizeBodyBig?: number
  sizeHeadingNormal?: number
  sizeHeadingBig?: number
}>`
  * {
    font-family: ${props => props.bodyFontFamily || "sans-serif"};
  }

  h1,
  h2 {
    * {
      font-family: ${props => props.headingFontFamily || "sans-serif"};
    }
  }

  .DraftEditor-root span {
    font-size: ${props => props.sizeBodyNormal || 16}px;
  }

  .DraftEditor-root h3 span {
    font-size: ${props => props.sizeBodyBig || 22}px;
  }

  .DraftEditor-root h2 span {
    font-size: ${props => props.sizeHeadingNormal || 26}px;
  }

  .DraftEditor-root h1 span {
    font-size: ${props => props.sizeHeadingBig || 32}px;
  }

  .category {
    font-size: ${props => props.sizeBodyBig || 22}px;
  }

  .container .downloadables {
    .info {
      padding-right: 16px;
    }

    .item .meta {
      font-size: ${props => props.sizeBodyNormal || 16}px;

      .desc {
        font-size: 0.95em;
      }
    }

    .info + div {
      flex-shrink: 0;
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -16px;
  padding: 2rem;
  border: 1px solid #f0f0f0;
  border-top: none;
  transition: opacity 150ms linear;
  background-color: ${props => props.backgroundColor};

  align-items: center;

  ${props =>
    props.pageAlign === "left" &&
    css`
      align-items: flex-start;
    `}

  ${props =>
    props.pageAlign === "right" &&
    css`
      align-items: flex-end;
    `}

  ${props =>
    props.backgroundImage &&
    css`
      background-position: center center;
      background-size: cover;
      background-image: url(${CMS_URL}/images/${props.backgroundImage});
    `}

  .container {
    border: 1px solid #666;
    width: 1024px;
    height: 768px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 3rem;
    background-color: ${props => props.containerBackgroundColor};
    border-radius: ${props => props.containerBorderRadius || "0"}px;
    margin-top: 2rem;

    ${props =>
      props.hasContainerShadow &&
      css`
        box-shadow: 0px 0px 30px 16px rgba(0, 0, 0, 0.1);
      `}

    ${props =>
      props.containerBackgroundImage &&
      css`
        background-position: center center;
        background-size: cover;
        background-image: url(${CMS_URL}/images/${props.containerBackgroundImage});
      `}

    ${props =>
      !props.hasContainer &&
      css`
        border-color: transparent !important;
        background: transparent !important;
        box-shadow: none !important;
        padding: 1.5rem 0;
        margin-top: 0 !important;
      `}
  }

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`

const LogoContainer = styled.div`
  width: 1024px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  .right,
  .left,
  .center {
    flex: 1;
    display: flex;
  }

  .left {
    justify-content: flex-start;
  }

  .center {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }

  .right .sub:not(:first-child) {
    margin-left: 2rem;
  }

  .left .main:not(:last-child) {
    margin-right: 2rem;
  }

  .center .main:not(:last-child) {
    margin-right: 2rem;
  }

  img {
    object-fit: contain;
    max-height: 400px;
    max-width: 300px;
  }

  ${mobile`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sub, .main {
      margin-left: 0!important;
      margin-right: 0!important;
      max-width: 80%;
    }

    .right, .left, .center {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    img {
      margin-bottom: 2.5rem;
    }
  `}
`

export const Logos: React.FC<{ projectData?: GetProjectQuery_project }> = ({
  projectData,
}) => (
  <LogoContainer>
    {["left", "center", "right"].map(pos => (
      <div className={pos} key={pos}>
        {projectData?.logoAlignment === pos && (
          <img
            src={makeImageURL(projectData?.logo)}
            alt="Logo"
            className="main"
          />
        )}

        {projectData?.showSubLogo && projectData?.subLogoAlignment === pos && (
          <img
            src={makeImageURL(projectData?.subLogo!!)}
            alt="Logo"
            className="sub"
          />
        )}
      </div>
    ))}
  </LogoContainer>
)

export const makeImageURL = (s: string) => `${CMS_URL}/images/${s}`

export default UpdateBasket

import React, { useState, useContext, useEffect } from "react"
import _ from "lodash"
import { gql, useMutation, useQuery } from "@apollo/client"
import { ListHeader, Actions, Page, ListActions } from "../util/page"
import { Button, Modal, PageHeader, Table, Tooltip } from "antd"
import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface"
import {
  ListBasketsQuery,
  ListBasketsQueryVariables,
  ListBasketsQuery_baskets_baskets,
} from "./types/ListBasketsQuery"
import { ProjectContext } from "../App"
import { useParams, Redirect } from "react-router"
import { DeleteOutlined } from "@ant-design/icons"
import { DeleteBasket, DeleteBasketVariables } from "./types/DeleteBasket"
import {
  DeleteAllBaskets,
  DeleteAllBasketsVariables,
} from "./types/DeleteAllBaskets"

const BasketFragments = {
  BasketFields: gql`
    fragment BasketFields on Basket {
      id
      nfcCode
      downloadables {
        id
        name
      }
      created
      updated
      version
    }
  `,
}

const LIST_BASKETS_QUERY = gql`
  query ListBasketsQuery($projectId: ID!, $pageSize: Int!, $page: Int!) {
    baskets(projectId: $projectId, pageSize: $pageSize, page: $page) {
      total
      baskets {
        ...BasketFields
      }
    }
  }
  ${BasketFragments.BasketFields}
`

const DELETE_BASKET_MUTATION = gql`
  mutation DeleteBasket($basketId: ID!) {
    deleteBasket(basketId: $basketId)
  }
`

const DELETE_ALL_BASKETS_MUTATION = gql`
  mutation DeleteAllBaskets($projectId: ID!) {
    deleteAllBaskets(projectId: $projectId)
  }
`

interface Params {
  projectId: string
}

const ListBaskets = () => {
  const params = useParams<Params>()
  const { activeProject, setActiveProject } = useContext(ProjectContext)

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const listBasketsQuery = useQuery<
    ListBasketsQuery,
    ListBasketsQueryVariables
  >(LIST_BASKETS_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !activeProject,
    variables: {
      projectId: activeProject?.id ?? "",
      page: (pagination.current ?? 1) - 1,
      pageSize: pagination.pageSize ?? 20,
    },
    onCompleted: res => {
      if (!res) return

      setPagination({
        ...pagination,
        total: res.baskets.total,
      })
    },
  })

  const [deleteBasket] = useMutation<DeleteBasket, DeleteBasketVariables>(
    DELETE_BASKET_MUTATION,
    {
      refetchQueries: [
        {
          query: LIST_BASKETS_QUERY,
          variables: {
            projectId: activeProject?.id ?? "",
            page: (pagination.current ?? 1) - 1,
            pageSize: pagination.pageSize ?? 20,
          },
        },
      ],
    }
  )

  const [deleteAllBaskets] = useMutation<
    DeleteAllBaskets,
    DeleteAllBasketsVariables
  >(DELETE_ALL_BASKETS_MUTATION, {
    refetchQueries: [
      {
        query: LIST_BASKETS_QUERY,
        variables: {
          projectId: activeProject?.id ?? "",
          page: (pagination.current ?? 1) - 1,
          pageSize: pagination.pageSize ?? 20,
        },
      },
    ],
  })

  const onTableChange = (
    newPagination: TablePaginationConfig,
    _newFilters: Record<string, React.ReactText[] | null>,
    _newSorting:
      | SorterResult<ListBasketsQuery_baskets_baskets>
      | SorterResult<ListBasketsQuery_baskets_baskets>[]
  ) => {
    const page = newPagination.current ?? 1
    const pageSize = newPagination.pageSize ?? 10

    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    })

    const offset = (page - 1) * pageSize
    const itemCount = listBasketsQuery.data?.baskets?.baskets?.length ?? 0
    if (itemCount > offset) return

    listBasketsQuery.fetchMore({
      variables: { page: page - 1, pageSize },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        return {
          ...prev,
          users: {
            ...prev.baskets,
            total: fetchMoreResult.baskets.total,
            users: [
              ...prev.baskets.baskets,
              ...fetchMoreResult.baskets.baskets,
            ],
          },
        }
      },
    })
  }

  const currentPage = (pagination.current ?? 1) - 1
  const offset = currentPage * (pagination.pageSize ?? 10)

  const currentItems = _.take(
    _.drop(listBasketsQuery.data?.baskets?.baskets ?? [], offset),
    pagination.pageSize
  )

  useEffect(() => {
    if (!activeProject) setActiveProject(params.projectId)
  }, [activeProject, params])

  if (activeProject && activeProject.id !== params.projectId)
    return <Redirect to={`/baskets/${activeProject.id}`} />

  return (
    <Page>
      <ListHeader>
        <PageHeader title="Baskets" />

        <Actions>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              Modal.confirm({
                title: "Alle Baskets löschen",
                content: (
                  <>
                    Sind Sie sicher, dass <b>alle Baskets</b> für dieses Projekt
                    gelöscht werden sollen?
                  </>
                ),
                okText: "Ja, alles Löschen",
                okButtonProps: {
                  danger: true,
                },
                onOk: () =>
                  deleteAllBaskets({
                    variables: { projectId: activeProject?.id ?? `` },
                  }),
                cancelText: "Abbrechen",
              })
            }}
          >
            Alle Löschen
          </Button>
        </Actions>
      </ListHeader>

      <Table
        dataSource={currentItems}
        pagination={pagination}
        loading={listBasketsQuery.loading}
        rowKey={row => row.id}
        onChange={(pagination, filters, sorting) =>
          onTableChange(pagination, filters, sorting)
        }
        bordered
      >
        <Table.Column title="NFC-Code" dataIndex="nfcCode" key={0} />

        <Table.Column
          title="Downloadables"
          dataIndex="downloadables"
          render={dls => `${dls.length} Downloadable(s)`}
          key={1}
        />

        <Table.Column
          key={5}
          title="Aktionen"
          width={105}
          fixed="right"
          render={(basket: ListBasketsQuery_baskets_baskets) => (
            <ListActions>
              <Tooltip title="Löschen">
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() =>
                    Modal.confirm({
                      title: `Basket "${basket.nfcCode}" löschen`,
                      content:
                        "Sind Sie sicher, dass dieser Basket gelöscht werden soll?",
                      okText: "Ja, Basket löschen",
                      okButtonProps: {
                        danger: true,
                      },
                      onOk: () =>
                        deleteBasket({ variables: { basketId: basket.id } }),
                      cancelText: "Abbrechen",
                    })
                  }
                />
              </Tooltip>
            </ListActions>
          )}
        />
      </Table>
    </Page>
  )
}

export default ListBaskets

import React, { useEffect, useState } from "react"
import {
  message,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Table,
  Tooltip,
} from "antd"
import { gql, useMutation, useQuery } from "@apollo/client"
import { ListIPoints_ipoints } from "./types/ListIPoints"
import {
  GetStartpage,
  GetStartpageVariables,
  GetStartpage_ipointStartpage_sub_pages,
} from "./types/GetStartpage"
import {
  GET_DESTINATIONS_QUERY,
  GET_STARTPAGE_QUERY,
} from "./UpdateIPointStartpage"
import IPointImageUpload from "../components/IPointImageUpload"
import {
  PlusOutlined,
  DeleteOutlined,
  DiffOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { CreateMenupage, CreateMenupageVariables } from "./types/CreateMenupage"
import { ListActions } from "../util/page"
import { DeleteMenupage, DeleteMenupageVariables } from "./types/DeleteMenupage"
import {
  GetDestinations,
  GetDestinationsVariables,
} from "./types/GetDestinations"
import { useForm } from "antd/lib/form/Form"
import { UpdateIPointMenupage } from "../graphql-types-global"
import { UpdateMenupage, UpdateMenupageVariables } from "./types/UpdateMenupage"
import styled from "styled-components"

const CREATE_MENUPAGE_MUTATION = gql`
  mutation CreateMenupage($ipointId: ID!, $name: String!) {
    createIPointMenupage(ipointId: $ipointId, name: $name) {
      page_id
      page_name
      bg_img
      destination_id
      menu_items {
        name
        image
        pos
        destination_id
      }
    }
  }
`

const DELETE_MENUPAGE_MUTATION = gql`
  mutation DeleteMenupage($id: ID!, $ipointId: ID!) {
    deleteIPointMenupage(id: $id, ipointId: $ipointId) {
      page_id
    }
  }
`

const UPDATE_MENUPAGE_MUTATION = gql`
  mutation UpdateMenupage($ipointId: ID!, $page: UpdateIPointMenupage!) {
    updateIPointMenupage(ipointId: $ipointId, page: $page) {
      page_id
    }
  }
`

const UpdateIPointMenupageComponent: React.FC<{
  ipoint: ListIPoints_ipoints
}> = ({ ipoint }) => {
  const [createMenuPage, createMenuPageMutation] = useMutation<
    CreateMenupage,
    CreateMenupageVariables
  >(CREATE_MENUPAGE_MUTATION, {
    onCompleted: data => {
      message.success("Menu Page erfolgreich erstellt")
      setShowNameModal(false)
      setName("")
      setSelectedMenuPage(data.createIPointMenupage)
    },
    refetchQueries: [
      { query: GET_STARTPAGE_QUERY, variables: { ipointId: ipoint.id } },
    ],
  })

  useEffect(() => {
    setSelectedMenuPage(null)
  }, [ipoint])

  const destinationsQuery = useQuery<GetDestinations, GetDestinationsVariables>(
    GET_DESTINATIONS_QUERY,
    {
      variables: { ipointId: ipoint.id },
    }
  )

  const dests = destinationsQuery.data?.ipointDestinations ?? []

  const startpageQuery = useQuery<GetStartpage, GetStartpageVariables>(
    GET_STARTPAGE_QUERY,
    {
      variables: { ipointId: ipoint.id },
    }
  )

  const [deleteMenupage, deleteMenupageMutation] = useMutation<
    DeleteMenupage,
    DeleteMenupageVariables
  >(DELETE_MENUPAGE_MUTATION, {
    onCompleted: data => {
      message.success("Menu Page erfolgreich gelöscht")
      setShowDeleteModal(false)
      setDeletingMenuPage(null)

      if (selectedMenuPage?.page_id === data.deleteIPointMenupage.page_id)
        setSelectedMenuPage(null)
    },
    refetchQueries: [
      { query: GET_STARTPAGE_QUERY, variables: { ipointId: ipoint.id } },
    ],
  })

  const [updateMenupage, updateMenupageMutation] = useMutation<
    UpdateMenupage,
    UpdateMenupageVariables
  >(UPDATE_MENUPAGE_MUTATION, {
    onCompleted: () => {
      message.success("Menu Page erfolgreich gespeichert")
    },
    refetchQueries: [
      { query: GET_STARTPAGE_QUERY, variables: { ipointId: ipoint.id } },
    ],
  })

  const [menuPageForm] = useForm()

  const [showNameModal, setShowNameModal] = useState(false)
  const [name, setName] = useState("")
  const [
    selectedMenuPage,
    setSelectedMenuPage,
  ] = useState<GetStartpage_ipointStartpage_sub_pages | null>(null)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [
    deletingMenuPage,
    setDeletingMenuPage,
  ] = useState<GetStartpage_ipointStartpage_sub_pages | null>(null)

  const onSaveMenuPage = (data: any) => {
    if (!startpageQuery.data || !selectedMenuPage) return

    const menu_items = [...Array(8).keys()]
      .map(i => {
        const img = data[`menu_${i + 1}`]
        const dest = data[`menu_${i + 1}_destination`]

        if (!img) return null

        return {
          name: `Menu ${i + 1}`,
          pos: i,
          destination_id: dest ?? "",
          image: img,
        }
      })
      .filter(it => it !== null)
      .map(it => it!!)

    const values: UpdateIPointMenupage = {
      bg_img: data.bg_img ?? "",
      destination_id: selectedMenuPage.destination_id,
      page_id: selectedMenuPage.page_id,
      page_name: selectedMenuPage.page_name,
      menu_items,
    }

    updateMenupage({
      variables: {
        ipointId: ipoint.id,
        page: values,
      },
    })
  }

  useEffect(() => {
    menuPageForm.resetFields()
    if (!selectedMenuPage) return

    const menu_items =
      selectedMenuPage?.menu_items.reduce(
        (acc, it) => ({
          ...acc,
          [`menu_${it.pos + 1}`]: it.image,
          [`menu_${it.pos + 1}_destination`]: it.destination_id,
        }),
        {}
      ) ?? {}

    const values = {
      bg_img: selectedMenuPage.bg_img,
      ...menu_items,
    }

    menuPageForm.setFieldsValue(values)
  }, [selectedMenuPage])

  return (
    <>
      <Modal
        title={`Menu Page ${deletingMenuPage?.page_name ?? ""} löschen`}
        visible={showDeleteModal}
        okText="Löschen"
        onCancel={() => {
          setShowDeleteModal(false)
          setDeletingMenuPage(null)
        }}
        okButtonProps={{
          danger: true,
          loading: deleteMenupageMutation.loading,
        }}
        cancelButtonProps={{
          disabled: deleteMenupageMutation.loading,
        }}
        onOk={() => {
          if (!deletingMenuPage) return
          deleteMenupage({
            variables: { id: deletingMenuPage.page_id, ipointId: ipoint.id },
          })
        }}
      >
        <p>
          Sind Sie sicher, dass Sie die Menu Page{" "}
          {deletingMenuPage?.page_name ?? ""} löschen möchten?
        </p>
      </Modal>

      <Row gutter={24}>
        <Col span={16}>
          <h2 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            Menu Pages für {ipoint.project_name}:
          </h2>
        </Col>

        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setShowNameModal(true)}
          >
            Neue Menu Page
          </Button>

          <Modal
            title="Neue Menu Page"
            visible={showNameModal}
            okText="Menu Page erstellen"
            cancelText="Abbrechen"
            okButtonProps={{ loading: createMenuPageMutation.loading }}
            cancelButtonProps={{ disabled: createMenuPageMutation.loading }}
            onCancel={() => {
              setShowNameModal(false)
              setName("")
            }}
            onOk={() => {
              if (!name) return
              createMenuPage({ variables: { ipointId: ipoint.id, name } })
            }}
          >
            <h3>Geben Sie einen Namen für die Menu Page ein:</h3>
            <Input
              onChange={e => setName(e.target.value)}
              style={{ width: "80%" }}
            />
          </Modal>
        </Col>
      </Row>

      <TableContainer>
        <Table
          style={{ marginBottom: "2rem" }}
          dataSource={startpageQuery.data?.ipointStartpage.sub_pages || []}
          rowKey="page_id"
          loading={startpageQuery.loading}
          pagination={false}
          bordered
          rowClassName={(p: GetStartpage_ipointStartpage_sub_pages) =>
            p.page_id === selectedMenuPage?.page_id ? "selected-row" : ""
          }
        >
          <Table.Column title="Name" key={0} dataIndex="page_name" />

          <Table.Column
            key={5}
            title="Aktionen"
            width={105}
            fixed="right"
            render={(menuPage: GetStartpage_ipointStartpage_sub_pages) => (
              <ListActions>
                <Tooltip
                  title={
                    selectedMenuPage?.page_id === menuPage.page_id
                      ? `Ausgewählt`
                      : "Menu Page bearbeiten"
                  }
                >
                  {selectedMenuPage?.page_id === menuPage.page_id ? (
                    <Button icon={<CheckOutlined />} type="primary" disabled />
                  ) : (
                    <Button
                      icon={<DiffOutlined />}
                      type="primary"
                      onClick={() => {
                        setSelectedMenuPage(menuPage)
                      }}
                    />
                  )}
                </Tooltip>

                <Tooltip title="Löschen">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setDeletingMenuPage(menuPage)
                      setShowDeleteModal(true)
                    }}
                  />
                </Tooltip>
              </ListActions>
            )}
          />
        </Table>
      </TableContainer>

      <div style={{ visibility: !!selectedMenuPage ? "unset" : "hidden" }}>
        <h3 style={{ paddingTop: "1rem" }}>
          Menu Page {selectedMenuPage?.page_name} bearbeiten:
        </h3>

        <Form form={menuPageForm} onFinish={values => onSaveMenuPage(values)}>
          <Row gutter={24}>
            <Col span={4}>
              <IPointImageUpload label="Background Image" name="bg_img" />
            </Col>

            {[...Array(4).keys()]
              .map(idx => idx + 1)
              .map(idx => (
                <Col span={5} key={idx}>
                  <IPointImageUpload
                    label={`Menu ${idx}`}
                    name={`menu_${idx}`}
                    dests={dests}
                    isLink
                  />
                </Col>
              ))}
          </Row>

          <Row gutter={24}>
            <Col span={4} />

            {[...Array(4).keys()]
              .map(idx => idx + 5)
              .map(idx => (
                <Col span={5} key={idx}>
                  <IPointImageUpload
                    label={`Menu ${idx}`}
                    name={`menu_${idx}`}
                    dests={dests}
                    isLink
                  />
                </Col>
              ))}
          </Row>

          <Button
            loading={updateMenupageMutation.loading}
            type="primary"
            htmlType="submit"
            style={{ marginTop: 15 }}
          >
            Menu Page speichern
          </Button>
        </Form>
      </div>
    </>
  )
}

const TableContainer = styled.div`
  .selected-row {
    background: #f0f0f0;
  }
`

export default UpdateIPointMenupageComponent

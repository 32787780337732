import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { Card, Button, Form, Input, Progress } from "antd"
import { MailOutlined } from "@ant-design/icons"
import { CMS_URL } from ".."

const ResendActivation = () => {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const onSend = async (email: string) => {
    setLoading(true)

    try {
      await axios.post(`${CMS_URL}/auth/activation/resend`, { username: email })

      setSent(true)
    } catch (err) {
      setSent(true)
    }
  }

  return (
    <Container>
      <ContentCard>
        {!sent && (
          <>
            <InfoText>
              Bitte geben Sie Ihre E-Mail-Adresse ein, um einen neuen
              Aktivierungs-Link zu erhalten.
            </InfoText>

            <Form
              name="email"
              onFinish={(values) => onSend(values.email as string)}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie Ihre E-Mail ein",
                  },
                  {
                    type: "email",
                    message: "Bitte geben Sie eine gültige E-Mail ein",
                  },
                ]}
              >
                <Input
                  prefix={<MailIcon />}
                  disabled={loading}
                  placeholder="E-Mail"
                />
              </Form.Item>

              <SubmitButton type="primary" htmlType="submit" loading={loading}>
                Aktivierungs-Link erneut senden
              </SubmitButton>
            </Form>
          </>
        )}

        {sent && (
          <>
            <InfoText>
              Falls ein nicht aktivierter Account mit Ihrer E-Mail existiert,
              erhalten Sie einen neuen Aktivierungslink.
            </InfoText>
            <Progress percent={100} status="success" showInfo={false} />
          </>
        )}
      </ContentCard>
    </Container>
  )
}

const MailIcon = styled(MailOutlined)`
  color: rgba(0, 0, 0, 0.25);
`

const SubmitButton = styled(Button)`
  margin-top: 0.5rem;
  width: 100%;
`

const ContentCard = styled(Card)`
  width: 24rem;
  margin: 2rem;
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoText = styled.p`
  width: 100%;
`

export default ResendActivation

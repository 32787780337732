import React, { useState, ReactNode } from "react"
import styled, { css } from "styled-components"
import {
  FileOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  DeleteOutlined,
  SaveOutlined,
  DownloadOutlined,
  MenuOutlined,
} from "@ant-design/icons"
import { Button, Input, Progress, Popconfirm, Upload, message } from "antd"
import TextArea from "antd/lib/input/TextArea"
import Axios, { AxiosError } from "axios"
import { CMS_URL, token } from ".."
import ReactTooltip from "react-tooltip"

interface DownloadableItemProps {
  state?: "uploading" | "creating" | "saving" | "saved" | "failed"
  uploadingPercent?: number
  originalFilename?: string
  filenameValue?: string
  downloadableId?: string
  onFilenameChange?: (v: string) => void
  extension?: string
  onSave?: (name: string, desc: string) => void
  onDelete?: () => void
  disabled?: boolean
  deleting?: boolean
  updating?: boolean
  saveDisabled?: boolean
  descriptionValue?: string
  onDescriptionChange?: (v: string) => void
  previewImage?: string
  onPreviewImageChange?: (v: string) => void
  fallbackImage?: string
  dragHandle?: ReactNode
}

const renderFileIcon = (
  extension: string,
  previewIcon?: string,
  fallbackIcon?: string
) => {
  if (previewIcon)
    return <FileThumb src={`${CMS_URL}/images/${previewIcon}`} alt="Vorschau" />

  if (fallbackIcon)
    return (
      <FileThumb src={`${CMS_URL}/images/${fallbackIcon}`} alt="Vorschau" />
    )

  switch (extension) {
    case ".jpg":
    case ".jpeg":
      return <FileImageOutlined />
    case ".pdf":
      return <FilePdfOutlined />
  }

  return <FileOutlined />
}

const fileExtensionRegex = /(?:\.([^.]+))?$/

const DownloadableItem: React.FC<DownloadableItemProps> = ({
  state = "saved",
  uploadingPercent = 0,
  originalFilename,
  filenameValue,
  downloadableId,
  onFilenameChange,
  extension,
  onSave,
  onDelete,
  disabled,
  deleting,
  updating,
  saveDisabled,
  descriptionValue,
  onDescriptionChange,
  previewImage,
  onPreviewImageChange,
  fallbackImage,
  dragHandle,
}) => {
  let progressStatus: "normal" | "exception" | "active" | "success" = "normal"
  switch (state) {
    case "uploading":
      progressStatus = uploadingPercent >= 100 ? "success" : "active"
      break
    case "failed":
      progressStatus = "exception"
      break
  }

  const [uploadingPreview, setUploadingPreview] = useState(false)

  const onUploadPreviewImage = async (file: File) => {
    const formData = new FormData()
    formData.append("file", file)

    const extension = file.name.match(fileExtensionRegex)?.[0]
    if (!extension) {
      message.error(`Die Datei "${file.name}" hat ein ungültiges Format.`)
      return
    }

    if (![".jpeg", ".jpg", ".png"].includes(extension)) {
      message.error(`Die Datei "${file.name}" hat ein ungültiges Format.`)
      return
    }

    setUploadingPreview(true)
    try {
      const formData = new FormData()
      formData.append("file", file!!)

      const res = await Axios.post(
        `${CMS_URL}/files/image?thumb=true`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      onPreviewImageChange && onPreviewImageChange(res.data.fileId)
      setUploadingPreview(false)
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      setUploadingPreview(false)

      if ((err as AxiosError).response?.data === "invalid file type\n")
        message.error(`Das Dateiformat "${extension}" ist nicht erlaubt.`)
      else if (
        (err as AxiosError).response?.data === "max width or height exceeded\n"
      )
        message.error(`Das Bild darf nicht größer als 400x400 sein.`)
      else
        message.error(
          `Beim Upload der Datei "${file.name}" ist ein Fehler aufgetreten.`
        )
    }
  }

  return (
    <DownloadableContainer>
      {dragHandle ?? <DragHandle style={{ opacity: 0.5 }} />}

      <div className="icon-upload">
        <Upload
          showUploadList={false}
          disabled={
            disabled ||
            ["uploading", "failed"].includes(state) ||
            uploadingPreview ||
            deleting ||
            updating
          }
          beforeUpload={(v) => {
            onUploadPreviewImage(v)
            return false
          }}
          style={{ position: "relative", height: "100%" }}
        >
          <DownloadableIcon data-tip="Bild hochladen (JPG oder PNG, maximal 400x400 Pixel, bevorzugt quadratisch)">
            {renderFileIcon(extension ?? "", previewImage, fallbackImage)}
          </DownloadableIcon>
        </Upload>
      </div>

      <DownloadableInputs>
        <div className="name">
          <Input
            value={filenameValue}
            disabled={
              disabled ||
              ["uploading", "failed"].includes(state) ||
              deleting ||
              updating
            }
            addonAfter={extension}
            onChange={(e) =>
              onFilenameChange && onFilenameChange(e.target.value)
            }
          />
          <Input
            value={originalFilename}
            disabled
            style={{ cursor: "default" }}
          />
        </div>

        <div className="desc">
          <TextArea
            autoSize
            placeholder="Beschreibung..."
            value={descriptionValue}
            onChange={(e) =>
              onDescriptionChange && onDescriptionChange(e.target.value)
            }
          />
        </div>
      </DownloadableInputs>

      <DownloadableActions>
        <Button
          loading={updating}
          onClick={() =>
            onSave && onSave(filenameValue ?? "", descriptionValue ?? "")
          }
          disabled={disabled || deleting || saveDisabled}
          type="primary"
          icon={<SaveOutlined />}
          data-tip="Änderungen speichern"
        />

        {disabled || deleting || updating ? (
          <Button disabled icon={<DownloadOutlined />} />
        ) : (
          <>
            <a
              href={`${CMS_URL}/files/downloadables/${downloadableId}`}
              target="_blank noopener noreferrer"
              download={originalFilename}
              data-tip="Herunterladen"
            >
              <Button icon={<DownloadOutlined />} />
            </a>
          </>
        )}

        <Popconfirm
          title="Downloadable löschen?"
          okText="Löschen"
          okButtonProps={{ danger: true }}
          cancelText="Abbrechen"
          onCancel={() => {}}
          onConfirm={() => onDelete && onDelete()}
          placement="left"
        >
          <Button
            loading={deleting}
            disabled={disabled}
            danger
            data-tip="Löschen"
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </DownloadableActions>

      <UploadingOverlay shown={["uploading", "failed"].includes(state)}>
        <Progress
          percent={uploadingPercent}
          status={progressStatus}
          showInfo={false}
        />
      </UploadingOverlay>

      <ReactTooltip place="top" effect="solid" />
    </DownloadableContainer>
  )
}

export const DragHandle: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => (
  <DragHandleInner style={style}>
    <MenuOutlined />
  </DragHandleInner>
)

const DragHandleInner = styled.div`
  font-size: 24px;
  width: 50px;
  min-height: 100%;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    opacity: 0.3;
  }
`

const UploadingOverlay = styled.div<{ shown: boolean }>`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0 2rem;
  align-items: center;

  ${(props) =>
    !props.shown &&
    css`
      display: none;
      pointer-events: none;
    `}
`

const DownloadableContainer = styled.div`
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #fff;

  display: flex;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;

  .icon-upload {
    min-height: 100%;
    display: flex;
    align-items: center;
    margin-left: 1rem;

    > span {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`

const DownloadableIcon = styled.div`
  color: #40a9ff;
  font-size: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.75;
  }
`

const DownloadableInputs = styled.div`
  padding: 0 1rem 0 1rem;
  flex: 1;

  .name {
    padding: 1rem 0 0.25rem 0;
    display: flex;
    flex: 1;

    > *:first-child {
      margin-right: 1rem;
    }
  }

  .desc {
    padding: 0.25rem 0 1rem 0;
  }
`

const FileThumb = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`

const DownloadableActions = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export default DownloadableItem

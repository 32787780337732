import React, { useContext, useState } from "react"
import _ from "lodash"
import { Page, Actions, ListActions, ListHeader } from "../util/page"
import { gql, useQuery, useMutation } from "@apollo/client"
import { Table, Button, Tooltip, PageHeader, Modal, Input, message } from "antd"
import {
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  SettingOutlined,
} from "@ant-design/icons"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { UserContext } from "../App"
import styled from "styled-components"
import { renderRole } from "../util/formats"
import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface"
import {
  ListUsersQuery,
  ListUsersQueryVariables,
  ListUsersQuery_users_users,
} from "./types/ListUsersQuery"
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
} from "./types/DeleteUserMutation"
import { Role } from "../graphql-types-global"

export const UserFragments = {
  UserFields: gql`
    fragment UserFields on User {
      id
      email
      name
      role
      projects
      active
      disabled
      created
      updated
      version
    }
  `,
}

export const LIST_USERS_QUERY = gql`
  query ListUsersQuery($pageSize: Int!, $page: Int!) {
    users(pageSize: $pageSize, page: $page) {
      users {
        ...UserFields
      }
      total
    }
  }
  ${UserFragments.UserFields}
`

const DELETE_USER_MUTATION = gql`
  mutation DeleteUserMutation($id: ID!, $version: Int!) {
    deleteUser(id: $id, version: $version) {
      id
    }
  }
`

const ListUsers = () => {
  const currentUser = useContext(UserContext)

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const listUsersQuery = useQuery<ListUsersQuery, ListUsersQueryVariables>(
    LIST_USERS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        page: (pagination.current ?? 1) - 1,
        pageSize: pagination.pageSize ?? 10,
      },
      onCompleted: (res) => {
        setPagination({
          ...pagination,
          total: res.users.total ?? 0,
        })
      },
    }
  )

  const [
    userToDelete,
    setUserToDelete,
  ] = useState<ListUsersQuery_users_users | null>(null)

  const showDeleteModal = (user: ListUsersQuery_users_users) => {
    setUserToDelete(user)
  }

  const [deleteEmailInput, setEmailDeleteInput] = useState("")

  const [deleteUser, { loading: deletingUser }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DELETE_USER_MUTATION, {
    notifyOnNetworkStatusChange: true,
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          users: (users) => {
            return {
              total: users.total ? users.total - 1 : 0,
              users: (users.users ?? []).filter(
                (it: any) => it.__ref !== `User:${data?.deleteUser.id}`
              ),
            }
          },
        },
      })
    },
  })

  const onDelete = async () => {
    if (deleteEmailInput !== userToDelete?.email) return

    try {
      await deleteUser({
        variables: {
          id: userToDelete?.id ?? "",
          version: userToDelete?.version ?? 0,
        },
      })

      message.success("Benutzer wurde erfolgreich gelöscht")
    } catch (err) {
      message.error("Ein Fehler ist aufgetreten.")
    }

    setEmailDeleteInput("")
    setUserToDelete(null)
  }

  const onTableChange = (
    newPagination: TablePaginationConfig,
    _newFilters: Record<string, React.ReactText[] | null>,
    _newSorting:
      | SorterResult<ListUsersQuery_users_users>
      | SorterResult<ListUsersQuery_users_users>[]
  ) => {
    const page = newPagination.current ?? 1
    const pageSize = newPagination.pageSize ?? 10

    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    })

    const offset = (page - 1) * pageSize
    const itemCount = listUsersQuery.data?.users?.users?.length ?? 0
    if (itemCount > offset) {
      console.log(`count is ${itemCount}, offset is ${offset}, skipping fetch`)
      return
    }

    console.log(`fetching more, page ${page}, offset ${offset}`)
    listUsersQuery.fetchMore({
      variables: { page: page - 1, pageSize },
    })
  }

  const currentPage = (pagination.current ?? 1) - 1
  const offset = currentPage * (pagination.pageSize ?? 10)

  const currentItems = _.take(
    _.drop(listUsersQuery.data?.users?.users ?? [], offset),
    pagination.pageSize
  )

  return (
    <Page>
      <Modal
        visible={!!userToDelete}
        centered
        title={`Benutzer ${userToDelete?.email} löschen`}
        cancelText="Abbrechen"
        okText="Benutzer löschen"
        okButtonProps={{
          disabled: !userToDelete || deleteEmailInput !== userToDelete?.email,
          loading: deletingUser,
          danger: true,
        }}
        onOk={() => onDelete()}
        onCancel={() => setUserToDelete(null)}
        cancelButtonProps={{
          disabled: !userToDelete || deletingUser,
        }}
      >
        Bitte geben Sie zum Bestätigen die E-Mail des Benutzers ein.
        <DeleteUserInput
          placeholder="E-Mail"
          autoFocus
          disabled={!userToDelete}
          value={deleteEmailInput}
          onChange={(ev) => setEmailDeleteInput(ev.target.value)}
          onPressEnter={() => onDelete()}
        />
      </Modal>

      <ListHeader>
        <PageHeader title="Benutzer" />

        <Actions>
          <Link to="/users/create">
            <Button type="primary" icon={<UserAddOutlined />}>
              Neuer Benutzer
            </Button>
          </Link>
        </Actions>
      </ListHeader>

      <Table
        dataSource={currentItems}
        pagination={pagination}
        loading={listUsersQuery.loading}
        rowKey={(row) => row.id}
        onChange={(pagination, filters, sorting) =>
          onTableChange(pagination, filters, sorting)
        }
        bordered
      >
        <Table.Column title="E-Mail" dataIndex="email" key={0} />

        <Table.Column key={1} title="Name" dataIndex="name" />

        <Table.Column
          key={2}
          title="Rolle"
          dataIndex="role"
          render={(role: Role) => renderRole(role)}
        />

        <Table.Column
          key={3}
          title="Erstellt"
          dataIndex="created"
          render={(created: string) => dayjs(created).fromNow()}
        />

        <Table.Column
          key={4}
          title="Bearbeitet"
          dataIndex="updated"
          render={(updated: string) => dayjs(updated).fromNow()}
        />

        <Table.Column
          key={5}
          title="Aktionen"
          width={105}
          fixed="right"
          render={(user: ListUsersQuery_users_users) => {
            const isSelf = currentUser?.id === user.id

            return (
              <ListActions>
                <Tooltip title={isSelf ? "Profil bearbeiten" : "Bearbeiten"}>
                  <Link to={isSelf ? "/profile" : `/users/${user.id}/edit`}>
                    <Button
                      icon={isSelf ? <SettingOutlined /> : <EditOutlined />}
                      type={isSelf ? "primary" : "default"}
                    />
                  </Link>
                </Tooltip>

                <Tooltip title="Löschen">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    disabled={isSelf}
                    onClick={() => showDeleteModal(user)}
                  />
                </Tooltip>
              </ListActions>
            )
          }}
        />
      </Table>
    </Page>
  )
}

const DeleteUserInput = styled(Input)`
  margin-top: 1rem;
`

export default ListUsers

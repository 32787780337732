import { gql, useMutation, useQuery } from "@apollo/client"
import { GET_PROJECT_QUERY, ProjectFragments } from "../projects/CreateProject"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "../projects/types/GetProjectQuery"
import { ListProjectsQuery_projects_projects } from "../types/ListProjectsQuery"
import { LIST_PROJECTS_QUERY } from "../util/queries"
import {
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
} from "./types/CreateCategoryMutation"
import {
  CreateDownloadableMutation,
  CreateDownloadableMutationVariables,
} from "./types/CreateDownloadableMutation"
import {
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from "./types/DeleteCategoryMutation"
import {
  DeleteDownloadableMutation,
  DeleteDownloadableMutationVariables,
} from "./types/DeleteDownloadableMutation"
import {
  ListDownloadablesQuery,
  ListDownloadablesQueryVariables,
} from "./types/ListDownloadablesQuery"
import {
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
} from "./types/UpdateCategoryMutation"
import {
  UpdateDownloadableMutation,
  UpdateDownloadableMutationVariables,
} from "./types/UpdateDownloadableMutation"
import {
  UpdateProjectDefaultIconMutation,
  UpdateProjectDefaultIconMutationVariables,
} from "./types/UpdateProjectDefaultIconMutation"
import {
  ReorderDownloadablesMutation,
  ReorderDownloadablesMutationVariables,
} from "./types/ReorderDownloadablesMutation"
import {
  DeleteAllDownloadablesMutation,
  DeleteAllDownloadablesMutationVariables,
} from "./types/DeleteAllDownloadablesMutation"
import { message } from "antd"

const DownloadableFragments = {
  DownloadableFields: gql`
    fragment DownloadableFields on Downloadable {
      id
      name
      filename
      file
      categoryId
      previewImage
      description
      created
      updated
      version
    }
  `,
  CategoryFields: gql`
    fragment CategoryFields on DownloadableCategory {
      id
      name
      created
      updated
      version
    }
  `,
}

export const LIST_DOWNLOADABLES_QUERY = gql`
  query ListDownloadablesQuery($projectId: ID!) {
    downloadables(projectId: $projectId) {
      categories {
        category {
          ...CategoryFields
        }
        total
        downloadables {
          ...DownloadableFields
        }
      }
      totalDownloadables
      totalCategories
    }
  }
  ${DownloadableFragments.DownloadableFields}
  ${DownloadableFragments.CategoryFields}
`

const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateCategoryMutation($category: CreateDownloadableCategory!) {
    createDownloadableCategory(category: $category) {
      ...CategoryFields
    }
  }
  ${DownloadableFragments.CategoryFields}
`

const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategoryMutation(
    $id: ID!
    $version: Int!
    $changes: UpdateDownloadableCategory!
  ) {
    updateDownloadableCategory(id: $id, version: $version, changes: $changes) {
      ...CategoryFields
    }
  }
  ${DownloadableFragments.CategoryFields}
`

const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategoryMutation($id: ID!, $version: Int!) {
    deleteDownloadableCategory(id: $id, version: $version) {
      id
    }
  }
`

const CREATE_DOWNLOADABLE_MUTATION = gql`
  mutation CreateDownloadableMutation($downloadable: CreateDownloadable!) {
    createDownloadable(downloadable: $downloadable) {
      ...DownloadableFields
    }
  }
  ${DownloadableFragments.DownloadableFields}
`

const UPDATE_DOWNLOADABLE_MUTATION = gql`
  mutation UpdateDownloadableMutation(
    $id: ID!
    $version: Int!
    $changes: UpdateDownloadable!
  ) {
    updateDownloadable(id: $id, version: $version, changes: $changes) {
      ...DownloadableFields
    }
  }
  ${DownloadableFragments.DownloadableFields}
`

const DELETE_DOWNLOADABLE_MUTATION = gql`
  mutation DeleteDownloadableMutation($id: ID!, $version: Int!) {
    deleteDownloadable(id: $id, version: $version) {
      ...DownloadableFields
    }
  }
  ${DownloadableFragments.DownloadableFields}
`

const DELETE_ALL_CATEGORIES_MUTATION = gql`
  mutation DeleteAllDownloadablesMutation($projectID: ID!) {
    deleteAllCategories(projectID: $projectID)
  }
`

const UPDATE_PROJECT_DEFAULT_ICON_MUTATION = gql`
  mutation UpdateProjectDefaultIconMutation(
    $id: ID!
    $version: Int!
    $changes: UpdateProject!
  ) {
    updateProject(id: $id, version: $version, changes: $changes) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

const REORDER_DOWNLOADABLES_MUTATION = gql`
  mutation ReorderDownloadablesMutation(
    $projectId: ID!
    $version: Int!
    $order: [DownloadableOrder!]!
  ) {
    reorderDownloadables(
      projectId: $projectId
      version: $version
      order: $order
    )
  }
`

export const useDeleteAllDownloadablesMutation = (
  projectID: string,
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [deleteCategories, { loading: deletingCategories }] = useMutation<
    DeleteAllDownloadablesMutation,
    DeleteAllDownloadablesMutationVariables
  >(DELETE_ALL_CATEGORIES_MUTATION, {
    variables: { projectID },
    onError: () =>
      message.error(
        "Downloadables konnten nicht gelöscht werden. Bitte probieren Sie es erneut oder löschen Sie die Downloadables einzeln."
      ),
    onCompleted: () => message.success("Downloadables erfolgreich gelöscht."),
    refetchQueries: [
      {
        query: LIST_DOWNLOADABLES_QUERY,
        variables: { projectId: activeProject?.id ?? "" },
      },
    ],
  })

  return { deleteCategories, deletingCategories }
}

export const useReorderDownloadablesMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [reorder, { loading: reordering }] = useMutation<
    ReorderDownloadablesMutation,
    ReorderDownloadablesMutationVariables
  >(REORDER_DOWNLOADABLES_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GET_PROJECT_QUERY,
        variables: { id: activeProject?.id ?? "" },
      },
    ],
  })

  return { reorder, reordering }
}

export const useListDownloadablesQuery = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const { data: dlbls, loading: loadingDlbls } = useQuery<
    ListDownloadablesQuery,
    ListDownloadablesQueryVariables
  >(LIST_DOWNLOADABLES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { projectId: activeProject?.id ?? "" },
    skip: !activeProject,
  })

  return { dlbls, loadingDlbls }
}

export const useUpdateCategoryMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [updateCategory, { loading: updatingCategory }] = useMutation<
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables
  >(UPDATE_CATEGORY_MUTATION, {
    update: (store, { data }) => {
      let cached: ListDownloadablesQuery | null = null
      try {
        cached = store.readQuery<
          ListDownloadablesQuery,
          ListDownloadablesQueryVariables
        >({
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
        })
      } catch (err) {
        return
      }

      const updatedCategories = cached!!.downloadables.categories.map(it =>
        it.category.id !== data!!.updateDownloadableCategory.id
          ? it
          : {
              ...it,
              category: data!!.updateDownloadableCategory,
            }
      )

      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
          data: {
            ...cached,
            downloadables: {
              ...cached!!.downloadables,
              categories: updatedCategories,
            },
          },
        }
      )
    },
  })

  return { updateCategory, updatingCategory }
}

export const useCreateCategoryMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [createCategory, { loading: creatingCategory }] = useMutation<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >(CREATE_CATEGORY_MUTATION, {
    notifyOnNetworkStatusChange: true,
    update: (store, { data }) => {
      let cached: ListDownloadablesQuery | null = null
      try {
        cached = store.readQuery<
          ListDownloadablesQuery,
          ListDownloadablesQueryVariables
        >({
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
        })
      } catch (err) {
        cached = {
          downloadables: {
            __typename: "DownloadableList",
            totalCategories: 0,
            totalDownloadables: 0,
            categories: [],
          },
        }
      }

      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
          data: {
            ...cached,
            downloadables: {
              ...cached!!.downloadables,
              totalCategories: cached!!.downloadables.totalCategories + 1,
              categories: [
                {
                  __typename: "CategoryWithDownloadables",
                  category: data!!.createDownloadableCategory,
                  downloadables: [],
                  total: 0,
                },
                ...cached!!.downloadables.categories,
              ],
            },
          },
        }
      )
    },
  })

  return { createCategory, creatingCategory }
}

export const useDeleteCategoryMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [deleteCategory, { loading: deletingCategory }] = useMutation<
    DeleteCategoryMutation,
    DeleteCategoryMutationVariables
  >(DELETE_CATEGORY_MUTATION, {
    update: (store, { data }) => {
      let cached: ListDownloadablesQuery | null = null
      try {
        cached = store.readQuery<
          ListDownloadablesQuery,
          ListDownloadablesQueryVariables
        >({
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
        })
      } catch (err) {
        return
      }

      const updatedCategories = cached!!.downloadables.categories.filter(
        it => it.category.id !== data!!.deleteDownloadableCategory.id
      )

      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
          data: {
            ...cached,
            downloadables: {
              ...cached!!.downloadables,
              categories: updatedCategories,
            },
          },
        }
      )
    },
  })

  return { deleteCategory, deletingCategory }
}

export const useCreateDownloadableMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [createDownloadable, { loading: creatingDownloadable }] = useMutation<
    CreateDownloadableMutation,
    CreateDownloadableMutationVariables
  >(CREATE_DOWNLOADABLE_MUTATION, {
    update: (store, { data }) => {
      let cached: ListDownloadablesQuery | null = null
      try {
        cached = store.readQuery<
          ListDownloadablesQuery,
          ListDownloadablesQueryVariables
        >({
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
        })
      } catch (err) {
        return
      }

      const updatedCategories = cached!!.downloadables.categories.map(it =>
        it.category.id !== data!!.createDownloadable.categoryId
          ? it
          : {
              ...it,
              total: it.total + 1,
              downloadables: [...it.downloadables, data!!.createDownloadable],
            }
      )

      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
          data: {
            ...cached,
            downloadables: {
              ...cached!!.downloadables,
              totalDownloadables: cached!!.downloadables.totalDownloadables + 1,
              categories: updatedCategories,
            },
          },
        }
      )
    },
  })

  return { createDownloadable, creatingDownloadable }
}

export const useDeleteDownloadableMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [deleteDownloadable, { loading: deletingDownloadable }] = useMutation<
    DeleteDownloadableMutation,
    DeleteDownloadableMutationVariables
  >(DELETE_DOWNLOADABLE_MUTATION, {
    update: (store, { data }) => {
      let cached: ListDownloadablesQuery | null = null
      try {
        cached = store.readQuery<
          ListDownloadablesQuery,
          ListDownloadablesQueryVariables
        >({
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
        })
      } catch (err) {
        return
      }

      const updatedCategories = cached!!.downloadables.categories.map(it =>
        it.category.id !== data!!.deleteDownloadable.categoryId
          ? it
          : {
              ...it,
              total: it.total - 1,
              downloadables: it.downloadables.filter(
                it => it.id !== data!!.deleteDownloadable.id
              ),
            }
      )

      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
          data: {
            ...cached,
            downloadables: {
              ...cached!!.downloadables,
              totalDownloadables: cached!!.downloadables.totalDownloadables - 1,
              categories: updatedCategories,
            },
          },
        }
      )
    },
  })

  return { deleteDownloadable, deletingDownloadable }
}

export const useUpdateDownloadableMutation = (
  activeProject: ListProjectsQuery_projects_projects | null
) => {
  const [updateDownloadable, { loading: updatingDownloadable }] = useMutation<
    UpdateDownloadableMutation,
    UpdateDownloadableMutationVariables
  >(UPDATE_DOWNLOADABLE_MUTATION, {
    update: (store, { data }) => {
      let cached: ListDownloadablesQuery | null = null
      try {
        cached = store.readQuery<
          ListDownloadablesQuery,
          ListDownloadablesQueryVariables
        >({
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
        })
      } catch (err) {
        return
      }

      const updatedCategories = cached!!.downloadables.categories.map(cat =>
        cat.category.id !== data!!.updateDownloadable.categoryId
          ? cat
          : {
              ...cat,
              downloadables: cat.downloadables.map(dl =>
                dl.id !== data!!.updateDownloadable.id
                  ? dl
                  : data!!.updateDownloadable
              ),
            }
      )

      store.writeQuery<ListDownloadablesQuery, ListDownloadablesQueryVariables>(
        {
          query: LIST_DOWNLOADABLES_QUERY,
          variables: { projectId: activeProject?.id ?? "" },
          data: {
            ...cached,
            downloadables: {
              ...cached!!.downloadables,
              categories: updatedCategories,
            },
          },
        }
      )
    },
  })

  return { updateDownloadable, updatingDownloadable }
}

export const useUpdateProjectDefaultIconMutation = () => {
  const [
    updateProjectDefaultIcon,
    { loading: updatingProjectDefaultIcon },
  ] = useMutation<
    UpdateProjectDefaultIconMutation,
    UpdateProjectDefaultIconMutationVariables
  >(UPDATE_PROJECT_DEFAULT_ICON_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    update: (store, { data }) => {
      store.writeQuery<GetProjectQuery, GetProjectQueryVariables>({
        query: GET_PROJECT_QUERY,
        data: { project: { ...data!!.updateProject } },
      })
    },
  })

  return { updateProjectDefaultIcon, updatingProjectDefaultIcon }
}

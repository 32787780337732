import React from "react"
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom"

import ListUsers from "./ListUsers"
import CreateUser from "./CreateUser"

const Users = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <CreateUser />
      </Route>

      <Route path={`${match.path}/:id/edit`}>
        <CreateUser />
      </Route>

      <Route path={match.path} exact>
        <ListUsers />
      </Route>

      <Route path={`${match.path}/*`}>
        <Redirect to="/users" />
      </Route>
    </Switch>
  )
}

export default Users

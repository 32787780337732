/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum IPointDestinationType {
  HOME = "HOME",
  MAIN = "MAIN",
  SUB = "SUB",
}

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface CreateDownloadable {
  projectId: string
  categoryId: string
  name: string
  filename: string
  previewImage?: string | null
  description: string
  file: string
}

export interface CreateDownloadableCategory {
  name: string
  projectId: string
}

export interface CreateProject {
  name: string
  slug: string
  customValues: any
  logo: string
  logoAlignment: string
  subLogo?: string | null
  showSubLogo: boolean
  subLogoAlignment?: string | null
  analyticsKey?: string | null
  mailSenderEmail?: string | null
  mailSenderName?: string | null
  mailTemplates: CreateProjectMailTemplates
  start?: any | null
  end?: any | null
  accessStart?: any | null
  accessEnd?: any | null
}

export interface CreateProjectMailTemplates {
  basketCheckoutTemplateId?: string | null
  basketCheckoutSubject?: string | null
  touchlessBundleTemplateId?: string | null
  touchlessBundleSubject?: string | null
}

export interface CreateTouchlessInput {
  projectID: string
  name: string
  slug: string
  qrPage: TouchlessContentInput
  start: TouchlessContentInput
  bundle: string
}

export interface CreateUser {
  email: string
  name: string
  role: Role
  projects: string[]
}

export interface DownloadableOrder {
  categoryId: string
  downloadables: string[]
}

export interface TouchlessContentInput {
  contentType: string
  image?: string | null
  video?: string | null
}

export interface TouchlessPageInput {
  name: string
  content: TouchlessContentInput[]
}

export interface UpdateDownloadable {
  categoryId?: string | null
  name?: string | null
  filename?: string | null
  previewImage?: string | null
  description?: string | null
  file?: string | null
}

export interface UpdateDownloadableCategory {
  name?: string | null
}

export interface UpdateIPointMainpage {
  id: string
  name: string
  fachinfo: string
  style: UpdateIPointMainpageStyle
  items: UpdateIPointMainpageItem[]
}

export interface UpdateIPointMainpageItem {
  id: string
  title: string
  document: string
  downloadables: string[]
  urls: UpdateIPointMainpageItemUrl[]
  videos: UpdateIPointMainpageItemVideo[]
}

export interface UpdateIPointMainpageItemUrl {
  url: string
  page: number
}

export interface UpdateIPointMainpageItemVideo {
  file: string
  page: number
}

export interface UpdateIPointMainpageStyle {
  img_bar_top: string
  img_bar_bottom: string
  img_bar_tab: string
  icon_home: string
  icon_back: string
  icon_basket: string
  icon_basket_add: string
  icon_basket_count_bg: string
  icon_page_bg_active: string
  icon_page_bg_inactive: string
  font_type_title: string
  font_type_tabbar: string
  font_type_pages: string
  font_type_basket_count: string
  color_title: string
  color_tabbar_active: string
  color_tabbar_inactive: string
  color_pages_active: string
  color_pages_inactive: string
  color_basket_count: string
  color_arrows: string
  tabbar_alignment: string
  tabbar_margin: number
  tabbar_padding: number
  title_offset: number
  show_page_buttons: boolean
}

export interface UpdateIPointMenupage {
  page_id: string
  page_name: string
  bg_img: string
  destination_id: string
  menu_items: UpdateIPointMenupageMenuitem[]
}

export interface UpdateIPointMenupageMenuitem {
  name: string
  image: string
  pos: number
  destination_id: string
}

export interface UpdateIPointStartpage {
  view_type: string
  bg_img: string
  home_img: string
  style: UpdateIPointStartpageStyle
  main_page?: UpdateIPointMenupage | null
}

export interface UpdateIPointStartpageStyle {
  icon_basket: string
  icon_basket_count_bg: string
  font_type_basket_count: string
  color_basket_count: string
}

export interface UpdateInstance {
  clientName?: string | null
  customValues?: any | null
  logo?: string | null
  strings?: UpdateInstanceStrings | null
  mailSenderEmail?: string | null
  mailSenderName?: string | null
  mailTemplates?: UpdateInstanceMailTemplates | null
}

export interface UpdateInstanceMailTemplates {
  activationTemplateId?: string | null
  activationSubject?: string | null
  activationSuccessTemplateId?: string | null
  activationSuccessSubject?: string | null
  passwordResetTemplateId?: string | null
  passwordResetSubject?: string | null
  passwordResetSuccessTemplateId?: string | null
  passwordResetSuccessSubject?: string | null
  basketCheckoutTemplateId?: string | null
  basketCheckoutSubject?: string | null
  touchlessBundleTemplateId?: string | null
  touchlessBundleSubject?: string | null
}

export interface UpdateInstanceStrings {
  imprint?: string | null
  dataPrivacyNotice?: string | null
  dark: boolean
}

export interface UpdateProject {
  name?: string | null
  slug?: string | null
  customValues?: any | null
  logo?: string | null
  logoAlignment?: string | null
  subLogo?: string | null
  showSubLogo?: boolean | null
  subLogoAlignment?: string | null
  start?: any | null
  end?: any | null
  accessStart?: any | null
  accessEnd?: any | null
  analyticsKey?: string | null
  mailSenderEmail?: string | null
  mailSenderName?: string | null
  mailTemplates?: CreateProjectMailTemplates | null
  defaultDownloadableIcon?: string | null
}

export interface UpdateTouchlessInput {
  id: string
  name: string
  slug: string
  qrPage: TouchlessContentInput
  start: TouchlessContentInput
  bundle: string
  showBundleButton: boolean
  timeout?: number | null
  closeButtonText?: string | null
  swipeHintText?: string | null
  emailInfoText?: string | null
  emailPlaceholderText?: string | null
  emailHintText?: string | null
  emailSendButtonText?: string | null
  connectionClosedText?: string | null
  emailSentText?: string | null
  backgroundColor?: string | null
  headingColor?: string | null
  textColor?: string | null
  hintColor?: string | null
  closeButtonColor?: string | null
  closeButtonTextColor?: string | null
  menuButtonColor?: string | null
  menuButtonTextColor?: string | null
  bundleButtonColor?: string | null
  bundleButtonTextColor?: string | null
  backButtonColor?: string | null
  swipeAreaColor?: string | null
  swipeIconColor?: string | null
  emailSendButtonColor?: string | null
  emailSendButtonTextColor?: string | null
  emailSentTextColor?: string | null
  headingFontFamily?: string | null
  headingFontSize?: number | null
  textFontFamily?: string | null
  textFontSize?: number | null
}

export interface UpdateTouchlessPagesInput {
  id: string
  pages: TouchlessPageInput[]
}

export interface UpdateUser {
  email?: string | null
  name?: string | null
  role?: Role | null
  disabled?: boolean | null
  projects?: string[] | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================

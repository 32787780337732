import React, { useContext, useState } from "react"
import { Page, ListHeader, CardSection } from "../util/page"
import {
  PageHeader,
  Button,
  message,
  Row,
  Col,
  Switch,
  Input,
  Divider,
  Radio,
} from "antd"
import { useHistory, useParams, Redirect } from "react-router"
import { ProjectContext } from "../App"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "./types/GetProjectQuery"
import { GET_PROJECT_QUERY, ProjectFragments } from "./CreateProject"
import ImageUpload from "../components/ImageUpload"
import { BackgroundColorElement } from "./UpdateBasket"
import { CustomColorSelect } from "@g51/hubi-components/components/EditableBar"

import { Element as BasketWelcomeElement } from "@g51/hubi-components/templates/basket/Welcome"
import { Element as BasketViewBasketElement } from "@g51/hubi-components/templates/basket/ViewBasket"
import { Element as BasketCheckoutElement } from "@g51/hubi-components/templates/basket/Checkout"
import { Element as BasketCheckoutSuccessElement } from "@g51/hubi-components/templates/basket/CheckoutSuccess"

import { Element as WebCodeEntryElement } from "@g51/hubi-components/templates/web/CodeEntry"
import { Element as WebViewBasketElement } from "@g51/hubi-components/templates/web/ViewBasket"
import { CustomValues, Property } from "@g51/hubi-components"
import { LIST_PROJECTS_QUERY } from "../util/queries"
import {
  UpdateProjectCustomValuesMutationInitial,
  UpdateProjectCustomValuesMutationInitialVariables,
} from "./types/UpdateProjectCustomValuesMutationInitial"
import { ExplainIcon, Tooltip } from "../util/util"
import styled from "styled-components"
import HubiButton from "@g51/hubi-components/components/Button"
import HubiSwitch from "@g51/hubi-components/components/Switch"

interface Params {
  id?: string
}

const UPDATE_PROJECT_CUSTOM_VALUES_MUTATION = gql`
  mutation UpdateProjectCustomValuesMutationInitial(
    $id: ID!
    $version: Int!
    $changes: UpdateProject!
  ) {
    updateProject(id: $id, version: $version, changes: $changes) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

const clampBorderRadius = (v: string) => {
  try {
    const num = parseInt(v)
    return Number.isNaN(num) ? "" : Math.max(Math.min(num, 100), 0)
  } catch {
    return ""
  }
}

enum SetupValues {
  BackgroundImage,
  BackgroundColor,
  ShowContainer,
  ContainerColor,
  ContainerBackgroundImage,
  ContainerBorderRadius,
  ContainerHasShadow,

  PageAlign,

  PrimaryTextColor,
  SecondaryTextColor,

  PrimaryButtonColor,
  SecondaryButtonColor,

  DownloadableBackgroundColor,
  CategoryBackgroundColor,
  CheckboxForeground,
  CheckboxBackground,

  SetDefaultTexts,
}

const SetupProject = () => {
  const history = useHistory()
  const params = useParams<Params>()

  const { activeProject, setActiveProject } = useContext(ProjectContext)
  const { data, loading: loadingProject, error: getProjectError } = useQuery<
    GetProjectQuery,
    GetProjectQueryVariables
  >(GET_PROJECT_QUERY, {
    variables: { id: params.id!! },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      message.error("Das Projekt konnte nicht gefunden werden.")
    },
    onCompleted: (data) => {
      if (!data) return
      setActiveProject(data.project.id)
    },
  })

  const [updateProject] = useMutation<
    UpdateProjectCustomValuesMutationInitial,
    UpdateProjectCustomValuesMutationInitialVariables
  >(UPDATE_PROJECT_CUSTOM_VALUES_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    update: (store, { data }) => {
      store.writeQuery<GetProjectQuery, GetProjectQueryVariables>({
        query: GET_PROJECT_QUERY,
        data: { project: { ...data!!.updateProject } },
      })
    },
    onCompleted: () => {
      message.success("Projekt erfolgreich gespeichert.")
      history.push(`/projects/${params.id}/edit`)
    },
    onError: (err) => {
      if (err.networkError) {
        message.error("Beim Speichern ist ein Fehler aufgetreten.")
        return
      }

      message.error("Ein unbekannter Fehler ist aufgetreten.")
    },
  })

  const [values, setValues] = useState<{ [key: number]: any }>({})
  const setValue = (key: SetupValues, value: any) =>
    setValues({
      ...values,
      [key]: value,
    })

  const onSave = () => {
    if (!data?.project) return

    const backgroundElement = {
      [Property.BackgroundColor]: values[SetupValues.BackgroundColor] ?? null,
      [Property.Image]: values[SetupValues.BackgroundImage] ?? null,
    }

    const containerElement = {
      [Property.HasContainer]: values[SetupValues.ShowContainer] ?? false,
      [Property.HasShadow]: values[SetupValues.ContainerHasShadow] ?? false,
      [Property.BackgroundColor]: values[SetupValues.ContainerColor] ?? null,
      [Property.Image]: values[SetupValues.ContainerBackgroundImage] ?? null,
      [Property.BorderRadius]: values[SetupValues.ContainerBorderRadius] ?? 0,
    }

    const textElement = {
      [Property.PrimaryColor]: values[SetupValues.PrimaryTextColor] ?? null,
      [Property.SecondaryColor]: values[SetupValues.SecondaryTextColor] ?? null,
    }

    const pageAlign = {
      [Property.TextAlign]: values[SetupValues.PageAlign] || "center",
    }

    const buttonElement = {
      [Property.PrimaryColor]: values[SetupValues.PrimaryButtonColor] ?? null,
      [Property.BackgroundColor]:
        values[SetupValues.SecondaryButtonColor] ?? null,
    }

    const tableElement = {
      [Property.PrimaryColor]: values[SetupValues.PrimaryTextColor] ?? null,
      [Property.SecondaryColor]: values[SetupValues.SecondaryTextColor] ?? null,
      [Property.BackgroundColor]:
        values[SetupValues.DownloadableBackgroundColor] ?? null,
      [Property.TableColor]:
        values[SetupValues.CategoryBackgroundColor] ?? null,
      [Property.ActionColorPrimary]:
        values[SetupValues.CheckboxBackground] ?? null,
      [Property.ActionColorSecondary]:
        values[SetupValues.CheckboxForeground] ?? null,
    }

    const basketWelcome = {
      "basket.welcome/align": { ...pageAlign },
      [BasketWelcomeElement.Background]: { ...backgroundElement },
      [BasketWelcomeElement.Container]: { ...containerElement },
      [BasketWelcomeElement.Intro]: { ...textElement },
    }

    const basketViewBasket = {
      "basket.view-basket/align": { ...pageAlign },
      [BasketViewBasketElement.Background]: { ...backgroundElement },
      [BasketViewBasketElement.Container]: { ...containerElement },
      [BasketViewBasketElement.Intro]: { ...textElement },
      [BasketViewBasketElement.AddAllButton]: { ...buttonElement },
      [BasketViewBasketElement.CheckoutButton]: { ...buttonElement },
      [BasketViewBasketElement.DownloadablesList]: { ...tableElement },
    }

    const basketCheckout = {
      "basket.checkout/align": { ...pageAlign },
      [BasketCheckoutElement.Background]: { ...backgroundElement },
      [BasketCheckoutElement.Container]: { ...containerElement },
      [BasketCheckoutElement.Intro]: { ...textElement },
      [BasketCheckoutElement.TextBeforeSend]: { ...textElement },
      [BasketCheckoutElement.TextAfterSend]: { ...textElement },
      [BasketCheckoutElement.SendButton]: { ...buttonElement },
      [BasketCheckoutElement.EmailInput]: { ...buttonElement },
    }

    const basketCheckoutSuccess = {
      "basket.checkout-success/align": { ...pageAlign },
      [BasketCheckoutSuccessElement.Background]: { ...backgroundElement },
      [BasketCheckoutSuccessElement.Container]: { ...containerElement },
      [BasketCheckoutSuccessElement.Intro]: { ...textElement },
    }

    const webCodeEntry = {
      "web.code-entry/align": { ...pageAlign },
      [WebCodeEntryElement.Background]: { ...backgroundElement },
      [WebCodeEntryElement.Container]: { ...containerElement },
      [WebCodeEntryElement.Intro]: { ...textElement },
      [WebCodeEntryElement.CodeEntryHeading]: { ...textElement },
    }

    const webViewBasket = {
      "web.view-basket/align": { ...pageAlign },
      [WebViewBasketElement.Background]: { ...backgroundElement },
      [WebViewBasketElement.Container]: { ...containerElement },
      [WebViewBasketElement.Intro]: { ...textElement },
      [WebViewBasketElement.LogoutButton]: { ...buttonElement },
      [WebViewBasketElement.DownloadAllButton]: { ...buttonElement },
      [WebViewBasketElement.DownloadablesList]: { ...tableElement },
    }

    const customValues: any = {
      ...basketWelcome,
      ...basketViewBasket,
      ...basketCheckout,
      ...basketCheckoutSuccess,
      ...webCodeEntry,
      ...webViewBasket,
    }

    if (data?.project.customValues["font-heading"])
      customValues["font-heading"] = data?.project.customValues["font-heading"]

    if (data?.project.customValues["font-body"])
      customValues["font-body"] = data?.project.customValues["font-body"]

    updateProject({
      variables: {
        id: data.project.id,
        version: data.project.version,
        changes: { customValues },
      },
    })
  }

  const bgColor = (values: CustomValues) =>
    values[SetupValues.ShowContainer]
      ? values[SetupValues.ContainerColor]
      : values[SetupValues.BackgroundColor]

  if (activeProject && activeProject.id !== params.id)
    return <Redirect to={`/projects/${activeProject.id}/edit/setup`} />

  return (
    <Page>
      <ListHeader>
        <PageHeader
          title={`Initialer Projekt-Setup für ${data?.project?.name ?? `...`}`}
        />
      </ListHeader>

      <div
        style={{
          width: "860px",
          marginBottom: "2rem",
          maxWidth: "100%",
          opacity: 0.8,
        }}
      >
        Auf dieser Seite können Grundeinstellungen für Basket und Website
        vorgenommen werden, die dann für alle Seiten übernommen werden. Jede
        einzelne Seite kann danach über die "Basket" sowie die "Website"
        Funktion im Projektmenü angepasst werden.
        <br />
        <br />
        Bei Bedarf kann dieser Setup erneut ausgeführt werden, allerdings werden
        dabei bestehende angepasste Einstellungen überschrieben.
      </div>

      <CardSection title="Hintergründe" style={{ marginBottom: "2rem" }}>
        <div style={{ padding: 1, marginBottom: 50 }}>
          <Row gutter={16} style={{ marginBottom: "1rem" }}>
            <Col span={12}>
              <div style={{ marginBottom: "2rem" }}>
                <div style={{ marginBottom: "0.5rem" }}>
                  Seiten-Ausrichtung{" "}
                  <ExplainIcon text="Diese Option bestimmt die Ausrichtung des Seiteninhalts." />
                </div>

                <Radio.Group
                  value={values[SetupValues.PageAlign] || "center"}
                  onChange={(s) =>
                    setValue(SetupValues.PageAlign, s.target.value || "center")
                  }
                >
                  <Radio.Button value="left">Links</Radio.Button>
                  <Radio.Button value="center">Mitte</Radio.Button>
                  <Radio.Button value="right">Rechts</Radio.Button>
                </Radio.Group>
              </div>

              <Tooltip text="Dieses Bild wird als Hintergrund, und so skaliert dass es bei jeder Auflösung den kompletten Hintergrund bedeckt. Die optimale Auflösung ist hier 1920x1080 bzw. die Auflösung des für den Basket verwendeten iPads.">
                <ImageUpload
                  text="Hintergrund"
                  style={{ width: "100%" }}
                  value={values[SetupValues.BackgroundImage]}
                  onChange={(s) => setValue(SetupValues.BackgroundImage, s)}
                />
              </Tooltip>
            </Col>

            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                Hintergrundfarbe{" "}
                <ExplainIcon text="Diese Farbe wird als Hintergrundfarbe für den gesamten Basket/Website genutzt. Wird ein Hintergrundbild ausgewählt, liegt es über dieser Farbe." />
              </div>
              <BackgroundColorElement
                bgColor={values[SetupValues.BackgroundColor]}
              >
                <CustomColorSelect
                  defaultColor="#fff"
                  color={values[SetupValues.BackgroundColor]}
                  onChange={(c) => setValue(SetupValues.BackgroundColor, c)}
                />
              </BackgroundColorElement>
            </Col>
          </Row>

          <Divider />

          <Row gutter={16}>
            <Col span={24}>
              <Switch
                style={{ marginRight: "1rem" }}
                checked={values[SetupValues.ShowContainer]}
                onChange={(v) => setValue(SetupValues.ShowContainer, v)}
              />
              Container anzeigen{" "}
              <ExplainIcon text="Diese Option aktiviert eine zusätzliche Hintergrundebene, der optional ein Schatten sowie ein eigenes Hintergrundbild gegeben werden kann." />
            </Col>
          </Row>

          {values[SetupValues.ShowContainer] && (
            <Row gutter={16} style={{ marginTop: "2rem", minHeight: 280 }}>
              <Col span={12}>
                <Tooltip text="Dieses Bild wird als Hintergrund für den Container genommen, und an den Rändern so abgeschnitten dass es immer den gesamten Container bedeckt.">
                  <ImageUpload
                    text="Container-Hintergrund"
                    style={{ width: "100%" }}
                    value={values[SetupValues.ContainerBackgroundImage]}
                    onChange={(s) =>
                      setValue(SetupValues.ContainerBackgroundImage, s)
                    }
                  />
                </Tooltip>
              </Col>

              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row gutter={16} style={{ marginBottom: "1rem" }}>
                  <Col span={12}>
                    Gerundete Ecken{" "}
                    <ExplainIcon text="Die Ecken des Containers lassen sich stufenweise abrunden, indem hier in Pixeln ein Radius eingegeben wird. Bei 0 sind die Ecken rechtwinklig." />
                    <Input
                      style={{ width: "100%" }}
                      type="number"
                      placeholder="Rundung in Pixel"
                      value={values[SetupValues.ContainerBorderRadius]}
                      onChange={(v) =>
                        setValue(
                          SetupValues.ContainerBorderRadius,
                          clampBorderRadius(v.target.value)
                        )
                      }
                    />
                  </Col>

                  <Col span={12}>
                    Schatten anzeigen{" "}
                    <ExplainIcon text="Diese Option fügt den Container einen Schatten hinzu, der ihn räumlich vom Hintergrund abhebt." />{" "}
                    <br />
                    <Switch
                      style={{ marginTop: "4px" }}
                      checked={values[SetupValues.ContainerHasShadow]}
                      onChange={(c) =>
                        setValue(SetupValues.ContainerHasShadow, c)
                      }
                    />
                  </Col>
                </Row>
                Containerfarbe{" "}
                <ExplainIcon text="Analog zur Hintergrundfarbe des Baskets/Website bestimmt diese Option die Hintergrundfarbe des Containers." />
                <BackgroundColorElement
                  bgColor={values[SetupValues.ContainerColor]}
                >
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.ContainerColor]}
                    onChange={(c) => setValue(SetupValues.ContainerColor, c)}
                  />
                </BackgroundColorElement>
              </Col>
            </Row>
          )}
        </div>
      </CardSection>

      <CardSection
        title="Farben für Texte und Buttons"
        style={{ marginBottom: "2rem" }}
      >
        <div style={{ padding: 1, marginBottom: 50 }}>
          <Row gutter={16} style={{ height: "220px", marginBottom: "4rem" }}>
            <Col span={12} style={{ height: "100%" }}>
              Textfarbe für Überschriften
              <BackgroundColorElement
                bgColor={bgColor(values)}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.PrimaryTextColor]}
                    onChange={(c) => setValue(SetupValues.PrimaryTextColor, c)}
                  />
                </ColorSelectContainer>

                <InnerColorContainer
                  style={{ color: values[SetupValues.PrimaryTextColor] }}
                >
                  <h3>Kleine Überschrift</h3>
                  <br />
                  <h2>Mittlere Überschrift</h2>
                  <br />
                  <h1>Große Überschrift</h1>
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>

            <Col span={12} style={{ height: "100%" }}>
              Textfarbe für normalen Text
              <BackgroundColorElement
                bgColor={bgColor(values)}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.SecondaryTextColor]}
                    onChange={(c) =>
                      setValue(SetupValues.SecondaryTextColor, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer
                  style={{
                    justifyContent: "flex-start",
                    color: values[SetupValues.SecondaryTextColor],
                  }}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Nec ultrices dui sapien eget mi proin sed libero.
                    Aenean vel elit scelerisque mauris. Arcu ac tortor dignissim
                    convallis aenean et tortor. Lectus sit amet est placerat in
                    egestas erat. Sollicitudin nibh sit amet commodo nulla
                    facilisi nullam. Aliquam vestibulum morbi blandit cursus
                    risus at. Tellus integer feugiat scelerisque varius morbi
                    enim nunc faucibus. Etiam sit amet nisl purus in mollis nunc
                    sed id. Volutpat lacus laoreet non curabitur gravida arcu ac
                    tortor dignissim. Fusce ut placerat orci nulla pellentesque
                    dignissim. In metus vulputate eu scelerisque felis. Laoreet
                    sit amet cursus sit. Volutpat sed cras ornare arcu dui
                    vivamus arcu felis bibendum. Id aliquet risus feugiat in
                    ante metus. Ultrices tincidunt arcu non sodales neque
                    sodales. Elementum sagittis vitae et leo duis ut diam. Enim
                    nec dui nunc mattis enim ut tellus elementum. Auctor eu
                    augue ut lectus.
                  </p>
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>
          </Row>

          <Divider />

          <Row gutter={16} style={{ height: "220px", marginBottom: "1rem" }}>
            <Col span={12} style={{ height: "100%" }}>
              Text- und Rahmenfarbe für Buttons
              <BackgroundColorElement
                bgColor={bgColor(values)}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.PrimaryButtonColor]}
                    onChange={(c) =>
                      setValue(SetupValues.PrimaryButtonColor, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer style={{ pointerEvents: "none" }}>
                  <HubiButton
                    style={{ marginBottom: 10 }}
                    primaryColor={values[SetupValues.PrimaryButtonColor]}
                    backgroundColor={values[SetupValues.SecondaryButtonColor]}
                    value="Basket ansehen"
                  />

                  <HubiButton
                    style={{ marginBottom: 10 }}
                    primaryColor={values[SetupValues.PrimaryButtonColor]}
                    backgroundColor={values[SetupValues.SecondaryButtonColor]}
                    value="Ausloggen"
                  />

                  <HubiButton
                    primaryColor={values[SetupValues.PrimaryButtonColor]}
                    backgroundColor={values[SetupValues.SecondaryButtonColor]}
                    value="Senden"
                  />
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>

            <Col span={12} style={{ height: "100%" }}>
              Hintergrundfarbe für Buttons
              <BackgroundColorElement
                bgColor={bgColor(values)}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.SecondaryButtonColor]}
                    onChange={(c) =>
                      setValue(SetupValues.SecondaryButtonColor, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer style={{ pointerEvents: "none" }}>
                  <HubiButton
                    style={{ marginBottom: 10 }}
                    primaryColor={values[SetupValues.PrimaryButtonColor]}
                    backgroundColor={values[SetupValues.SecondaryButtonColor]}
                    value="Hinzufügen"
                  />

                  <HubiButton
                    style={{ marginBottom: 10 }}
                    primaryColor={values[SetupValues.PrimaryButtonColor]}
                    backgroundColor={values[SetupValues.SecondaryButtonColor]}
                    value="E-Mail senden"
                  />

                  <HubiButton
                    primaryColor={values[SetupValues.PrimaryButtonColor]}
                    backgroundColor={values[SetupValues.SecondaryButtonColor]}
                    value="Downloadable entfernen"
                  />
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>
          </Row>
        </div>
      </CardSection>

      <CardSection
        title="Farben für Downloadable-Liste"
        style={{ marginBottom: "2rem" }}
      >
        <div style={{ padding: 1, marginBottom: 50 }}>
          <Row gutter={16} style={{ height: "220px", marginBottom: "4rem" }}>
            <Col span={12} style={{ height: "100%" }}>
              Hintergrundfarbe für Kategorie-Überschriften
              <BackgroundColorElement
                bgColor={values[SetupValues.BackgroundColor]}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.CategoryBackgroundColor]}
                    onChange={(c) =>
                      setValue(SetupValues.CategoryBackgroundColor, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer style={{ padding: 1 }}>
                  <ListDemo
                    headerBgColor={values[SetupValues.CategoryBackgroundColor]}
                    itemBgColor={
                      values[SetupValues.DownloadableBackgroundColor]
                    }
                    headerTextColor={values[SetupValues.PrimaryTextColor]}
                    itemTextColor={values[SetupValues.SecondaryTextColor]}
                  >
                    <div className="list-container">
                      <div className="cat-header">Kategorie</div>

                      {[
                        "Downloadable",
                        "Downloadable",
                        "Downloadable",
                        "Downloadable",
                        "Downloadable",
                      ].map((it, idx) => (
                        <div className="dl-item" key={idx}>
                          {it}
                        </div>
                      ))}
                    </div>
                  </ListDemo>
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>

            <Col span={12} style={{ height: "100%" }}>
              Hintergrundfarbe für Downloadables
              <BackgroundColorElement
                bgColor={values[SetupValues.BackgroundColor]}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.DownloadableBackgroundColor]}
                    onChange={(c) =>
                      setValue(SetupValues.DownloadableBackgroundColor, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer style={{ padding: 1 }}>
                  <ListDemo
                    headerBgColor={values[SetupValues.CategoryBackgroundColor]}
                    itemBgColor={
                      values[SetupValues.DownloadableBackgroundColor]
                    }
                    headerTextColor={values[SetupValues.PrimaryTextColor]}
                    itemTextColor={values[SetupValues.SecondaryTextColor]}
                  >
                    <div className="list-container">
                      <div className="cat-header">Kategorie</div>

                      {[
                        "Downloadable",
                        "Downloadable",
                        "Downloadable",
                        "Downloadable",
                        "Downloadable",
                      ].map((it, idx) => (
                        <div className="dl-item" key={idx}>
                          {it}
                        </div>
                      ))}
                    </div>
                  </ListDemo>
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>
          </Row>

          <Divider />

          <Row gutter={16} style={{ height: "220px", marginBottom: "1rem" }}>
            <Col span={12} style={{ height: "100%" }}>
              Vordergrundfarbe für Checkboxen
              <BackgroundColorElement
                bgColor={values[SetupValues.BackgroundColor]}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.CheckboxForeground]}
                    onChange={(c) =>
                      setValue(SetupValues.CheckboxForeground, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <HubiSwitch
                      id="switch-bg-demo-loading"
                      primaryColor={values[SetupValues.CheckboxBackground]}
                      secondaryColor={values[SetupValues.CheckboxForeground]}
                      loading
                    />
                    <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                      Checkbox (Laden)
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <HubiSwitch
                      id="switch-bg-demo-unchecked"
                      primaryColor={values[SetupValues.CheckboxBackground]}
                      secondaryColor={values[SetupValues.CheckboxForeground]}
                    />
                    <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                      Unselektierte Checkbox
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <HubiSwitch
                      id="switch-bg-demo-checked"
                      primaryColor={values[SetupValues.CheckboxBackground]}
                      secondaryColor={values[SetupValues.CheckboxForeground]}
                      checked
                    />
                    <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                      Selektierte Checkbox
                    </span>
                  </div>
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>

            <Col span={12} style={{ height: "100%" }}>
              Hintergrundfarbe für Checkboxen
              <BackgroundColorElement
                bgColor={values[SetupValues.BackgroundColor]}
                style={{ height: "100%" }}
              >
                <ColorSelectContainer>
                  <CustomColorSelect
                    defaultColor="#fff"
                    color={values[SetupValues.CheckboxBackground]}
                    onChange={(c) =>
                      setValue(SetupValues.CheckboxBackground, c)
                    }
                  />
                </ColorSelectContainer>

                <InnerColorContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <HubiSwitch
                      id="switch-bg-demo-loading"
                      primaryColor={values[SetupValues.CheckboxBackground]}
                      secondaryColor={values[SetupValues.CheckboxForeground]}
                      loading
                    />
                    <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                      Checkbox (Laden)
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <HubiSwitch
                      id="switch-bg-demo-unchecked"
                      primaryColor={values[SetupValues.CheckboxBackground]}
                      secondaryColor={values[SetupValues.CheckboxForeground]}
                    />
                    <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                      Unselektierte Checkbox
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <HubiSwitch
                      id="switch-bg-demo-checked"
                      primaryColor={values[SetupValues.CheckboxBackground]}
                      secondaryColor={values[SetupValues.CheckboxForeground]}
                      checked
                    />
                    <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                      Selektierte Checkbox
                    </span>
                  </div>
                </InnerColorContainer>
              </BackgroundColorElement>
            </Col>
          </Row>
        </div>
      </CardSection>

      <Button
        type="primary"
        htmlType="submit"
        style={{ marginTop: "1rem" }}
        disabled={loadingProject || !!getProjectError}
        onClick={() => onSave()}
      >
        Setup abschließen
      </Button>
    </Page>
  )
}

const ListDemo = styled.div<{
  headerBgColor: string
  itemBgColor: string
  headerTextColor: string
  itemTextColor: string
}>`
  width: 100%;
  height: 100%;
  overflow: hidden;

  .list-container {
    margin: 1rem;
    width: 800px;
    border-radius: 4px;
    overflow: hidden;
    border: 2px solid ${(props) => props.headerBgColor};

    .cat-header {
      width: 100%;
      padding: 14px 16px 18px;
      font-size: 20px;
      font-weight: bold;
      background: ${(props) => props.headerBgColor};
      color: ${(props) => props.headerTextColor};
    }

    .dl-item {
      width: 100%;
      border-bottom: 2px solid #ddd;
      padding: 20px;
      background: ${(props) => props.itemBgColor};
      color: ${(props) => props.itemTextColor};
    }
  }
`

const InnerColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  padding: 0.75rem;
  overflow: hidden;
  user-select: none;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    line-height: 1;
    margin: 0;
    white-space: nowrap;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 6px;
  }
`

const ColorSelectContainer = styled.div`
  z-index: 10;

  > div {
    left: unset;
    right: 0;
    top: 0;
  }
`

export default SetupProject

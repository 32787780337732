import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Tooltip as AntTooltip,
  message,
  Input,
  Upload,
  Switch,
  Radio,
} from "antd"
import { v4 as uuid } from "uuid"
import _ from "lodash"
import { useForm } from "antd/lib/form/Form"
import React, { useContext, useEffect, useMemo } from "react"
import { useState } from "react"
import Modal from "antd/lib/modal/Modal"
import {
  LoadingOutlined,
  FilePdfOutlined,
  PlusOutlined,
  DiffOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { CustomColorSelect } from "@g51/hubi-components/components/EditableBar"
import IPointCard from "../components/IPointCard"
import styled from "styled-components"
import IPointImageUpload from "../components/IPointImageUpload"
import { ListIPoints_ipoints } from "./types/ListIPoints"
import { ListActions } from "../util/page"
import FontUpload from "../components/FontUpload"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
  ListContentPagesQuery,
  ListContentPagesQueryVariables,
  ListContentPagesQuery_ipointMainpages,
  ListContentPagesQuery_ipointMainpages_items,
} from "./types/ListContentPagesQuery"
import { CreateMainpage, CreateMainpageVariables } from "./types/CreateMainpage"
import { UpdateMainpage, UpdateMainpageVariables } from "./types/UpdateMainpage"
import { DeleteMainpage, DeleteMainpageVariables } from "./types/DeleteMainpage"
import { RcFile } from "antd/lib/upload"
import Axios from "axios"
import { CMS_URL, token } from ".."
import ContentPageItem from "../components/ContentPageItem"
import { LIST_DOWNLOADABLES_QUERY } from "../downloadables/queries"
import {
  ListDownloadablesQuery,
  ListDownloadablesQueryVariables,
} from "../downloadables/types/ListDownloadablesQuery"
import { ProjectContext } from "../App"
import { Tooltip } from "../util/util"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

const LIST_CONTENTPAGES_QUERY = gql`
  query ListContentPagesQuery($ipointId: ID!) {
    ipointMainpages(ipointId: $ipointId) {
      id
      name
      fachinfo
      style {
        img_bar_top
        img_bar_bottom
        img_bar_tab
        icon_home
        icon_back
        icon_basket
        icon_basket_add
        icon_basket_count_bg
        icon_page_bg_active
        icon_page_bg_inactive
        font_type_title
        font_type_tabbar
        font_type_pages
        font_type_basket_count
        color_title
        color_tabbar_active
        color_tabbar_inactive
        color_pages_active
        color_pages_inactive
        color_basket_count
        color_arrows
        tabbar_alignment
        tabbar_margin
        tabbar_padding
        title_offset
        show_page_buttons
      }
      items {
        id
        title
        document
        downloadables
        urls {
          url
          page
        }
        videos {
          file
          page
        }
      }
    }
  }
`

const CREATE_MAINPAGE_MUTATION = gql`
  mutation CreateMainpage($ipointId: ID!, $name: String!) {
    createIPointMainpage(ipointId: $ipointId, name: $name) {
      id
      name
      fachinfo
      style {
        img_bar_top
        img_bar_bottom
        img_bar_tab
        icon_home
        icon_back
        icon_basket
        icon_basket_add
        icon_basket_count_bg
        icon_page_bg_active
        icon_page_bg_inactive
        font_type_title
        font_type_tabbar
        font_type_pages
        font_type_basket_count
        color_title
        color_tabbar_active
        color_tabbar_inactive
        color_pages_active
        color_pages_inactive
        color_basket_count
        color_arrows
        tabbar_alignment
        tabbar_margin
        tabbar_padding
        title_offset
        show_page_buttons
      }
      items {
        id
        title
        document
        downloadables
        urls {
          url
          page
        }
        videos {
          file
          page
        }
      }
    }
  }
`

const UPDATE_MAINPAGE_MUTATION = gql`
  mutation UpdateMainpage($ipointId: ID!, $page: UpdateIPointMainpage!) {
    updateIPointMainpage(ipointId: $ipointId, page: $page) {
      id
    }
  }
`

const DELETE_MAINPAGE_MUTATION = gql`
  mutation DeleteMainpage($id: ID!, $ipointId: ID!) {
    deleteIPointMainpage(id: $id, ipointId: $ipointId) {
      id
    }
  }
`

const DEFAULT_PDF_DATA = {
  name: null,
  fileId: null,
}

export const parsePDFData = (s: string) => {
  if (!s) return DEFAULT_PDF_DATA
  const parts = s.split("::")
  if (parts.length !== 2) return DEFAULT_PDF_DATA

  return {
    fileId: parts[0],
    name: parts[1],
  }
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const formatPDFData = (name: string, fileId: string) =>
  `${fileId}::${name}`

const UpdateIPointContentpage: React.FC<{ ipoint: ListIPoints_ipoints }> = ({
  ipoint,
}) => {
  const { activeProject } = useContext(ProjectContext)
  const [form] = useForm()
  const [
    selectedContentPage,
    setSelectedContentPage,
  ] = useState<ListContentPagesQuery_ipointMainpages | null>(null)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [
    deletingPage,
    setDeletingPage,
  ] = useState<ListContentPagesQuery_ipointMainpages | null>(null)

  useEffect(() => {
    setSelectedContentPage(null)
  }, [ipoint])

  const [showNameModal, setShowNameModal] = useState(false)
  const [name, setName] = useState("")

  const downloadablesQuery = useQuery<
    ListDownloadablesQuery,
    ListDownloadablesQueryVariables
  >(LIST_DOWNLOADABLES_QUERY, {
    variables: {
      projectId: activeProject?.id!!,
    },
    fetchPolicy: "network-only",
  })

  const contentPagesQuery = useQuery<
    ListContentPagesQuery,
    ListContentPagesQueryVariables
  >(LIST_CONTENTPAGES_QUERY, {
    variables: { ipointId: ipoint.id },
  })

  const [createContentPage, createMutation] = useMutation<
    CreateMainpage,
    CreateMainpageVariables
  >(CREATE_MAINPAGE_MUTATION, {
    onCompleted: data => {
      message.success("Menu Page erfolgreich erstellt")
      setShowNameModal(false)
      setName("")
      setSelectedContentPage(data.createIPointMainpage)
    },
    refetchQueries: [
      { query: LIST_CONTENTPAGES_QUERY, variables: { ipointId: ipoint.id } },
    ],
  })

  const [updateContentPage, updateMutation] = useMutation<
    UpdateMainpage,
    UpdateMainpageVariables
  >(UPDATE_MAINPAGE_MUTATION, {
    onCompleted: () => {
      message.success("Content-Page erfolgreich gespeichert")
    },
    refetchQueries: [
      { query: LIST_CONTENTPAGES_QUERY, variables: { ipointId: ipoint.id } },
    ],
  })

  const [deleteContentPage, deleteMutation] = useMutation<
    DeleteMainpage,
    DeleteMainpageVariables
  >(DELETE_MAINPAGE_MUTATION, {
    onCompleted: data => {
      message.success("Content-Page erfolgreich gelöscht")
      setShowDeleteModal(false)
      setDeletingPage(null)

      if (selectedContentPage?.id === data.deleteIPointMainpage.id)
        setSelectedContentPage(null)
    },
    refetchQueries: [
      { query: LIST_CONTENTPAGES_QUERY, variables: { ipointId: ipoint.id } },
    ],
  })

  useEffect(() => {
    form.resetFields()
    if (!selectedContentPage) return

    const fontStyles: { [k: string]: string } = _.pick(
      selectedContentPage.style,
      "font_type_basket_count",
      "font_type_pages",
      "font_type_tabbar",
      "font_type_title"
    )

    const values = {
      fachinfo: selectedContentPage.fachinfo,
      ..._.omit(
        selectedContentPage.style,
        "font_type_basket_count",
        "font_type_pages",
        "font_type_tabbar",
        "font_type_title"
      ),
      ...Object.keys(fontStyles).reduce(
        (acc, it) => ({
          ...acc,
          [it]: parseFontType(fontStyles[it]),
        }),
        {}
      ),
    }

    form.setFieldsValue(values)

    setPages(selectedContentPage.items.map(it => _.omit(it, "__typename")))
  }, [selectedContentPage])

  const parseFontType = (str: string) => {
    const parts = str.split("::")

    return parts.length === 2
      ? {
          cssUrl: parts[0],
          fontFamily: parts[1],
        }
      : null
  }

  const formatFontType = (url: string, family: string) =>
    url && family ? `${url}::${family}` : ``

  const saveMainPage = (data: any) => {
    if (!selectedContentPage) return

    const variables: UpdateMainpageVariables = {
      ipointId: ipoint.id,
      page: {
        id: selectedContentPage.id,
        fachinfo: data.fachinfo,
        items: pages.map(it => ({
          ...it,
          videos: it.videos.map(vid => _.omit(vid, "__typename")),
          urls: it.urls.map(url => _.omit(url, "__typename")),
        })),
        name: selectedContentPage.name,
        style: {
          color_arrows: data.color_arrows ?? "",
          color_basket_count: data.color_basket_count ?? "",
          color_pages_active: data.color_pages_active ?? "",
          color_pages_inactive: data.color_pages_inactive ?? "",
          color_tabbar_active: data.color_tabbar_active ?? "",
          color_tabbar_inactive: data.color_tabbar_inactive ?? "",
          color_title: data.color_title ?? "",
          font_type_basket_count: formatFontType(
            data.font_type_basket_count?.cssUrl,
            data.font_type_basket_count?.fontFamily
          ),
          font_type_pages: formatFontType(
            data.font_type_pages?.cssUrl,
            data.font_type_pages?.fontFamily
          ),
          font_type_tabbar: formatFontType(
            data.font_type_tabbar?.cssUrl,
            data.font_type_tabbar?.fontFamily
          ),
          font_type_title: formatFontType(
            data.font_type_title?.cssUrl,
            data.font_type_title?.fontFamily
          ),
          icon_back: data.icon_back ?? "",
          icon_basket: data.icon_basket ?? "",
          icon_basket_add: data.icon_basket_add ?? "",
          icon_basket_count_bg: data.icon_basket_count_bg ?? "",
          icon_home: data.icon_home ?? "",
          icon_page_bg_active: data.icon_page_bg_active ?? "",
          icon_page_bg_inactive: data.icon_page_bg_inactive ?? "",
          img_bar_bottom: data.img_bar_bottom ?? "",
          img_bar_tab: data.img_bar_tab ?? "",
          img_bar_top: data.img_bar_top ?? "",
          show_page_buttons: data.show_page_buttons ?? false,
          tabbar_alignment: data.tabbar_alignment ?? "left",
          tabbar_margin: data.tabbar_margin ?? 0,
          tabbar_padding: data.tabbar_padding ?? 0,
          title_offset: data.title_offset ?? 0,
        },
      },
    }

    updateContentPage({ variables })
  }

  const [pages, setPages] = useState<
    Omit<ListContentPagesQuery_ipointMainpages_items, "__typename">[]
  >([])
  const [uploadingNewPage, setUploadingNewPage] = useState(false)

  const onPageChange = (
    newPage: Omit<ListContentPagesQuery_ipointMainpages_items, "__typename">
  ) => {
    setPages(pages.map(p => (p.id === newPage.id ? newPage : p)))
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return
    setPages(reorder(pages, result.source.index, result.destination.index))
  }

  const onPageDelete = (pid: string) => {
    setPages(pages.filter(it => it.id !== pid))
  }

  const onPDFUpload = async (f: RcFile) => {
    setUploadingNewPage(true)

    const formData = new FormData()
    formData.append("file", f)

    try {
      const res = await Axios.post(`${CMS_URL}/files/downloadable`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setUploadingNewPage(false)
      setPages(prev => [
        ...prev,
        {
          document: formatPDFData(f.name, res.data.fileId),
          downloadables: [],
          id: uuid(),
          title: f.name,
          videos: [],
          urls: [],
        },
      ])
    } catch {
      setUploadingNewPage(false)
      message.error(
        "Beim Upload ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
      )
    }
  }

  return (
    <>
      <Modal
        title={`Content-Page ${deletingPage?.name ?? ""} löschen`}
        visible={showDeleteModal}
        okText="Löschen"
        onCancel={() => {
          setShowDeleteModal(false)
          setDeletingPage(null)
        }}
        okButtonProps={{
          danger: true,
          loading: deleteMutation.loading,
        }}
        cancelButtonProps={{
          disabled: deleteMutation.loading,
        }}
        onOk={() => {
          if (!deletingPage) return
          deleteContentPage({
            variables: { id: deletingPage.id, ipointId: ipoint.id },
          })
        }}
      >
        <p>
          Sind Sie sicher, dass Sie die Content-Page {deletingPage?.name ?? ""}{" "}
          löschen möchten?
        </p>
      </Modal>

      <Modal
        title="Neue Content Page"
        visible={showNameModal}
        okText="Content Page erstellen"
        cancelText="Abbrechen"
        okButtonProps={{ loading: createMutation.loading }}
        cancelButtonProps={{ disabled: createMutation.loading }}
        onCancel={() => {
          setShowNameModal(false)
          setName("")
        }}
        onOk={() => {
          if (!name) return
          createContentPage({ variables: { ipointId: ipoint.id, name } })
        }}
      >
        <h3>Geben Sie einen Namen für die Content Page ein:</h3>
        <Input
          onChange={e => setName(e.target.value)}
          style={{ width: "80%" }}
        />
      </Modal>

      <Row gutter={24}>
        <Col span={16}>
          <h2 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            Content Pages für {ipoint.project_name}:
          </h2>
        </Col>

        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setShowNameModal(true)}
          >
            Neue Content Page
          </Button>
        </Col>
      </Row>

      <TableContainer>
        <Table
          style={{ marginBottom: "2rem" }}
          dataSource={contentPagesQuery.data?.ipointMainpages ?? []}
          rowKey="id"
          pagination={false}
          loading={contentPagesQuery.loading}
          bordered
          rowClassName={(p: ListContentPagesQuery_ipointMainpages) =>
            p.id === selectedContentPage?.id ? "selected-row" : ""
          }
        >
          <Table.Column title="Name" key={0} dataIndex="name" />

          <Table.Column
            key={5}
            title="Aktionen"
            width={105}
            fixed="right"
            render={(page: ListContentPagesQuery_ipointMainpages) => (
              <ListActions>
                <AntTooltip
                  title={
                    selectedContentPage?.id === page.id
                      ? `Ausgewählt`
                      : "Content-Page bearbeiten"
                  }
                >
                  {selectedContentPage?.id === page.id ? (
                    <Button icon={<CheckOutlined />} type="primary" disabled />
                  ) : (
                    <Button
                      icon={<DiffOutlined />}
                      type="primary"
                      onClick={() => {
                        setSelectedContentPage(page)
                      }}
                    />
                  )}
                </AntTooltip>

                <AntTooltip title="Löschen">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setDeletingPage(page)
                      setShowDeleteModal(true)
                    }}
                  />
                </AntTooltip>
              </ListActions>
            )}
          />
        </Table>
      </TableContainer>

      <div style={{ visibility: !!selectedContentPage ? "unset" : "hidden" }}>
        <Form form={form} onFinish={data => saveMainPage(data)}>
          <Row gutter={24}>
            <Col span={4}>
              <FontStyleCard
                fontLabel="Title Font"
                fontName="font_type_title"
                color1Label="Title Color"
                color1Name="color_title"
              />
            </Col>

            <Col span={6}>
              <FontStyleCard
                fontLabel="Tab Bar Font"
                fontName="font_type_tabbar"
                color1Label="Tab Bar Active Color"
                color1Name="color_tabbar_active"
                color2Label="Tab Bar Inactive Color"
                color2Name="color_tabbar_inactive"
              />
            </Col>

            <Col span={6}>
              <FontStyleCard
                fontLabel="Pages Font"
                fontName="font_type_pages"
                color1Label="Page Active Color"
                color1Name="color_pages_active"
                color2Label="Page Inactive Color"
                color2Name="color_pages_inactive"
              />
            </Col>

            <Col span={4}>
              <FontStyleCard
                fontLabel="Basket Count Font"
                fontName="font_type_basket_count"
                color1Label="Basket Count Color"
                color1Name="color_basket_count"
              />
            </Col>

            <Col span={4}>
              <FontStyleCard
                color1Label="Page Arrow Color"
                color1Name="color_arrows"
                extra={
                  <div>
                    <Form.Item
                      valuePropName="checked"
                      label="Page Buttons anzeigen"
                      name="show_page_buttons"
                    >
                      <Switch />
                    </Form.Item>
                  </div>
                }
              />
            </Col>

            <Col span={4}>
              <Form.Item name="fachinfo" noStyle>
                <FachInfoUpload />
              </Form.Item>
            </Col>

            <Col span={4}>
              <IPointCard>
                <p style={{ marginBottom: 0 }}>Tab Bar Ausrichtung</p>
                <Form.Item
                  name="tabbar_alignment"
                  label="Tab Bar Ausrichtung"
                  noStyle
                >
                  <Radio.Group>
                    <Radio.Button value="left">Links</Radio.Button>
                    <Radio.Button value="right">Zentriert</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <p style={{ marginBottom: 0, marginTop: 6 }}>
                  Tab Bar Margin (in Pixel)
                </p>
                <Form.Item
                  name="tabbar_margin"
                  label="Tab Bar Margin (in Pixel)"
                  noStyle
                >
                  <Input type="number" />
                </Form.Item>

                <p style={{ marginBottom: 0, marginTop: 6 }}>
                  Tab Bar Padding (in Pixel)
                </p>
                <Form.Item
                  name="tabbar_padding"
                  label="Tab Bar Padding (in Pixel)"
                  noStyle
                >
                  <Input type="number" />
                </Form.Item>

                <p style={{ marginBottom: 0, marginTop: 6 }}>
                  Titel Offset (in Pixel)
                </p>
                <Form.Item
                  name="title_offset"
                  label="Titel Offset (in Pixel)"
                  noStyle
                >
                  <Input type="number" />
                </Form.Item>
              </IPointCard>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 2732x284">
                <IPointImageUpload
                  label="Top Bar Background"
                  name="img_bar_top"
                />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 2732x104">
                <IPointImageUpload
                  label="Tab Bar Background"
                  name="img_bar_tab"
                />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 140x140">
                <IPointImageUpload
                  label="Basket Add Button"
                  name="icon_basket_add"
                />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 2732x241">
                <IPointImageUpload
                  label="Bottom Bar Background"
                  name="img_bar_bottom"
                />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 140x140">
                <IPointImageUpload label="Home Button" name="icon_home" />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 140x140">
                <IPointImageUpload label="Back Button" name="icon_back" />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 100x100">
                <IPointImageUpload
                  label="Page Active Background"
                  name="icon_page_bg_active"
                />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 100x100">
                <IPointImageUpload
                  label="Page Inactive Background"
                  name="icon_page_bg_inactive"
                />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 140x140">
                <IPointImageUpload label="Basket Button" name="icon_basket" />
              </Tooltip>
            </Col>

            <Col span={4}>
              <Tooltip text="Auflösung: 80x80">
                <IPointImageUpload
                  label="Basket Count Background"
                  name="icon_basket_count_bg"
                />
              </Tooltip>
            </Col>
          </Row>

          <PagesContainer>
            <h2>PDF-Seiten</h2>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="id"
                type="pdf-droppable"
                direction="horizontal"
              >
                {provided => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      overflowY: "auto",
                    }}
                  >
                    {pages.map((p, idx) => (
                      <Draggable key={p.id} draggableId={p.id} index={idx}>
                        {provided => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{
                              marginRight: "1rem",
                              marginBottom: "1rem",
                              ...provided.draggableProps.style,
                            }}
                          >
                            <ContentPageItem
                              dragHandle={provided.dragHandleProps}
                              onChange={p => onPageChange(p)}
                              onDelete={() => onPageDelete(p.id)}
                              item={p}
                              downloadables={
                                downloadablesQuery.data?.downloadables
                                  .categories ?? []
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}

                    <Upload.Dragger
                      showUploadList={false}
                      multiple
                      className="pdf-upload-container"
                      disabled={uploadingNewPage}
                      beforeUpload={f => {
                        onPDFUpload(f)
                        return false
                      }}
                    >
                      {uploadingNewPage && (
                        <p className="ant-upload-drag-icon">
                          <LoadingOutlined spin />
                        </p>
                      )}

                      {!uploadingNewPage && (
                        <>
                          <p className="ant-upload-drag-icon">
                            <FilePdfOutlined />
                          </p>

                          <p>Neue PDF-Seite hochladen</p>
                        </>
                      )}
                    </Upload.Dragger>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </PagesContainer>

          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: 15 }}
              loading={updateMutation.loading}
            >
              Änderungen Speichern
            </Button>
          </Col>
        </Form>
      </div>
    </>
  )
}

const PagesContainer = styled.div`
  .pdf-upload-container {
    height: calc(100% - 1rem);
    width: calc(240px - 1rem);
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`

const FontStyleCard = ({
  fontLabel,
  fontName,
  color1Label,
  color1Name,
  color2Label,
  color2Name,
  extra,
}: {
  fontLabel?: string
  fontName?: string
  color1Label: string
  color1Name: string
  color2Label?: string
  color2Name?: string
  extra?: React.ReactNode
}) => {
  return (
    <IPointCard>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {extra}

        {fontLabel && fontName && (
          <>
            <span style={{ display: "block" }}>{fontLabel}</span>
            <Form.Item name={fontName}>
              <FontUpload />
            </Form.Item>
          </>
        )}

        <Row gutter={16}>
          <Col span={color2Label && color2Name ? 12 : 24}>
            <span>{color1Label}</span>
            <Form.Item name={color1Name}>
              <ColorSelectModal label={color1Label} />
            </Form.Item>
          </Col>

          {color2Label && color2Name && (
            <Col span={12}>
              <span>{color2Label}</span>
              <Form.Item name={color2Name}>
                <ColorSelectModal label={color2Label} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
    </IPointCard>
  )
}

const FachInfoUpload: React.FC<{
  value?: string | null
  onChange?: (s: string | null) => void
}> = ({ value = null, onChange = () => {} }) => {
  const [uploadingFachInfo, setUploadingFachInfo] = useState(false)
  const { name } = useMemo(
    () => (value ? parsePDFData(value) : DEFAULT_PDF_DATA),
    [value]
  )

  const onFachInfoUpload = async (f: RcFile) => {
    setUploadingFachInfo(true)

    const formData = new FormData()
    formData.append("file", f)

    try {
      const res = await Axios.post(`${CMS_URL}/files/downloadable`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setUploadingFachInfo(false)
      onChange(`${res.data.fileId}::${f.name}`)
    } catch {
      setUploadingFachInfo(false)
      message.error(
        "Beim Upload ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
      )
    }
  }

  return (
    <IPointCard>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ display: "block" }}>Fachinfo (PDF)</span>

        <Upload.Dragger
          showUploadList={false}
          multiple
          disabled={uploadingFachInfo}
          beforeUpload={f => {
            onFachInfoUpload(f)
            return false
          }}
        >
          {uploadingFachInfo && (
            <p className="ant-upload-drag-icon">
              <LoadingOutlined spin />
            </p>
          )}

          {!uploadingFachInfo && (
            <>
              <p className="ant-upload-drag-icon">
                <FilePdfOutlined />
              </p>

              {name || <p>Fachinfo Hochladen</p>}
            </>
          )}
        </Upload.Dragger>
      </div>
    </IPointCard>
  )
}

const ColorPickerContainer = styled.div`
  > * {
    position: inherit;
    width: auto;
  }
`

const ColorSelectModal: React.FC<{
  value?: string
  onChange?: (color: string) => string
  defaultColor?: string
  label?: string
}> = ({ value = "", onChange = () => {}, defaultColor = "#fff", label }) => {
  const [modalShown, setModalShown] = useState(false)
  const [color, setColor] = useState(value)

  return (
    <>
      <ColorContainer
        color={value || defaultColor}
        onClick={() => {
          setColor(value || defaultColor)
          setModalShown(true)
        }}
      />

      <Modal
        visible={modalShown}
        okText="Farbe Setzen"
        cancelText="Abbrechen"
        onCancel={() => {
          setModalShown(false)
          setColor("")
        }}
        onOk={() => {
          onChange(color)
          setModalShown(false)
        }}
        title="Color Picker"
        width={270}
      >
        <h2>{label}</h2>
        <ColorPickerContainer>
          <CustomColorSelect
            onChange={c => setColor(c || "#fff")}
            defaultColor="#fff"
            color={color}
            leftSide
          />
        </ColorPickerContainer>
      </Modal>
    </>
  )
}

const TableContainer = styled.div`
  .selected-row {
    background: #f0f0f0;
  }
`

const ColorContainer = styled.div<{ color: string }>`
  width: 100%;
  height: 40px;
  background-color: ${props => props.color};
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
`

export default UpdateIPointContentpage

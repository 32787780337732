import React, { useContext, useState } from "react"
import { Page, ListHeader, ListActions, Actions } from "../util/page"
import {
  PageHeader,
  message,
  Tabs,
  Table,
  Input,
  Form,
  Switch,
  Button,
  InputNumber,
  Modal,
  Tooltip,
} from "antd"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useParams, Redirect } from "react-router"
import { ProjectContext } from "../App"
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  DiffOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { useForm } from "antd/lib/form/Form"
import dayjs from "dayjs"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "./types/GetProjectQuery"
import { GET_PROJECT_QUERY } from "./CreateProject"
import styled from "styled-components"
import UpdateIPointStartpage from "./UpdateIPointStartpage"
import {
  ListIPoints,
  ListIPointsVariables,
  ListIPoints_ipoints,
} from "./types/ListIPoints"
import UpdateIPointMenupage from "./UpdateIPointMenupage"
import UpdateIPointContentpage from "./UpdateIPointContentpage"

interface Params {
  id?: string
}

export const LIST_IPOINTS_QUERY = gql`
  query ListIPoints($id: ID!) {
    ipoints(projectId: $id) {
      id
      project_name
      has_embedded_basket
      has_embedded_browser
      embedded_browser_url
      screen_timeout

      created
      updated
      version
    }
  }
`

const DELETE_IPOINT_MUTATION = gql`
  mutation DeleteIPoint($id: ID!) {
    deleteIPoint(id: $id) {
      id
    }
  }
`

const CREATE_IPOINT_MUTATION = gql`
  mutation CreateIPoint(
    $projectId: ID!
    $project: String!
    $name: String!
    $embedded: Boolean!
    $hasEmbeddedBrowser: Boolean!
    $embeddedBrowserURL: String
    $alwaysVisible: Boolean!
    $timeout: Int!
  ) {
    createIPoint(
      projectId: $projectId
      project: $project
      name: $name
      embedded: $embedded
      alwaysVisible: $alwaysVisible
      hasEmbeddedBrowser: $hasEmbeddedBrowser
      embeddedBrowserURL: $embeddedBrowserURL
      timeout: $timeout
    ) {
      id
    }
  }
`

const UPDATE_IPOINT_MUTATION = gql`
  mutation UpdateIPoint(
    $id: ID!
    $project: String!
    $name: String!
    $embedded: Boolean!
    $hasEmbeddedBrowser: Boolean!
    $embeddedBrowserURL: String
    $alwaysVisible: Boolean!
    $timeout: Int!
  ) {
    updateIPoint(
      id: $id
      project: $project
      name: $name
      embedded: $embedded
      hasEmbeddedBrowser: $hasEmbeddedBrowser
      embeddedBrowserURL: $embeddedBrowserURL
      alwaysVisible: $alwaysVisible
      timeout: $timeout
    ) {
      id
    }
  }
`

const UpdateIPoint = () => {
  const params = useParams<Params>()
  const { activeProject, setActiveProject } = useContext(ProjectContext)

  const [editModalShown, setEditModalShown] = useState(false)
  const [
    editingIPoint,
    setEditingIPoint,
  ] = useState<ListIPoints_ipoints | null>(null)

  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const [
    deletingIPoint,
    setDeletingIPoint,
  ] = useState<ListIPoints_ipoints | null>(null)

  const [
    selectedIPoint,
    setSelectedIPoint,
  ] = useState<ListIPoints_ipoints | null>(null)

  const ipointsQuery = useQuery<ListIPoints, ListIPointsVariables>(
    LIST_IPOINTS_QUERY,
    {
      skip: !activeProject,
      variables: { id: activeProject?.id ?? "" },
    }
  )

  const [createIPoint, createIPointMutation] = useMutation(
    CREATE_IPOINT_MUTATION,
    {
      refetchQueries: [
        {
          query: LIST_IPOINTS_QUERY,
          variables: { id: activeProject?.id || "" },
        },
      ],
      onCompleted: () => {
        message.info("i-Point erfolgreich gespeichert")
        setEditModalShown(false)
        setEditingIPoint(null)
      },
    }
  )

  const [updateIPoint, updateIPointMutation] = useMutation(
    UPDATE_IPOINT_MUTATION,
    {
      refetchQueries: [
        {
          query: LIST_IPOINTS_QUERY,
          variables: { id: activeProject?.id || "" },
        },
      ],
      onCompleted: () => {
        message.info("i-Point erfolgreich gespeichert")
        setEditModalShown(false)
        setEditingIPoint(null)
      },
    }
  )

  const [deleteIPoint, deleteIPointMutation] = useMutation(
    DELETE_IPOINT_MUTATION,
    {
      refetchQueries: [
        {
          query: LIST_IPOINTS_QUERY,
          variables: { id: activeProject?.id || "" },
        },
      ],
      onCompleted: () => {
        message.info("i-Point erfolgreich gelöscht")
        setDeleteModalShown(false)
        setDeletingIPoint(null)
      },
    }
  )

  const [form] = useForm()

  const onSave = (data: any) => {
    if (!!editingIPoint) {
      updateIPoint({
        variables: {
          id: editingIPoint!!.id,
          projectId: activeProject?.id,
          project: "",
          name: data.project_name,
          embedded: !!data.has_embedded_basket,
          hasEmbeddedBrowser: false,
          embeddedBrowserURL: "",
          alwaysVisible: !!data.basket_always_visible,
          timeout: data.screen_timeout,
        },
      })
    } else {
      createIPoint({
        variables: {
          project: "",
          projectId: activeProject?.id,
          name: data.project_name,
          embedded: !!data.has_embedded_basket,
          hasEmbeddedBrowser: false,
          embeddedBrowserURL: "",
          alwaysVisible: !!data.basket_always_visible,
          timeout: data.screen_timeout,
        },
      })
    }
  }

  useQuery<GetProjectQuery, GetProjectQueryVariables>(GET_PROJECT_QUERY, {
    variables: { id: params.id!! },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      message.error("Das Projekt konnte nicht gefunden werden.")
    },
    onCompleted: data => {
      if (!data) return
      setActiveProject(data.project.id)
    },
  })

  if (activeProject && activeProject.id !== params.id)
    return <Redirect to={`/projects/${activeProject.id}/ipoint/edit`} />

  return (
    <Page>
      <Modal
        title={`i-Point ${deletingIPoint?.project_name ?? ""} löschen`}
        visible={deleteModalShown}
        okText="Löschen"
        onCancel={() => {
          setDeleteModalShown(false)
          setDeletingIPoint(null)
        }}
        okButtonProps={{
          danger: true,
          loading: deleteIPointMutation.loading,
        }}
        cancelButtonProps={{
          disabled: deleteIPointMutation.loading,
        }}
        onOk={() => {
          deleteIPoint({ variables: { id: deletingIPoint?.id } })
        }}
      >
        <p>
          Sind Sie sicher, dass Sie den i-Point{" "}
          {deletingIPoint?.project_name ?? ""} löschen möchten?
        </p>
      </Modal>

      <Modal
        title={
          !!editingIPoint
            ? `i-Point ${editingIPoint.project_name} bearbeiten`
            : `i-Point erstellen`
        }
        visible={editModalShown}
        onOk={() => form.submit()}
        okText={
          !!editingIPoint
            ? `i-Point ${editingIPoint.project_name} speichern`
            : `i-Point erstellen`
        }
        okButtonProps={{
          loading: createIPointMutation.loading || updateIPointMutation.loading,
        }}
        cancelButtonProps={{
          disabled:
            createIPointMutation.loading || updateIPointMutation.loading,
        }}
        onCancel={() => setEditModalShown(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={data => onSave(data)}
          initialValues={{
            project_name: "",
            has_embedded_basket: true,
            has_embedded_browser: false,
            embedded_browser_url: "",
            basket_always_visible: false,
            screen_timeout: 30,
          }}
        >
          <Form.Item
            name="project_name"
            label="i-Point Name"
            rules={[{ required: true }]}
          >
            <Input
              disabled={
                createIPointMutation.loading || updateIPointMutation.loading
              }
            />
          </Form.Item>

          <Form.Item
            name="has_embedded_basket"
            label="Enthält Basket"
            valuePropName="checked"
          >
            <Switch
              disabled={
                createIPointMutation.loading || updateIPointMutation.loading
              }
            />
          </Form.Item>

          <Form.Item
            name="basket_always_visible"
            label="Basket ist immer sichtbar"
            valuePropName="checked"
          >
            <Switch
              disabled={
                createIPointMutation.loading || updateIPointMutation.loading
              }
            />
          </Form.Item>

          <Form.Item
            name="screen_timeout"
            label="Inaktivitäts-Timeout in Sekunden"
            rules={[{ required: true }]}
          >
            <InputNumber
              min={1}
              max={6000}
              disabled={
                createIPointMutation.loading || updateIPointMutation.loading
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      <ListHeader>
        <PageHeader
          title={`i-Points für Projekt ${
            activeProject?.name ?? "..."
          } bearbeiten`}
        />

        <Actions>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingIPoint(null)
              form.resetFields()
              setEditModalShown(true)
            }}
          >
            Neuer i-Point
          </Button>
        </Actions>
      </ListHeader>

      <TableContainer>
        <Table
          rowKey="id"
          loading={ipointsQuery.loading}
          dataSource={ipointsQuery.data?.ipoints || []}
          pagination={false}
          bordered
          rowClassName={(ipoint: ListIPoints_ipoints) =>
            ipoint.id === selectedIPoint?.id ? "selected-row" : ""
          }
        >
          <Table.Column title="Name" key={0} dataIndex="project_name" />

          <Table.Column
            key={3}
            title="Erstellt"
            dataIndex="created"
            render={(created: string) => dayjs(created).fromNow()}
          />

          <Table.Column
            key={4}
            title="Bearbeitet"
            dataIndex="updated"
            render={(updated: string) => dayjs(updated).fromNow()}
          />

          <Table.Column
            key={5}
            title="Aktionen"
            width={105}
            fixed="right"
            render={(ipoint: ListIPoints_ipoints) => (
              <ListActions>
                <Tooltip title="i-Point Einstellungen bearbeiten">
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditingIPoint(ipoint)
                      form.setFieldsValue({ ...ipoint })
                      setEditModalShown(true)
                    }}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    selectedIPoint?.id === ipoint.id
                      ? `Ausgewählt`
                      : "i-Point Content bearbeiten"
                  }
                >
                  {selectedIPoint?.id === ipoint.id ? (
                    <Button icon={<CheckOutlined />} type="primary" disabled />
                  ) : (
                    <Button
                      icon={<DiffOutlined />}
                      type="primary"
                      onClick={() => {
                        setSelectedIPoint(ipoint)
                      }}
                    />
                  )}
                </Tooltip>

                <Tooltip title="Löschen">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setDeletingIPoint(ipoint)
                      setDeleteModalShown(true)
                    }}
                  />
                </Tooltip>
              </ListActions>
            )}
          />
        </Table>
      </TableContainer>

      <Tabs
        type="line"
        size="large"
        destroyInactiveTabPane
        style={{ marginBottom: "2rem", marginTop: "3rem" }}
        defaultActiveKey="1"
      >
        <Tabs.TabPane tab="Start Page" key="1" disabled={!selectedIPoint}>
          {!!selectedIPoint && (
            <UpdateIPointStartpage ipoint={selectedIPoint} />
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Menu Pages" key="2" disabled={!selectedIPoint}>
          {!!selectedIPoint && <UpdateIPointMenupage ipoint={selectedIPoint} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Content Pages" key="3" disabled={!selectedIPoint}>
          {!!selectedIPoint && (
            <UpdateIPointContentpage ipoint={selectedIPoint} />
          )}
        </Tabs.TabPane>
      </Tabs>

      {!selectedIPoint && (
        <NoIPointSelected>
          Klicken Sie rechts in der Tabelle auf den Content-Button (
          <DiffOutlined />
          ), um den i-Point-Content zu bearbeiten.
        </NoIPointSelected>
      )}
    </Page>
  )
}

const TableContainer = styled.div`
  .selected-row {
    background: #f0f0f0;
    border: 1px solid #333;
  }
`

const NoIPointSelected = styled.div`
  width: 100%;
  text-align: center;
  margin: 4rem 0;
  font-size: 1.15rem;
`

export default UpdateIPoint

import React, { useContext, useState } from "react"
import { Page, ListHeader, CardSection, Actions } from "../util/page"
import {
  Form,
  PageHeader,
  Row,
  Col,
  Input,
  Button,
  message,
  Select,
  Divider,
  Radio,
  Switch,
  Modal,
  Upload,
  DatePicker,
} from "antd"
import { gql, useMutation, ApolloError, useQuery } from "@apollo/client"
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
} from "./types/CreateProjectMutation"
import { isDuplicateRecordError } from "../util/errors"
import { useHistory, useParams, Redirect } from "react-router"
import { useForm } from "antd/lib/form/Form"
import { ProjectContext } from "../App"
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "./types/GetProjectQuery"
import {
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from "./types/UpdateProjectMutation"
import { LIST_PROJECTS_QUERY } from "../util/queries"
import ImageUpload from "../components/ImageUpload"
import { ListMailOptionsQuery } from "../instance/types/ListMailOptionsQuery"
import { LIST_MAIL_OPTIONS_QUERY } from "../instance/UpdateInstance"
import { animateScroll } from "react-scroll"
import { Link } from "react-router-dom"
import styled from "styled-components"
import {
  CloudUploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
  CopyOutlined,
  ControlOutlined,
} from "@ant-design/icons"
import { CMS_URL, token } from ".."
import Axios, { AxiosError } from "axios"
import Helmet from "react-helmet"
import { CustomValues } from "@g51/hubi-components"
import { ExplainIcon } from "../util/util"
import moment from "moment"
import _ from "lodash"
import {
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
} from "./types/DeleteProjectMutation"
import {
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables,
} from "./types/DuplicateProjectMutation"
import {
  SendTestEventMutation,
  SendTestEventMutationVariables,
} from "./types/SendTestEventMutation"

export const ProjectFragments = {
  ProjectFields: gql`
    fragment ProjectFields on Project {
      id
      name
      slug
      customValues
      start
      end
      accessStart
      accessEnd
      downloadables {
        categories {
          category {
            id
            name
          }
          downloadables {
            id
            name
            description
            previewImage
          }
        }
      }
      analyticsKey
      mailTemplates {
        basketCheckoutTemplateId
        basketCheckoutSubject
        touchlessBundleTemplateId
        touchlessBundleSubject
      }
      mailSenderEmail
      mailSenderName
      logo
      logoAlignment
      defaultDownloadableIcon
      showSubLogo
      subLogoAlignment
      subLogo
      created
      updated
      version
    }
  `,
}

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProjectMutation($project: CreateProject!) {
    createProject(project: $project) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProjectMutation(
    $id: ID!
    $version: Int!
    $changes: UpdateProject!
  ) {
    updateProject(id: $id, version: $version, changes: $changes) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

export const GET_PROJECT_QUERY = gql`
  query GetProjectQuery($id: ID!) {
    project(id: $id) {
      ...ProjectFields
    }
  }
  ${ProjectFragments.ProjectFields}
`

const DUPLICATE_PROJECT_MUTATION = gql`
  mutation DuplicateProjectMutation(
    $projectID: ID!
    $newName: String!
    $includeDL: Boolean!
  ) {
    duplicateProject(
      id: $projectID
      newName: $newName
      includeDownloadables: $includeDL
    ) {
      id
    }
  }
`

const SEND_TEST_EVENT_MUTATION = gql`
  mutation SendTestEventMutation($name: String!, $key: String!) {
    sendTestEvent(name: $name, key: $key)
  }
`

const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProjectMutation($projectID: ID!, $version: Int!) {
    deleteProject(id: $projectID, version: $version) {
      id
    }
  }
`

interface ProjectForm {
  name: string
  slug: string
  logo: string
  logoAlignment: string
  showSubLogo: boolean
  subLogo?: string
  subLogoAlignment?: string
  analyticsKey: string
  mailSenderEmail: string
  mailSenderName: string
  startend: moment.Moment[]
  accessStartend: moment.Moment[]
  mailTemplates: {
    basketCheckoutTemplateId: string
    basketCheckoutSubject: string
    touchlessBundleTemplateId: string
    touchlessBundleSubject: string
  }
  customValues: {
    emailTexts: {
      emailPlaceholder: string
      emailInvalid: string
    }
  }
}

interface Params {
  id?: string
}

const CreateProject = () => {
  const history = useHistory()
  const params = useParams<Params>()

  const [form] = useForm()
  const isEditing = !!params.id

  const { activeProject, setActiveProject } = useContext(ProjectContext)
  const [showSubLogo, setShowSubLogo] = useState(false)
  const [headingFontData, setHeadingFontData] = useState<FontData | null>(null)
  const [bodyFontData, setBodyFontData] = useState<FontData | null>(null)

  const [createProject, { loading: creatingProject }] = useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CREATE_PROJECT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
  })

  const [updateProject, { loading: updatingProject }] = useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UPDATE_PROJECT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    update: (store, { data }) => {
      store.writeQuery<GetProjectQuery, GetProjectQueryVariables>({
        query: GET_PROJECT_QUERY,
        data: { project: { ...data!!.updateProject } },
      })
    },
  })

  const { data, loading: loadingProject, error: getProjectError } = useQuery<
    GetProjectQuery,
    GetProjectQueryVariables
  >(GET_PROJECT_QUERY, {
    variables: { id: params.id!! },
    skip: !isEditing,
    notifyOnNetworkStatusChange: true,
    onError: () => {
      message.error("Das Projekt konnte nicht gefunden werden.")
    },
    onCompleted: data => {
      if (!isEditing || !data) return

      setActiveProject(data.project.id)
      form.setFieldsValue({ ...data.project })
      setShowSubLogo(data.project.showSubLogo)

      if (data.project.start && data.project.end)
        form.setFieldsValue({
          startend: [moment(data.project.start), moment(data.project.end)],
        })

      if (data.project.accessStart && data.project.accessEnd)
        form.setFieldsValue({
          accessStartend: [
            moment(data.project.accessStart),
            moment(data.project.accessEnd),
          ],
        })

      setHeadingFontData({
        fontFamily: data.project.customValues["font-heading"]?.["family"],
        cssUrl: data.project.customValues["font-heading"]?.["css-url"],
        sizeNormal: data.project.customValues["font-heading"]?.["size-normal"],
        sizeBig: data.project.customValues["font-heading"]?.["size-big"],
      })

      setBodyFontData({
        fontFamily: data.project.customValues["font-body"]?.["family"],
        cssUrl: data.project.customValues["font-body"]?.["css-url"],
        sizeNormal: data.project.customValues["font-body"]?.["size-normal"],
        sizeBig: data.project.customValues["font-body"]?.["size-big"],
      })
    },
  })

  const { data: mailOptionsData, loading: loadingMailOptions } = useQuery<
    ListMailOptionsQuery
  >(LIST_MAIL_OPTIONS_QUERY)

  const onSaveError = (err: ApolloError) => {
    if (err.networkError) {
      message.error("Beim Speichern ist ein Fehler aufgetreten.")
      return
    }

    if (isDuplicateRecordError(err)) {
      message.error("Ein Projekt mit diesem Slug oder Namen existiert bereits.")
      return
    }

    message.error("Ein unbekannter Fehler ist aufgetreten.")
  }

  const onFinish = async (values: ProjectForm) => {
    if (isEditing) {
      const customValues = { ...(data?.project.customValues ?? {}) }
      if (headingFontData) {
        customValues["font-heading"] = {
          family: headingFontData.fontFamily,
          "css-url": headingFontData.cssUrl,
          "size-normal": headingFontData.sizeNormal,
          "size-big": headingFontData.sizeBig,
        }
      }

      if (bodyFontData) {
        customValues["font-body"] = {
          family: bodyFontData.fontFamily,
          "css-url": bodyFontData.cssUrl,
          "size-normal": bodyFontData.sizeNormal,
          "size-big": bodyFontData.sizeBig,
        }
      }

      customValues["emailTexts"] = {
        emailPlaceholder:
          values.customValues.emailTexts?.emailPlaceholder ?? "Ihre E-Mail",
        emailInvalid:
          values.customValues.emailTexts?.emailInvalid ??
          "Bitte geben Sie Ihre E-Mail ein!",
      }

      const start = moment(values.startend[0]).format()
      const end = moment(values.startend[1]).format()
      const accessStart = moment(values.accessStartend[0]).format()
      const accessEnd = moment(values.accessStartend[1]).format()

      const variables: UpdateProjectMutationVariables = {
        id: params.id!!,
        version: data?.project?.version ?? 0,
        changes: {
          ..._.omit(values, "startend", "accessStartend"),
          showSubLogo,
          customValues,
          start,
          end,
          accessStart,
          accessEnd,
        },
      }

      try {
        await updateProject({ variables })
        message.success("Projekt erfolgreich gespeichert.")
        animateScroll.scrollToTop({ duration: 300 })
      } catch (err) {
        onSaveError(err as ApolloError)
      }
    } else {
      const customValues: CustomValues = {}
      if (headingFontData) {
        customValues["font-heading"] = {
          family: headingFontData.fontFamily,
          "css-url": headingFontData.cssUrl,
          "size-normal": headingFontData.sizeNormal,
          "size-big": headingFontData.sizeBig,
        }
      }

      if (bodyFontData) {
        customValues["font-body"] = {
          family: bodyFontData.fontFamily,
          "css-url": bodyFontData.cssUrl,
          "size-normal": bodyFontData.sizeNormal,
          "size-big": bodyFontData.sizeBig,
        }
      }

      if (!customValues["emailTexts"]) customValues["emailTexts"] = {}

      customValues["emailTexts"]["emailPlaceholder"] =
        values.customValues.emailTexts.emailPlaceholder ?? "Ihre E-Mail"
      customValues["emailTexts"]["emailInvalid"] =
        values.customValues.emailTexts.emailPlaceholder ??
        "Bitte geben Sie Ihre E-Mail ein!"

      const start = moment(values.startend[0]).format()
      const end = moment(values.startend[1]).format()
      const accessStart = moment(values.accessStartend[0]).format()
      const accessEnd = moment(values.accessStartend[1]).format()

      const variables: CreateProjectMutationVariables = {
        project: {
          ..._.omit(values, "startend", "accessStartend"),
          showSubLogo,
          customValues,
          start,
          end,
          accessStart,
          accessEnd,
        },
      }

      try {
        const result = await createProject({ variables })
        message.success("Projekt erfolgreich erstellt.")
        animateScroll.scrollToTop({ duration: 300 })
        setActiveProject(result.data?.createProject?.id)
        history.push(`/projects/${result.data?.createProject?.id}/setup`)
      } catch (err) {
        onSaveError(err as ApolloError)
      }
    }
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteInput, setDeleteInput] = useState("")

  const [deleteProject, { loading: deletingProject }] = useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DELETE_PROJECT_MUTATION, {
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    onError: () => message.error("Das Projekt konnte nicht gelöscht werden."),
    onCompleted: () => {
      setActiveProject(undefined)
      message.success("Projekt erfolgreich gelöscht.")
      history.replace("/")
    },
  })

  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [duplicateInput, setDuplicateInput] = useState("")

  const [duplicateProject, { loading: duplicatingProject }] = useMutation<
    DuplicateProjectMutation,
    DuplicateProjectMutationVariables
  >(DUPLICATE_PROJECT_MUTATION, {
    refetchQueries: [{ query: LIST_PROJECTS_QUERY }],
    onError: () => message.error("Das Projekt konnte nicht dupliziert werden."),
    onCompleted: res => {
      setActiveProject(res.duplicateProject.id)
      history.push(`/projects/${res.duplicateProject.id}/setup`)
      message.success("Projekt erfolgreich kopiert.")
    },
  })

  const onDuplicate = (name: string) => {
    if (!data) return

    duplicateProject({
      variables: {
        projectID: data?.project.id,
        newName: name,
        includeDL: false,
      },
    })
  }

  const disableFormFields =
    loadingProject || creatingProject || updatingProject || !!getProjectError

  const onFinishFailed = () => {
    message.error("Bitte füllen Sie alle benötigten Felder aus.")
  }

  const [sendTest, sendTestMut] = useMutation<
    SendTestEventMutation,
    SendTestEventMutationVariables
  >(SEND_TEST_EVENT_MUTATION, {
    onCompleted: () =>
      message.success(
        "Test-Event wurde gesendet. Überprüfen Sie jetzt in Google Analytics, ob ein Event mit dem korrekten Projektnamen empfangen wurde.",
        10
      ),
  })

  const onSendTest = () => {
    const name = form.getFieldValue("name")
    const key = form.getFieldValue("analyticsKey")

    if (!name || !key) {
      message.error(
        "Bitte wählen Sie zuerst einen Projektnamen und eine Tracking ID."
      )
      return
    }

    sendTest({ variables: { name, key } })
  }

  if (isEditing && activeProject && activeProject.id !== params.id)
    return <Redirect to={`/projects/${activeProject.id}/edit`} />

  return (
    <Page>
      <Modal
        visible={showDuplicateModal}
        centered
        title="Projekt kopieren"
        cancelText="Abbrechen"
        okText="Projekt kopieren"
        okButtonProps={{
          loading: duplicatingProject,
          type: "primary",
        }}
        onOk={() => onDuplicate(duplicateInput)}
        onCancel={() => {
          setShowDuplicateModal(false)
          setDuplicateInput("")
        }}
        cancelButtonProps={{ disabled: !data }}
      >
        Geben Sie einen Namen für das neue Projekt ein, um dieses Projekt zu
        kopieren.
        <ModalInput
          placeholder="Projektname"
          autoFocus
          disabled={!data}
          value={duplicateInput}
          onChange={ev => setDuplicateInput(ev.target.value)}
          onPressEnter={() => onDuplicate(duplicateInput)}
        />
      </Modal>

      <Modal
        visible={showDeleteModal}
        centered
        title="Projekt löschen"
        cancelText="Abbrechen"
        okText="Ja, Projekt löschen"
        okButtonProps={{
          disabled: !data || deleteInput !== data?.project.name,
          loading: deletingProject,
          danger: true,
        }}
        onOk={() =>
          data &&
          deleteProject({
            variables: {
              projectID: data?.project.id,
              version: data?.project.version,
            },
          })
        }
        onCancel={() => {
          setShowDeleteModal(false)
          setDeleteInput("")
        }}
        cancelButtonProps={{ disabled: !data }}
      >
        Sie Sie sicher, dass Sie dieses Projekt löschen wollen?
        <br />
        <b>
          Hierbei werden auch alle Downloadables, bestehende Baskets und iPoints
          gelöscht.
        </b>
        <br />
        <br />
        Geben Sie "{data?.project.name ?? "..."}" ein, um das Projekt zu
        löschen.
        <ModalInput
          placeholder="Projektname"
          autoFocus
          disabled={!data}
          value={deleteInput}
          onChange={ev => setDeleteInput(ev.target.value)}
          onPressEnter={() =>
            data &&
            deleteProject({
              variables: {
                projectID: data?.project.id,
                version: data?.project.version,
              },
            })
          }
        />
      </Modal>

      <ListHeader>
        <PageHeader
          title={
            isEditing
              ? `Projekt ${data?.project?.name ?? "..."} bearbeiten`
              : "Projekt erstellen"
          }
        />

        <Actions>
          {isEditing &&
            Object.keys(data?.project.customValues ?? {}).length > 0 && (
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: "Initialer Setup",
                    content:
                      "Wollen Sie den initialen Projekt Setup erneut durchführen? Bereits eingestellte Optionen werden hierbei überschrieben!",
                    okText: "Ja, alte Optionen überschreiben",
                    cancelText: "Abbrechen",
                    onOk: () => history.push(`/projects/${params.id}/setup`),
                  })
                }}
              >
                Initialen Projekt-Setup erneut durchführen
              </Button>
            )}

          {isEditing && (
            <>
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => setShowDeleteModal(true)}
              >
                Projekt löschen
              </Button>

              <Button
                icon={<CopyOutlined />}
                onClick={() => setShowDuplicateModal(true)}
              >
                Projekt kopieren
              </Button>

              {Object.keys(data?.project.customValues ?? {}).length === 0 && (
                <Link to={`/projects/${params.id}/setup`}>
                  <Button icon={<ControlOutlined />}>
                    Initialen Projekt-Setup durchführen
                  </Button>
                </Link>
              )}
            </>
          )}
        </Actions>
      </ListHeader>

      <Form
        name="general"
        layout="vertical"
        onFinishFailed={() => onFinishFailed()}
        onFinish={values => onFinish(values as ProjectForm)}
        form={form}
        initialValues={{ logoAlignment: "left" }}
      >
        <CardSection style={{ marginBottom: "2rem" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Projektname"
                rules={[
                  { required: true, message: "Bitte Projektnamen eingeben" },

                  {
                    min: 3,
                    message: "Name muss mindestens 3 Zeichen lang sein",
                  },
                  {
                    max: 32,
                    message: "Name darf maximal 32 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Name" disabled={disableFormFields} />
              </Form.Item>

              <Form.Item
                name="slug"
                label={
                  <div>
                    Slug{" "}
                    <ExplainIcon
                      text={`Der Slug bestimmt die URL des Projektes. Auf der Instanz "instanz.de" hat ein Projekt mit dem Slug "sommermesse" z.B. die URL "instanz.de/sommermesse".`}
                    />
                  </div>
                }
                rules={[
                  { required: true, message: "Bitte Slug eingeben" },
                  {
                    min: 3,
                    message: "Slug muss mindestens 3 Zeichen lang sein",
                  },
                  {
                    max: 32,
                    message: "Slug darf maximal 32 Zeichen lang sein",
                  },
                ]}
              >
                <Input placeholder="Slug" disabled={disableFormFields} />
              </Form.Item>

              <Form.Item
                name="startend"
                label={
                  <div>
                    Zeitspanne Kongress{" "}
                    <ExplainIcon text="Start- und Enddatum an dem der Kongress statt findet. Diese Daten werden dem Gast auf der Auswahlseite für Kongresse angezeigt." />
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Bitte Start- und Enddatum eingeben",
                  },
                ]}
              >
                <DatePicker.RangePicker
                  placeholder={["Startdatum", "Enddatum"]}
                  style={{ width: "100%" }}
                  format="DD.MM.YYYY"
                  disabled={disableFormFields}
                />
              </Form.Item>

              <Form.Item
                name="accessStartend"
                label={
                  <div>
                    Zeitspanne Zugriffsbeschränkung{" "}
                    <ExplainIcon text="Zugriffsstart- und Enddatum bestimmen, wie lange auf die Downloadables zugegriffen werden kann. Vor und nach dem angegebenen Datum ist es dem Nutzer nicht möglich, auf seinen Basket zuzugreifen." />
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Bitte Start- und Enddatum eingeben",
                  },
                ]}
              >
                <DatePicker.RangePicker
                  placeholder={["Startdatum", "Enddatum"]}
                  style={{ width: "100%" }}
                  format="DD.MM.YYYY"
                  disabled={disableFormFields}
                />
              </Form.Item>

              <Form.Item
                name="analyticsKey"
                label={
                  <div>
                    Google Tracking ID{" "}
                    <ExplainIcon text="Alle Nutzungsdaten werden an die angegebene Tracking ID gesendet." />
                  </div>
                }
                rules={[
                  { required: true, message: "Bitte Tracking ID eingeben" },
                ]}
              >
                <Input.Search
                  placeholder="UA-000000000-0"
                  disabled={disableFormFields}
                  enterButton="Test-Event senden"
                  onSearch={() => onSendTest()}
                  loading={sendTestMut.loading}
                />
              </Form.Item>

              <Divider>Schriftarten</Divider>

              <Helmet>
                {!!headingFontData && (
                  <link rel="stylesheet" href={headingFontData?.cssUrl} />
                )}

                {!!bodyFontData && (
                  <link rel="stylesheet" href={bodyFontData?.cssUrl} />
                )}
              </Helmet>

              <FontUploader
                title="Überschriften"
                defaultBig={32}
                defaultNormal={26}
                fontData={headingFontData}
                onChange={d => setHeadingFontData(d)}
                explainText="Diese Schriftart wird für alle Überschriften der Website und der Basket-App verwendet. Wird keine Schriftart angegeben, wird die Standard-Schriftart des Browsers verwendet."
              />

              <FontUploader
                title="Texte"
                defaultBig={22}
                defaultNormal={16}
                fontData={bodyFontData}
                onChange={d => setBodyFontData(d)}
                explainText="Diese Schriftart wird für alle normalen Texte der Website und der Basket-App verwendet (inklusive Buttons und Platzhalter-Texte für Eingabefelder). Wird keine Schriftart angegeben, wird die Standard-Schriftart des Browsers verwendet."
              />
            </Col>

            <Col span={12}>
              <div style={{ marginBottom: "1rem" }}>
                <span style={{ color: "#ff4d4f" }}>*</span> Logo{" "}
                <ExplainIcon text="Das hier hochgeladene Logo wird für Website sowie Basket verwendet. Die optimale Auflösung ist ca. 350x100, Abweichungen sind aber möglich. Erlaubte Formate sind PNG und JPG, maximale Größe ist 600x600." />
              </div>

              <Form.Item
                className="image-form-item"
                name="logo"
                label="Logo"
                rules={[{ required: true, message: "Bitte Logo hochladen" }]}
                noStyle
              >
                <ImageUpload
                  text="Logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </Form.Item>

              <Form.Item
                name="logoAlignment"
                label={
                  <div>
                    Ausrichtung Logo{" "}
                    <ExplainIcon text="Diese Option bestimmt die Ausrichtung des Logos. Auf mobilen Geräten wird das Logo immer zentriert angezeigt." />
                  </div>
                }
                style={{ marginTop: "1rem", width: "100%" }}
              >
                <Radio.Group disabled={disableFormFields}>
                  <Radio.Button value="left">Links</Radio.Button>
                  <Radio.Button value="center">Mitte</Radio.Button>
                  <Radio.Button value="right">Rechts</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <div style={{ marginBottom: "1rem" }}>
                <Switch
                  onChange={c => setShowSubLogo(c)}
                  checked={showSubLogo}
                />{" "}
                2tes Logo anzeigen{" "}
                <ExplainIcon text="Diese Option erlaubt ein weiteres Logo hochzuladen, z.B. für eine Untermarke. Dieses Logo lässt sich separat ausrichten. Auf mobilen Geräten werden die Logos untereinander angezeigt." />
              </div>

              {showSubLogo && (
                <>
                  <Form.Item noStyle name="subLogo" label="2tes Logo">
                    <ImageUpload
                      text="2. Logo"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="subLogoAlignment"
                    label="Ausrichtung 2. Logo"
                    style={{ marginTop: "1rem", width: "100%" }}
                  >
                    <Radio.Group disabled={disableFormFields}>
                      <Radio.Button value="left">Links</Radio.Button>
                      <Radio.Button value="center">Mitte</Radio.Button>
                      <Radio.Button value="right">Rechts</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
        </CardSection>

        <CardSection title="E-Mails">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="mailSenderEmail"
                label={
                  <div>
                    Absender für E-Mails (Adresse){" "}
                    <ExplainIcon text="Von dieser Adresse aus werden E-Mails für den Basket-Checkout versendet. Zur Auswahl stehen alle in Mailjet hinzugefügten Adressen." />
                  </div>
                }
                rules={[
                  { type: "email", message: "Keine gültige E-Mail Addresse" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Absender"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailSenders?.map(s => (
                    <Select.Option value={s} key={s}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="mailSenderName"
                label={
                  <div>
                    Absender für E-Mails (Name){" "}
                    <ExplainIcon text="Dieser Name wird im E-Mail-Programm des Empfängers angezeigt, und kann frei für jedes Projekt gewählt werden." />
                  </div>
                }
                rules={[
                  {
                    min: 3,
                    message: "Name muss mindestens 3 Zeichen lang sein",
                  },
                  {
                    required: true,
                    message: "Dieser Wert wird benötigt.",
                  },
                ]}
              >
                <Input placeholder="Absender" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Templates und Betreffe</Divider>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "basketCheckoutTemplateId"]}
                label={
                  <div>
                    Basket Checkout E-Mail Vorlage{" "}
                    <ExplainIcon text="Diese Vorlage wird verwendet, um Basket-Checkout E-Mails zu versenden. Zur Auswahl stehen alle in Mailjet erstellten Vorlagen." />
                  </div>
                }
              >
                <Select
                  showSearch
                  placeholder="Basket Checkout E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "basketCheckoutSubject"]}
                label={
                  <div>
                    Basket Checkout E-Mail Betreff{" "}
                    <ExplainIcon text="Dieser Betreff wird für alle Basket-Checkout E-Mails verwendet." />
                  </div>
                }
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                  {
                    required: true,
                    message: "Dieser Wert wird benötigt.",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "touchlessBundleTemplateId"]}
                label={
                  <div>
                    Touchless Content E-Mail Vorlage{" "}
                    <ExplainIcon text="Diese Vorlage wird verwendet, um Touchless Content E-Mails zu versenden. Zur Auswahl stehen alle in Mailjet erstellten Vorlagen." />
                  </div>
                }
              >
                <Select
                  showSearch
                  placeholder="Touchless Content E-Mail Template"
                  optionFilterProp="children"
                  disabled={disableFormFields}
                  loading={loadingMailOptions}
                  filterOption={(input, option) =>
                    (option?.children?.toLowerCase() ?? "").indexOf(
                      input.toLowerCase()
                    ) >= 0
                  }
                >
                  {mailOptionsData?.mailTemplates?.map(a => (
                    <Select.Option value={a.key} key={a.key}>
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["mailTemplates", "touchlessBundleSubject"]}
                label={
                  <div>
                    Touchless Content E-Mail Betreff{" "}
                    <ExplainIcon text="Dieser Betreff wird für alle Touchless Content E-Mails verwendet." />
                  </div>
                }
                rules={[
                  {
                    min: 3,
                    message: "Betreff muss mindestens 3 Zeichen lang sein",
                  },
                  {
                    required: true,
                    message: "Dieser Wert wird benötigt.",
                  },
                ]}
              >
                <Input placeholder="Betreff" disabled={disableFormFields} />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Texte</Divider>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["customValues", "emailTexts", "emailPlaceholder"]}
                label="E-Mail Eingabe Platzhalter"
              >
                <Input
                  placeholder="Ihre E-Mail..."
                  disabled={disableFormFields}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={["customValues", "emailTexts", "emailInvalid"]}
                label="E-Mail Fehlertext"
              >
                <Input
                  placeholder="Bitte geben Sie Ihre E-Mail ein!"
                  disabled={disableFormFields}
                />
              </Form.Item>
            </Col>
          </Row>
        </CardSection>

        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: "1rem" }}
          disabled={loadingProject || !!getProjectError}
          loading={creatingProject || updatingProject}
        >
          {isEditing ? "Projekt speichern" : "Projekt erstellen"}
        </Button>
      </Form>
    </Page>
  )
}

export interface FontData {
  fontFamily: string
  cssUrl: string
  sizeBig: number
  sizeNormal: number
}

export const FontUploader: React.FC<{
  title: string
  fontData: FontData | null
  onChange: (d: FontData | null) => void
  explainText: string
  defaultNormal: number
  defaultBig: number
}> = ({
  title,
  onChange,
  fontData,
  explainText,
  defaultNormal,
  defaultBig,
}) => {
  const [loading, setLoading] = useState(false)

  const onFontUpload = async (file: File) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("file", file)

    try {
      const res = await Axios.post(`${CMS_URL}/fonts`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      onChange({
        fontFamily: res.data.fontFamily,
        cssUrl: res.data.css,
        sizeNormal: fontData?.sizeNormal || defaultNormal,
        sizeBig: fontData?.sizeBig || defaultBig,
      })

      setLoading(false)
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      setLoading(false)
      const res = (err as AxiosError).response

      if (res?.status === 413) {
        message.error("Die Schriftdatei ist zu groß. (Maximal 32MB)")
        return
      }

      if (res?.status === 400 && res?.data.cause === "invalid file type") {
        message.error(
          "Die Schriftdatei hatte ein ungültiges Format. Die folgenden Formate sind erlaubt: .ttf, .woff, .woff2, .svg, .otf",
          10
        )
        return
      }

      message.error("Ein unbekannter Fehler ist aufgetreten.")
    }
  }

  return (
    <FontUploadContainer>
      <div className="header">
        {title} <ExplainIcon text={explainText} />
      </div>

      <div className="content">
        <Upload.Dragger
          style={{ width: 100 }}
          showUploadList={false}
          disabled={loading}
          beforeUpload={v => {
            onFontUpload(v)
            return false
          }}
        >
          {!loading ? (
            <FontUploadIcon style={{ paddingTop: "0.25rem" }}>
              <CloudUploadOutlined />
            </FontUploadIcon>
          ) : (
            <FontUploadIcon style={{ paddingTop: "0.25rem" }}>
              <LoadingOutlined spin />
            </FontUploadIcon>
          )}
        </Upload.Dragger>

        <div className="preview">
          <div className="font-name">{fontData?.fontFamily ?? "Standard"}</div>
          <div
            className="font-letters"
            style={{ fontFamily: fontData?.fontFamily ?? "sans-serif" }}
          >
            <div style={{ fontSize: `${fontData?.sizeBig || defaultBig}px` }}>
              Schrift für große {title}
            </div>

            <div
              style={{ fontSize: `${fontData?.sizeNormal || defaultNormal}px` }}
            >
              Schrift für kleine {title}
            </div>
          </div>
        </div>
      </div>

      <div className="sizes">
        <div className="normal">
          <div>
            Schriftgröße Normal{" "}
            <ExplainIcon
              text={`Die Schriftgröße für normale ${title} in Pixel. Muss zwischen 10 und 80 liegen.`}
            />
          </div>
          <Input
            className="input"
            type="number"
            defaultValue={defaultNormal}
            value={fontData?.sizeNormal}
            min={10}
            max={80}
            onChange={s =>
              onChange({
                ...fontData!,
                sizeNormal: s.target.valueAsNumber,
              })
            }
          />
        </div>

        <div className="big">
          <div>
            Schriftgröße Groß{" "}
            <ExplainIcon
              text={`Die Schriftgröße für große ${title} in Pixel. Muss zwischen 10 und 80 liegen.`}
            />
          </div>
          <Input
            className="input"
            type="number"
            defaultValue={defaultBig}
            value={fontData?.sizeBig}
            min={10}
            max={80}
            onChange={s =>
              onChange({
                ...fontData!,
                sizeBig: s.target.valueAsNumber,
              })
            }
          />
        </div>
      </div>
    </FontUploadContainer>
  )
}

const FontUploadContainer = styled.div`
  margin-bottom: 1.5rem;

  .sizes {
    margin-top: 1rem;
    display: flex;

    .normal,
    .big {
      width: 200px;
      display: flex;
      flex-direction: column;

      > div:first-child {
        margin-bottom: 4px;
      }
    }

    .input {
      width: 100px;
    }
  }

  .header {
    margin-bottom: 0.5rem;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .preview {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    flex: 1;
    width: calc(100% - 1rem - 100px);

    .font-name {
      font-size: 1.2em;
    }

    .font-letters {
      letter-spacing: 0.1rem;
      font-size: 1.1em;
      word-wrap: break-word;
      opacity: 0.6;
    }
  }
`

const FontUploadIcon = styled.div`
  color: #40a9ff;
  font-size: 48px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`

const ModalInput = styled(Input)`
  margin-top: 1rem;
`

export default CreateProject

import { Role } from "../graphql-types-global"

export const renderRole = (role: Role) => {
  switch (role) {
    case Role.ADMIN:
      return "Administrator"
    case Role.USER:
      return "Benutzer"
  }
}

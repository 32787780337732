import React, { useState, useEffect, createContext, ReactNode } from "react"
import _ from "lodash"
import styled, { css } from "styled-components"
import { useLocalStorage } from "react-use"
import { timeout } from "./util/util"
import {
  useHistory,
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from "react-router"
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Breadcrumb,
  Modal,
  Select,
  message,
} from "antd"
import { useQuery } from "@apollo/client"
import { setToken as setAuthToken, hasToken, CMS_URL, APP_NAME } from "."
import {
  UserOutlined,
  DashboardOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  SettingOutlined,
  CloudOutlined,
  FolderOpenOutlined,
  LoadingOutlined,
  HomeOutlined,
} from "@ant-design/icons"
import { Link } from "react-router-dom"
import { MeQuery_me, MeQuery } from "./types/MeQuery"
import QRCode from "qrcode.react"
import Login from "./public/Login"
import Activate from "./public/Activate"
import RequestPasswordReset from "./public/RequestPasswordReset"
import ResetPassword from "./public/ResetPassword"
import { Role } from "./graphql-types-global"
import Users from "./users/Users"
import { renderRole } from "./util/formats"
import UpdateInstance from "./instance/UpdateInstance"
import Projects from "./projects/Projects"
import Downloadables from "./downloadables/Downloadables"
import {
  ListProjectsQuery,
  ListProjectsQuery_projects_projects,
} from "./types/ListProjectsQuery"
import { ME_QUERY, LIST_PROJECTS_QUERY } from "./util/queries"
import { isNoUserError } from "./util/errors"
import Baskets from "./baskets/Baskets"
import CreateUser from "./users/CreateUser"
import ResendActivation from "./public/ResendActivation"
import Dashboard from "./Dashboard"
import { Helmet } from "react-helmet"

const { Header, Content, Footer, Sider } = Layout

export const UserContext = createContext<MeQuery_me | null>(null)
export const ProjectContext = createContext<{
  activeProject: ListProjectsQuery_projects_projects | null
  setActiveProject: (id: string | undefined) => void
}>({ activeProject: null, setActiveProject: () => {} })

interface NavLink {
  url?: string
  projectUrl?: (projectId: string) => string
  key: string
  name: string
  icon: ReactNode
  matchExact?: boolean
  subLinks?: SubLink[]
  admin?: boolean
}

interface SubLink {
  url?: string
  projectUrl?: (projectId: string) => string
  key: string
  name: string
}

interface ActiveRoute {
  matched: boolean
  key: string
  sub?: string
}

const navLinks: NavLink[] = [
  {
    url: "/",
    key: "home",
    name: "Übersicht",
    icon: <DashboardOutlined />,
    matchExact: true,
  },
  {
    name: "Projekt",
    key: "project",
    icon: <FolderOpenOutlined />,
    subLinks: [
      {
        name: "Einstellungen",
        url: "/projects",
        key: "project-settings",
        projectUrl: (projectId: string) => `/projects/${projectId}/edit`,
      },
      {
        name: "Basket",
        url: "/projects/basket/edit",
        key: "project-basket",
        projectUrl: (projectId: string) => `/projects/${projectId}/basket/edit`,
      },
      {
        name: "Website",
        url: "/projects/website/edit",
        key: "project-website",
        projectUrl: (projectId: string) =>
          `/projects/${projectId}/website/edit`,
      },
      {
        name: "i-Points",
        url: "/projects/ipoint/edit",
        key: "project-ipoint",
        projectUrl: (projectId: string) => `/projects/${projectId}/ipoint/edit`,
      },
      {
        name: "Touchless",
        url: "/projects/touchless/edit",
        key: "project-touchless",
        projectUrl: (projectId: string) =>
          `/projects/${projectId}/touchless/edit`,
      },
      {
        url: "/downloadables",
        projectUrl: (projectId: string) => `/downloadables/${projectId}`,
        key: "downloadables",
        name: "Downloadables",
      },
      {
        url: "/baskets",
        projectUrl: (projectId: string) => `/baskets/${projectId}`,
        key: "baskets",
        name: "Baskets",
      },
    ],
  },
  {
    url: "/instance",
    name: "Instanz",
    key: "instance",
    icon: <CloudOutlined />,
    admin: true,
  },
  {
    url: "/users",
    name: "Benutzer",
    key: "users",
    icon: <UserOutlined />,
    admin: true,
  },
]

const breadcrumbItems: { [key: string]: string } = {
  "/users": "Alle Benutzer",
  "/users/create": "Neuer Benutzer",
  "/users/id:users": "Benutzer",
  "/users/id:users/edit": "Benutzer Bearbeiten",

  "/profile": "Profil bearbeiten",

  "/instance": "Instanz bearbeiten",

  "/projects": "Projekte",
  "/projects/create": "Projekt erstellen",
  "/projects/id:projects": "Projekt",
  "/projects/id:projects/edit": "Projekt bearbeiten",
  "/projects/id:projects/basket/edit": "Basket für Projekt bearbeiten",
  "/projects/id:projects/website/edit": "Website für Projekt bearbeiten",
  "/projects/id:projects/ipoint/edit": "iPoint für Projekt bearbeiten",
  "/projects/id:projects/touchless/edit": "Touchless für Projekt bearbeiten",

  "/downloadables": "Downloadables",
  "/downloadables/id:projects": "Downloadables für Projekt",

  "/baskets": "Baskets",
  "/baskets/id:projects": "Baskets für Projekt",
}

const App = () => {
  const [token, setToken] = useLocalStorage<string | null>("token", null)

  const [loggingOut, setLoggingOut] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const [activeRoutes, setActiveRoutes] = useState<string[]>([])
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([])

  const [activeProjectId, setActiveProjectId] = useState<string | undefined>()
  const [
    activeProject,
    _setActiveProject,
  ] = useState<ListProjectsQuery_projects_projects | null>(null)

  useEffect(() => {
    const matchedRoutes = navLinks
      .flatMap<ActiveRoute, NavLink>(link => {
        if (!!link.url) {
          const matched = matchPath(location.pathname, {
            path: link.url,
            exact: link.matchExact,
            strict: false,
          })

          return [{ matched: !!matched, key: link.key }]
        } else {
          return (link.subLinks ?? []).map(subLink => {
            const matched = matchPath(location.pathname, {
              path:
                activeProjectId && subLink.projectUrl
                  ? subLink.projectUrl(activeProjectId)
                  : subLink.url,
              exact: !activeProjectId && !!subLink.projectUrl,
              strict: false,
            })

            return { matched: !!matched, key: subLink.key, sub: link.key }
          })
        }
      })
      .filter(({ matched }) => matched)

    const routes = matchedRoutes.map(({ key }) => key)
    const submenus = _.uniq(
      matchedRoutes.filter(({ sub }) => !!sub).map(({ sub }) => sub!!)
    )

    setActiveRoutes(routes)
    setOpenSubmenus(submenus)
  }, [location, activeProjectId])

  useEffect(() => {
    if (!!token) setAuthToken(token)
    else setAuthToken(null)
  }, [token])

  const pathParts = location.pathname
    .split("/")
    .filter(it => !!it)
    .map(part => (part.indexOf(".") !== -1 ? `id:${part.split(".")[1]}` : part))

  const breadcrumbParts = pathParts
    .map((_part, index) => {
      const url = `/${pathParts.slice(0, index + 1).join("/")}`
      return breadcrumbItems[url] ? (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbItems[url] ?? "Seite"}</Link>
        </Breadcrumb.Item>
      ) : null
    })
    .filter(it => !!it)

  const {
    client,
    loading: gettingUser,
    data: userData,
    error: gettingUserError,
  } = useQuery<MeQuery>(ME_QUERY, {
    fetchPolicy: "network-only",
    skip: !hasToken(),
    onError: err => {
      if (err.graphQLErrors?.[0]?.message === "no-user") logout(true)
    },
  })

  const { data: projectsData, loading: loadingProjects } = useQuery<
    ListProjectsQuery
  >(LIST_PROJECTS_QUERY, {
    skip: !hasToken(),
    onCompleted: () => {
      if (!!activeProjectId) setActiveProject(activeProjectId)
    },
  })

  const setActiveProject = (id?: string) => {
    setActiveProjectId(id)
    const project = projectsData?.projects?.projects?.find(it => it.id === id)
    _setActiveProject(project ?? null)
  }

  // Show a global loading overlay if either:
  // - The user is logging out,
  // - we're currently fetching the user, or
  // - the user is supposed to be logged in and we haven't
  //   validated it yet
  const isLoading = loggingOut || gettingUser || (!userData && hasToken())

  const logout = async (showPrompt: boolean = false) => {
    setLoggingOut(true)
    await timeout(400)

    setToken(null)
    client?.stop()
    client?.clearStore()

    history.push("/login")
    setLoggingOut(false)

    if (showPrompt) message.error("Bitte loggen sie sich erneut ein.")
  }

  useEffect(() => {
    if (
      token &&
      !gettingUser &&
      gettingUserError &&
      isNoUserError(gettingUserError)
    )
      logout(true)
  }, [gettingUserError])

  const [authModalVisible, setAuthModalVisible] = useState(false)

  const linksToShow =
    userData?.me?.role === Role.ADMIN
      ? navLinks
      : navLinks.filter(it => !it.admin)

  return (
    <AppContainer isLoading={isLoading}>
      <Helmet>
        <title>{APP_NAME} HUB-i CMS</title>
      </Helmet>

      <LoadingOverlay isLoading={isLoading}>
        <SizedLoadingIcon spin />
      </LoadingOverlay>

      {!hasToken() && (
        <Switch>
          <Route path="/login">
            <Login setToken={setToken} />
          </Route>

          <Route path="/request-password-reset">
            <RequestPasswordReset />
          </Route>

          <Route path="/reset-password/:code">
            <ResetPassword setToken={setToken} />
          </Route>

          <Route path="/resend-activation">
            <ResendActivation />
          </Route>

          <Route path="/activate/:code">
            <Activate setToken={setToken} />
          </Route>

          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      )}

      {hasToken() && userData && (
        <UserContext.Provider value={userData.me}>
          <Modal
            centered
            okButtonProps={{ hidden: true }}
            visible={authModalVisible}
            onCancel={() => setAuthModalVisible(false)}
            cancelText="Schließen"
            title="Setup-App autorisieren"
          >
            <QRContainer>
              <QRCode
                renderAs="svg"
                value={token ? `${CMS_URL}|${token}` : ""}
                style={{ width: 400, height: 400 }}
              />
            </QRContainer>
          </Modal>

          <AppLayout>
            <AppSidebar width={280} style={{ zIndex: 10 }}>
              <InstanceTitle>{APP_NAME} CMS</InstanceTitle>

              <ProfileSection>
                <AvatarContainer>
                  <Link to="/">
                    <Avatar size={128} icon={<UserOutlined />} />
                  </Link>

                  <Link to={`/users/${userData?.me?.id}/edit`}>
                    <SettingsButton
                      type="primary"
                      size="large"
                      shape="circle"
                      icon={<SettingOutlined />}
                    />
                  </Link>
                </AvatarContainer>

                <UserRole>{renderRole(userData?.me?.role ?? "USER")}</UserRole>
                <UserName>{userData?.me?.name}</UserName>
                <UserEmail>{userData?.me?.email}</UserEmail>
              </ProfileSection>

              <ProjectSelectContainer>
                <Select
                  loading={loadingProjects}
                  disabled={loadingProjects}
                  placeholder="Projekt auswählen"
                  value={activeProjectId ?? undefined}
                  onChange={value => setActiveProject(value as string)}
                >
                  {projectsData?.projects?.projects?.map(project => (
                    <Select.Option value={project.id} key={project.id}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select>
              </ProjectSelectContainer>

              <AppMenu
                theme="dark"
                selectedKeys={activeRoutes}
                mode="inline"
                defaultOpenKeys={openSubmenus}
              >
                {linksToShow.map(link =>
                  (link.subLinks?.length ?? 0) === 0 ? (
                    <Menu.Item key={link.key}>
                      <Link
                        to={
                          link.projectUrl && activeProjectId
                            ? link.projectUrl(activeProjectId)
                            : link.url!!
                        }
                      >
                        {link.icon}
                        <span>{link.name}</span>
                      </Link>
                    </Menu.Item>
                  ) : (
                    <Menu.SubMenu
                      key={link.key}
                      title={
                        <>
                          {link.icon}
                          <span>{link.name}</span>
                        </>
                      }
                    >
                      {link.subLinks!!.map(subLink => (
                        <Menu.Item key={subLink.key}>
                          <Link
                            to={
                              subLink.projectUrl && activeProjectId
                                ? subLink.projectUrl(activeProjectId)
                                : subLink.url!!
                            }
                          >
                            <span>{subLink.name}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  )
                )}
              </AppMenu>
            </AppSidebar>

            <Layout>
              <AppHeader>
                <Breadcrumb>
                  <Breadcrumb.Item href="/" key="home">
                    <HomeOutlined />
                  </Breadcrumb.Item>

                  {breadcrumbParts}
                </Breadcrumb>

                <AppHeaderActions>
                  <Button onClick={() => setAuthModalVisible(true)}>
                    <QrcodeOutlined />
                    Setup-App autorisieren
                  </Button>

                  <Button onClick={() => logout()}>
                    <LogoutOutlined />
                    Logout
                  </Button>
                </AppHeaderActions>
              </AppHeader>

              <StyledContent>
                <ProjectContext.Provider
                  value={{ activeProject, setActiveProject }}
                >
                  <Switch>
                    <Route path="/" exact>
                      <Dashboard isAdmin={userData.me.role === Role.ADMIN} />
                    </Route>

                    <Route path="/profile" exact>
                      <CreateUser />
                    </Route>

                    <Route path="/projects">
                      <Projects />
                    </Route>

                    <Route path="/downloadables">
                      <Downloadables />
                    </Route>

                    <Route path="/baskets">
                      <Baskets />
                    </Route>

                    {userData?.me?.role === Role.ADMIN && (
                      <>
                        <Route path="/users">
                          <Users />
                        </Route>

                        <Route path="/instance">
                          <UpdateInstance />
                        </Route>
                      </>
                    )}

                    <Route>
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </ProjectContext.Provider>
              </StyledContent>

              <StyledFooter>
                HUB-i CMS{" "}
                <span style={{ opacity: 0.4 }}>
                  v{process.env.REACT_APP_VERSION}
                </span>
              </StyledFooter>
            </Layout>
          </AppLayout>
        </UserContext.Provider>
      )}
    </AppContainer>
  )
}

const InstanceTitle = styled.h1`
  width: 100%;
  text-align: center;
  color: white;
  margin: 1rem 0 0 0;
  font-weight: bold;
`

const AppMenu = styled(Menu)`
  .ant-menu-item-only-child .anticon {
    margin-right: 10px;
  }
`

const UserRole = styled.div`
  color: #fff;
  opacity: 0.4;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: 1.5rem;
  font-size: 0.8em;
  font-weight: bold;
`

const UserName = styled.div`
  color: #fff;
  font-size: 1.5em;
  line-height: 1.6;
  font-weight: bold;
`

const UserEmail = styled.div`
  color: #bbb;
`

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  margin: 2rem 0 1.5rem 0;
`

const AppHeader = styled(Header)`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
`

const AppHeaderActions = styled.div`
  button:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const AppLayout = styled(Layout)`
  min-height: 100vh;
  margin-left: 280px;
`

const SizedLoadingIcon = styled(LoadingOutlined)`
  font-size: 3rem;
`

const AppSidebar = styled(Sider)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
`

const AppContainer = styled.div<{ isLoading: boolean }>`
  .ant-layout.ant-layout-has-sider > .ant-layout {
    max-width: 100%;
    overflow-x: unset;
  }

  ${(props: any) =>
    props.isLoading &&
    css`
      overflow: hidden;
    `}
`

const LoadingOverlay = styled.div<{ isLoading: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    !props.isLoading &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`

const StyledContent = styled(Content)`
  background: #fff;
`

const StyledFooter = styled(Footer)`
  background: #fff;
  border-top: 1px solid #f0f0f0;
`

const QRContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AvatarContainer = styled.div`
  position: relative;
`

const SettingsButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
`

const ProjectSelectContainer = styled.div`
  margin-bottom: 1.5rem;
  padding: 0 2rem;

  > div {
    width: 100%;
  }
`

export default App

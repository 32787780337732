import React from "react"
import { Page, ListHeader } from "./util/page"
import { Row, Col, Button, Card, PageHeader } from "antd"
import styled from "styled-components"
import { UserAddOutlined, FolderAddOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

const Dashboard: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  return (
    <Page>
      <ListHeader>
        <PageHeader title="Übersicht" />
      </ListHeader>

      <DashboardRow gutter={24} style={{ marginTop: "1rem" }}>
        <Col span={12}>
          <DashboardCard title="Aktionen">
            {isAdmin && (
              <Row gutter={16}>
                <Col span={12}>
                  <Link to="/users/create">
                    <QuickActionsButton
                      type="primary"
                      icon={<UserAddOutlined />}
                    >
                      Benutzer erstellen
                    </QuickActionsButton>
                  </Link>
                </Col>

                <Col span={12}>
                  <Link to="/projects/create">
                    <QuickActionsButton
                      type="primary"
                      icon={<FolderAddOutlined />}
                    >
                      Projekt erstellen
                    </QuickActionsButton>
                  </Link>
                </Col>
              </Row>
            )}
          </DashboardCard>
        </Col>
      </DashboardRow>
    </Page>
  )
}

const DashboardCard = styled(Card)`
  height: 100%;
`

const DashboardRow = styled(Row)`
  margin-bottom: 24px;
`

const QuickActionsButton = styled(Button)`
  width: 100%;

  :not(:last-child) {
    margin-bottom: 16px;
  }
`

export default Dashboard

import _ from "lodash"
import { useMutation } from "@apollo/client"
import { useQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import { CustomColorSelect } from "@g51/hubi-components/components/EditableBar"
import { Form, Radio, Col, Row, Button, message } from "antd"
import { useForm } from "antd/lib/form/Form"
import React from "react"
import IPointCard from "../components/IPointCard"
import IPointImageUpload from "../components/IPointImageUpload"
import { ListIPoints_ipoints } from "./types/ListIPoints"
import {
  GetDestinations,
  GetDestinationsVariables,
} from "./types/GetDestinations"
import { GetStartpage, GetStartpageVariables } from "./types/GetStartpage"
import { UpdateIPointStartpage } from "../graphql-types-global"
import FontUpload from "../components/FontUpload"
import { Tooltip } from "../util/util"

export const GET_STARTPAGE_QUERY = gql`
  query GetStartpage($ipointId: ID!) {
    ipointStartpage(ipointId: $ipointId) {
      view_type
      bg_img
      home_img
      style {
        icon_basket
        icon_basket_count_bg
        font_type_basket_count
        color_basket_count
      }
      main_page {
        page_id
        page_name
        bg_img
        destination_id
        menu_items {
          name
          image
          pos
          destination_id
        }
      }
      sub_pages {
        page_id
        page_name
        bg_img
        destination_id
        menu_items {
          name
          image
          pos
          destination_id
        }
      }
    }
  }
`

export const GET_DESTINATIONS_QUERY = gql`
  query GetDestinations($ipointId: ID!) {
    ipointDestinations(ipointId: $ipointId) {
      id
      name
      type
    }
  }
`

const UPDATE_STARTPAGE_MUTATION = gql`
  mutation UpdateStartpageMutation(
    $ipointId: ID!
    $page: UpdateIPointStartpage!
  ) {
    updateIPointStartpage(ipointId: $ipointId, page: $page) {
      view_type
    }
  }
`

const UpdateIPointStartpageComponent: React.FC<{
  ipoint: ListIPoints_ipoints
}> = ({ ipoint }) => {
  const [form] = useForm()
  const startpageQuery = useQuery<GetStartpage, GetStartpageVariables>(
    GET_STARTPAGE_QUERY,
    {
      variables: { ipointId: ipoint.id },
      onCompleted: data => {
        if (!data.ipointStartpage) return

        const menu_items =
          data.ipointStartpage.main_page?.menu_items.reduce(
            (acc, it) => ({
              ...acc,
              [`menu_${it.pos + 1}`]: it.image,
              [`menu_${it.pos + 1}_destination`]: it.destination_id,
            }),
            {}
          ) ?? {}

        const count_font_parts = data.ipointStartpage.style.font_type_basket_count.split(
          "::"
        )

        const values = {
          ...menu_items,
          ..._.pick(data.ipointStartpage, "view_type", "home_img", "bg_img"),
          ..._.omit(data.ipointStartpage.style, "font_type_basket_count"),
          font_type_basket_count:
            count_font_parts.length === 2
              ? {
                  cssUrl: count_font_parts[0],
                  fontFamily: count_font_parts[1],
                }
              : null,
        }

        form.setFieldsValue(values)
      },
    }
  )

  const destinationsQuery = useQuery<GetDestinations, GetDestinationsVariables>(
    GET_DESTINATIONS_QUERY,
    {
      variables: { ipointId: ipoint.id },
    }
  )

  const dests = destinationsQuery.data?.ipointDestinations ?? []

  const onSave = (data: any) => {
    if (!startpageQuery.data) return

    const menu_items = [...Array(8).keys()]
      .map(i => {
        const img = data[`menu_${i + 1}`]
        const dest = data[`menu_${i + 1}_destination`]

        if (!img) return null

        return {
          name: `Menu ${i + 1}`,
          pos: i,
          destination_id: dest ?? "",
          image: img,
        }
      })
      .filter(it => it !== null)
      .map(it => it!!)

    const font_type_basket_count = data.font_type_basket_count
      ? `${data.font_type_basket_count.cssUrl}::${data.font_type_basket_count.fontFamily}`
      : ``

    const page: UpdateIPointStartpage = {
      view_type: data.view_type,
      bg_img: data.bg_img ?? "",
      home_img: data.home_img ?? "",
      style: {
        color_basket_count: data.color_basket_count ?? "",
        font_type_basket_count,
        icon_basket_count_bg: data.icon_basket_count_bg ?? "",
        icon_basket: data.icon_basket ?? "",
      },
      main_page: {
        page_id: "",
        page_name: "",
        destination_id: "",
        bg_img: "",
        menu_items,
      },
    }

    console.log(page)

    updateStartpageMutation({
      variables: { page, ipointId: ipoint.id },
    })
  }

  const [updateStartpageMutation] = useMutation(UPDATE_STARTPAGE_MUTATION, {
    onCompleted: () => {
      message.success("Erfolgreich gespeichert")
    },
    refetchQueries: [
      {
        query: GET_STARTPAGE_QUERY,
        variables: { ipointId: ipoint.id },
      },
    ],
  })

  return (
    <>
      {startpageQuery.data && (
        <>
          <h2 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            Start Page für {ipoint.project_name}:
          </h2>

          <Form
            form={form}
            onFinish={data => onSave(data)}
            initialValues={{
              view_type: "checkout",
            }}
          >
            <Row gutter={24}>
              <Col span={4}>
                <IPointCard>
                  <span>iPoint Type</span>
                  <Form.Item name="view_type" noStyle>
                    <Radio.Group
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Radio value="checkout">Checkout</Radio>
                      <Radio value="single_page">Single Page</Radio>
                      <Radio value="single_menu">Single Menu</Radio>
                      <Radio value="multi_menu">Menu with Submenus</Radio>
                    </Radio.Group>
                  </Form.Item>
                </IPointCard>
              </Col>

              <Col span={5}>
                <IPointCard>
                  <span>Basket Count Color</span>
                  <Form.Item
                    name="color_basket_count"
                    valuePropName="color"
                    className="color-control"
                  >
                    <CustomColorSelect defaultColor="#fff" leftSide />
                  </Form.Item>
                </IPointCard>
              </Col>

              <Col span={5}>
                <IPointCard>
                  <span style={{ display: "block" }}>
                    Basket Count Schriftart
                  </span>
                  <Form.Item name="font_type_basket_count">
                    <FontUpload />
                  </Form.Item>
                </IPointCard>
              </Col>

              <Col span={5}>
                <Tooltip text="Auflösung: 140x140">
                  <IPointImageUpload label="Basket Button" name="icon_basket" />
                </Tooltip>
              </Col>

              <Col span={5}>
                <Tooltip text="Auflösung: 80x80">
                  <IPointImageUpload
                    label="Basket Count Background"
                    name="icon_basket_count_bg"
                  />
                </Tooltip>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>
                <Tooltip text="Auflösung: 2732x1049">
                  <IPointImageUpload label="Background Image" name="bg_img" />
                </Tooltip>
              </Col>

              {[...Array(4).keys()]
                .map(idx => idx + 1)
                .map(idx => (
                  <Col span={5} key={idx}>
                    <Tooltip text="Auflösung: 512x512">
                      <IPointImageUpload
                        name={`menu_${idx}`}
                        label={`Menu ${idx}`}
                        dests={dests}
                        isLink
                      />
                    </Tooltip>
                  </Col>
                ))}
            </Row>

            <Row gutter={24}>
              <Col span={4}>
                <Tooltip text="Auflösung: 140x140">
                  <IPointImageUpload label="Home Button" name="home_img" />
                </Tooltip>
              </Col>

              {[...Array(4).keys()]
                .map(idx => idx + 5)
                .map(idx => (
                  <Col span={5} key={idx}>
                    <Tooltip text="Auflösung: 512x512">
                      <IPointImageUpload
                        name={`menu_${idx}`}
                        label={`Menu ${idx}`}
                        dests={dests}
                        isLink
                      />
                    </Tooltip>
                  </Col>
                ))}
            </Row>

            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: 15 }}
              >
                Änderungen Speichern
              </Button>
            </Col>
          </Form>
        </>
      )}
    </>
  )
}

export default UpdateIPointStartpageComponent

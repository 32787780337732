import _ from "lodash"

const illegalRegex = /[/?<>\\:*|":]/g
const reservedRegex = /^\.+$/
const windowsReservedRegex = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i

// eslint-disable-next-line no-control-regex
const controlRegex = /[\x00-\x1f\x80-\x9f]/g

export const sanitizeFilename = (input: string) => {
  const sanitized = input
    .replace(illegalRegex, "")
    .replace(controlRegex, "")
    .replace(reservedRegex, "")
    .replace(windowsReservedRegex, "")

  return _.truncate(sanitized, { length: 255 })
}

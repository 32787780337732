import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Button } from "antd"

interface EditableSpanProps {
  value?: string
  style?: React.CSSProperties
  className?: string
  onChange?: (v?: string) => void
  disabled?: boolean
  placeholder?: string
  autofocus?: boolean
  onSave?: (v?: string) => void
  saveText?: string
  saveDisabled?: boolean
  canSave?: boolean
  loading?: boolean
}

const EditableSpan: React.FC<EditableSpanProps> = ({
  value,
  style,
  className,
  onChange,
  disabled,
  placeholder,
  autofocus,
  onSave,
  saveText = "Speichern",
  saveDisabled,
  canSave,
  loading,
}) => {
  const [editableValue, setEditableValue] = useState("")
  const _onChange = (v: string) => {
    setEditableValue(v)
    onChange && onChange(v)
  }

  const [focussed, setFocussed] = useState(false)

  return (
    <EditableContainer
      className={className}
      style={style}
      focussed={focussed}
      disabled={disabled}
    >
      {!disabled && (
        <>
          <EditableInput
            onFocus={() => setFocussed(true)}
            onBlur={() => setFocussed(false)}
            value={value || editableValue}
            onChange={(e) => _onChange(e.target.value)}
            placeholder={placeholder}
            autoFocus={autofocus}
            onKeyDown={(ev) => ev.keyCode === 13 && onSave && onSave(value)}
            disabled={loading}
          />

          <SaveButton saveDisabled={saveDisabled}>
            <Button
              loading={loading}
              disabled={saveDisabled || !canSave}
              type="primary"
              onClick={() => onSave && onSave(value)}
            >
              {saveText}
            </Button>
          </SaveButton>
        </>
      )}

      {disabled && <span>{value || editableValue}</span>}
    </EditableContainer>
  )
}

const EditableContainer = styled.div<{
  focussed?: boolean
  disabled?: boolean
}>`
  position: relative;
  z-index: 0;
  background: transparent;
  font-size: inherit;
  display: flex;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover::before {
        opacity: 0.4;
      }
    `}

  &::before {
    position: absolute;
    display: block;
    content: "";
    border-radius: 2px;
    top: -0.25em;
    bottom: -0.25em;
    left: -0.5em;
    right: -0.25em;
    z-index: -1;
    border: 1px solid #40a9ff;
    opacity: 0;
    transition: opacity 0.15s linear;

    ${(props) =>
      props.focussed &&
      css`
        opacity: 1 !important;
      `}
  }
`

const SaveButton = styled.div<{ saveDisabled?: boolean }>`
  opacity: 1;
  transition: opacity 0.15s linear;

  ${(props) =>
    props.saveDisabled &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`

const EditableInput = styled.input.attrs({ type: "text" })`
  flex: 1;
  outline: none;
  border: none;
  padding: 0;
  background: transparent;

  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
`

export default EditableSpan

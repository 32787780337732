import React, { useContext } from "react"
import { Switch, useRouteMatch, Route, Redirect, Link } from "react-router-dom"
import CreateProject from "./CreateProject"
import { ProjectContext, UserContext } from "../App"
import { Empty, Button } from "antd"
import styled from "styled-components"
import { Role } from "../graphql-types-global"
import { FolderAddOutlined } from "@ant-design/icons"
import UpdateBasket from "./UpdateBasket"
import UpdateWebsite from "./UpdateWebsite"
import UpdateIPoint from "./UpdateIPoint"
import SetupProject from "./SetupProject"
import UpdateTouchless from "./UpdateTouchless"

const Projects = () => {
  const match = useRouteMatch()
  const { activeProject } = useContext(ProjectContext)
  const user = useContext(UserContext)

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <CreateProject />
      </Route>

      <Route path={`${match.path}/basket/edit`}>
        {activeProject && (
          <Redirect to={`${match.path}/${activeProject.id}/basket/edit`} />
        )}

        {!activeProject && (
          <ProjectSelect showCreate={user?.role === Role.ADMIN} />
        )}
      </Route>

      <Route path={`${match.path}/:id/basket/edit`}>
        <UpdateBasket />
      </Route>

      <Route path={`${match.path}/website/edit`}>
        {activeProject && (
          <Redirect to={`${match.path}/${activeProject.id}/website/edit`} />
        )}

        {!activeProject && (
          <ProjectSelect showCreate={user?.role === Role.ADMIN} />
        )}
      </Route>

      <Route path={`${match.path}/:id/website/edit`}>
        <UpdateWebsite />
      </Route>

      <Route path={`${match.path}/ipoint/edit`}>
        {activeProject && (
          <Redirect to={`${match.path}/${activeProject.id}/ipoint/edit`} />
        )}

        {!activeProject && (
          <ProjectSelect showCreate={user?.role === Role.ADMIN} />
        )}
      </Route>

      <Route path={`${match.path}/:id/ipoint/edit`}>
        <UpdateIPoint />
      </Route>

      <Route path={`${match.path}/:id/touchless/edit`}>
        <UpdateTouchless />
      </Route>

      <Route path={`${match.path}/:id/setup`}>
        <SetupProject />
      </Route>

      <Route path={`${match.path}/:id/edit`}>
        <CreateProject />
      </Route>

      <Route path={match.path} exact>
        {activeProject && (
          <Redirect to={`${match.path}/${activeProject.id}/edit`} />
        )}

        {!activeProject && (
          <ProjectSelect showCreate={user?.role === Role.ADMIN} />
        )}
      </Route>

      <Route path={`${match.path}/*`}>
        <Redirect to="/projects" />
      </Route>
    </Switch>
  )
}

const EmptyContainer = styled.div`
  margin-top: 4rem;
`

const ProjectSelect: React.FC<{ showCreate: boolean }> = ({ showCreate }) => (
  <EmptyContainer>
    <Empty description="Bitte wählen Sie ein Projekt aus">
      {showCreate && (
        <Link to="/projects/create">
          <Button type="primary" icon={<FolderAddOutlined />}>
            Neues Projekt
          </Button>
        </Link>
      )}
    </Empty>
  </EmptyContainer>
)

export default Projects

import React, { useEffect, useState } from "react"
import { Upload, Button } from "antd"
import { PictureOutlined, CloseOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { CMS_URL, token } from ".."
import axios from "axios"
import { timeout } from "../util/util"

export const useImageUpload = (onChange?: (v: string | undefined) => void) => {
  const [file, setFile] = useState<Blob | null>(null)
  const [loading, setLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [hasUploadError, setHasUploadError] = useState(false)

  useEffect(() => {
    if (file) doUpload()
  }, [file])

  const doUpload = async () => {
    setLoading(true)

    const formData = new FormData()
    formData.append("file", file!!)

    try {
      const res = await axios.post(`${CMS_URL}/files/image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: ({ loaded, total }) => {
          setUploadProgress((loaded / total) * 100)
        },
      })

      setUploadProgress(100)
      onChange && onChange(res.data.fileId)
      await timeout(500)

      setFile(null)
      setLoading(false)
    } catch (err) {
      console.log(`error while uploading: ${err}`)
      setHasUploadError(true)
      await timeout(500)

      setFile(null)
      setLoading(false)
      setHasUploadError(false)
    }
  }

  return {
    setFile,
    loading,
    uploadProgress,
    hasUploadError,
    doUpload,
  }
}

interface ImageUploadProps {
  text?: string
  disabled?: boolean
  icon?: React.ReactNode
  style?: React.CSSProperties
  value?: string
  onChange?: (v: string | undefined) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  text,
  disabled,
  icon = <PictureOutlined />,
  style,
  value,
  onChange,
}) => {
  const imageUploadProps = useImageUpload(onChange)
  const { setFile } = imageUploadProps

  return (
    <>
      <Container style={style} className="image-container">
        <Upload.Dragger
          showUploadList={false}
          multiple={false}
          className="dragger"
          beforeUpload={(value) => {
            setFile(value)
            return false
          }}
          disabled={disabled}
        >
          {value ? (
            <div className="uploaded">
              <UploadedImage
                src={`${CMS_URL}/images/${value}`}
                style={{ padding: 0 }}
              />
              <ResetButton
                shape="circle"
                danger
                onClick={(e) => {
                  e.stopPropagation()
                  onChange && onChange("")
                }}
                icon={<CloseOutlined />}
              />
            </div>
          ) : (
            <>
              <p className="ant-upload-drag-icon">{icon}</p>
              <p className="ant-upload-text">{text}</p>
            </>
          )}
        </Upload.Dragger>
      </Container>
    </>
  )
}

const ResetButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;
`

const UploadedImage = styled.img`
  object-fit: contain;
  pointer-events: none;
`

const Container = styled.div`
  width: 100%;
  min-height: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    width: 100%;
    min-height: 300px;
  }

  .dragger {
    width: 100%;
    height: 100%;
  }

  .uploaded {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 300px;
    padding: 0 0.5rem;
    background: #fff;
    border-radius: 4px;
  }
`

export default ImageUpload

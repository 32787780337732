import { Form, Select } from "antd"
import React from "react"
import { GetDestinations_ipointDestinations } from "../projects/types/GetDestinations"
import ImageUpload from "./ImageUpload"
import IPointCard from "./IPointCard"

const IPointImageUpload: React.FC<{
  label: string
  name: string
  dests?: GetDestinations_ipointDestinations[]
  isLink?: boolean
}> = ({ dests = [], name, label, isLink = false }) => (
  <IPointCard>
    <span>{label}</span>
    <Form.Item noStyle name={name}>
      <ImageUpload text={label} />
    </Form.Item>

    {isLink && (
      <Form.Item
        name={`${name}_destination`}
        label="Link zu"
        style={{ marginBottom: 0, marginTop: 12 }}
      >
        <Select
          placeholder="Menü oder Content auswählen"
          style={{ width: "100%" }}
        >
          {dests.map((option) => (
            <Select.Option key={option.id} value={option.id}>
              {!!option.id ? option.name : "Start-Page"}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )}
  </IPointCard>
)

export default IPointImageUpload

import { gql } from "@apollo/client"

export const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      name
      role
      projects
    }
  }
`

export const LIST_PROJECTS_QUERY = gql`
  query ListProjectsQuery {
    projects {
      projects {
        id
        name
      }
      total
    }
  }
`
